<template>
  <div class="stepper-wrapper" :class="{ 'optional-margin': !editMode }">
    <v-stepper
      v-model="step"
      class="elevation-0 report-stepper"
      v-if="step < 4"
    >
      <v-stepper-header class="elevation-0 stepper-header">
        <v-stepper-step
          :class="{ 'active-header': step >= 1 }"
          :complete="step > 1"
          step="1"
        >
          Department
        </v-stepper-step>

        <v-divider :class="{ primary: step > 1 }"></v-divider>

        <v-stepper-step
          :class="{ 'active-header': step >= 2 }"
          :complete="step > 2"
          step="2"
        >
          Crew
        </v-stepper-step>

        <v-divider :class="{ primary: step > 2 }"></v-divider>

        <v-stepper-step
          :class="{ 'active-header': step >= 3 }"
          :complete="step > 3"
          step="3"
        >
          Job
        </v-stepper-step>
      </v-stepper-header>

      <div class="stepper-subheader">
        <p v-if="step == 1">
          Select a Department
        </p>
        <span v-if="step >= 2">
          <p>
            {{ department.code }}
          </p>
        </span>
        <v-icon color="black" v-if="step >= 2">
          mdi-chevron-right
        </v-icon>

        <p v-if="step == 2">
          Select a Crew
        </p>
        <span v-if="step >= 3">
          <p>
            {{ crew.code }}
          </p>
        </span>
        <v-icon color="black" v-if="step >= 3">
          mdi-chevron-right
        </v-icon>

        <p v-if="step == 3">
          Select a Job
        </p>
      </div>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="list-wrapper">
            <v-list>
              <v-list-item-group color="primary" v-model="departmentModel">
                <v-list-item
                  v-for="(dep, i) in departments"
                  :key="i"
                  :class="{ last: i == departments.length - 1 }"
                  @click="handleDepartmentSelect(dep)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="dep.code"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>

          <div class="stepper-footer"></div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="autocomplete-wrapper">
            <v-text-field
              v-model="crewsSearch"
              hide-details
              outlined
              single-line
              label="Start typing to search"
            ></v-text-field>
          </div>
          <div class="list-wrapper">
            <v-list v-if="filteredCrews.length">
              <v-list-item-group color="primary" v-model="crewModel">
                <v-list-item
                  v-for="(crew, i) in filteredCrews"
                  :key="i"
                  :class="{ last: i == filteredCrews.length - 1 }"
                  @click="handleCrewSelect(crew)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="crew.code"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>

          <div class="stepper-footer">
            <v-btn text class="back-button" @click="step = 1">
              Back
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="autocomplete-wrapper">
            <v-text-field
              v-model="jobSearch"
              hide-details
              outlined
              single-line
              label="Start typing to search"
            ></v-text-field>
          </div>
          <div class="list-wrapper">
            <v-list v-if="filteredJobs.length">
              <v-list-item-group color="primary" v-model="jobModel">
                <v-list-item
                  v-for="(job, i) in filteredJobs"
                  :key="i"
                  :class="{ last: i == filteredJobs.length - 1 }"
                  @click="handleJobSelect(job)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="job.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>

          <div class="stepper-footer">
            <v-btn text class="back-button" @click="step = 2">
              Back
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div v-if="step > 3" class="report-stepper">
      <div class="stepper-header">
        <p class="review-header">
          Review Report Details
        </p>
      </div>
      <div
        class="review-main"
        :class="{
          expanded: mainExpanded,
          'expanded-more': additionalDetails,
        }"
      >
        <div class="review-section-wrapper">
          <span class="review-section-heading">Job Details & Date</span>
        </div>
        <div class="review-section-content-wrapper">
          <div class="job-details">
            <p>
              {{ department.code }}

              <v-icon color="black">
                mdi-chevron-right
              </v-icon>
              {{ crew.code }}
              <v-icon color="black">
                mdi-chevron-right
              </v-icon>
              {{ job.name }}
            </p>
            <span class="edit-button" @click="step = 3">
              Edit
            </span>
          </div>
          <span class="date-label">Date of Report</span>
          <v-menu
            v-model="dateMenu"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                dense
                hide-details
                outlined
                single-line
                label=""
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable> </v-date-picker>
          </v-menu>
        </div>
        <div class="review-section-wrapper">
          <span class="review-section-heading">Additional Details</span>
        </div>
        <p class="additional-details-text">
          Installation reports might require Time In, Crew Size & Weather Info.
          Check the box below if this applies.
        </p>
        <div class="review-section-content-wrapper">
          <v-checkbox
            hide-details
            v-model="additionalDetails"
            label="Add Time In, Crew Size & Weather Info"
          ></v-checkbox>
          <v-form ref="form" class="mt-4" v-if="additionalDetails">
            <span class="date-label">Time In</span>
            <v-menu
              ref="timeMenu"
              v-model="timeMenu"
              transition="scale-transition"
              overflow-y
              nudge-bottom="40"
              allow-overflow
              max-width="290px"
              :close-on-content-click="false"
              @input="menuInput"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeFormatted"
                  :rules="[required]"
                  dense
                  hide-details
                  outlined
                  single-line
                  label="hh:mm"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                ref="timepicker"
                v-if="timeMenu"
                v-model="time"
                full-width
                no-title
                @click:minute="$refs.timeMenu.save(time)"
              ></v-time-picker>
            </v-menu>
            <div
              class="mt-4"
              v-if="editMode && status != 'Created' && status != 'Archived'"
            >
              <span class="date-label">Time Off</span>
              <v-menu
                ref="timeOffMenu"
                v-model="timeOffMenu"
                transition="scale-transition"
                overflow-y
                nudge-bottom="40"
                allow-overflow
                max-width="290px"
                :close-on-content-click="false"
                @input="menuInputOff"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="timeOffFormatted"
                    dense
                    hide-details
                    outlined
                    single-line
                    label="hh:mm"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  ref="timepickerOff"
                  v-if="timeOffMenu"
                  v-model="timeOff"
                  full-width
                  no-title
                  @click:minute="$refs.timeOffMenu.save(time)"
                ></v-time-picker>
              </v-menu>
            </div>
            <div class="mt-4 date-label">Crew Size</div>
            <div class="crew-input">
              <div class="counter-button minus" @click="handleMinus">
                -
              </div>
              <div class="middle">{{ crewSize }}</div>
              <div class="counter-button plus" @click="crewSize += 1">
                +
              </div>
            </div>
            <div class="mt-4 date-label">Weather</div>
            <v-text-field
              v-model="weather"
              :rules="[required]"
              dense
              hide-details
              outlined
              single-line
              label="e.g. Sunny"
            ></v-text-field>
          </v-form>
        </div>
      </div>
      <div class="stepper-footer">
        <v-btn
          text
          class="back-button"
          @click="
            step = 3;
            mainExpanded = false;
          "
        >
          Back
        </v-btn>

        <v-btn
          v-if="editMode"
          id="create-report"
          class="primary create-report-button"
          :disabled="step != 4"
          @click="handleEditReport"
          >Edit Report</v-btn
        >
        <v-btn
          v-if="!editMode"
          id="create-report"
          class="primary create-report-button"
          :disabled="step != 4"
          @click="handleCreateReport"
          >Create Report</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ReportStepper",
  async created() {
    await this.fetchDepartmentsForWizard();
    if (this.reportDetails) {
      this.step = 4;
      const {
        departmentCode,
        createDate,
        weather,
        crewSize,
        exitDateTime,
      } = this.reportDetails;
      const fullDepartmentIndex = this.departments.findIndex(
        e => e.code == departmentCode,
      );
      const fullDepartment = this.departments[fullDepartmentIndex];
      this.departmentModel = fullDepartmentIndex;
      this.department = fullDepartment;
      await this.fetchCrewsForWizard(fullDepartment.id);
      const fullCrewIndex = this.crews.findIndex(
        e => e.code == this.reportDetails.crewCode,
      );
      const fullCrew = this.crews[fullCrewIndex];
      this.crewModel = fullCrewIndex;
      this.crew = fullCrew;
      await this.fetchJobsForWizard({
        departmentId: fullDepartment.id,
        crewId: fullCrew.id,
      });
      const fullJobIndex = this.jobs.findIndex(
        e => e.name == this.reportDetails.jobName,
      );
      const fullJob = this.jobs[fullJobIndex];
      this.jobModel = fullJobIndex;
      this.job = fullJob;
      setTimeout(() => {
        this.mainExpanded = true;
      }, 600);
      const parsedDateTime = new Date(
        new Date(createDate) - new Date().getTimezoneOffset() * 60000,
      ).toISOString();
      this.date = parsedDateTime.slice(0, 10);
      if (exitDateTime) {
        const parsedDateTimeOff = new Date(
          new Date(exitDateTime + "Z") - new Date().getTimezoneOffset() * 60000,
        ).toISOString();
        this.timeOff = parsedDateTimeOff.slice(11, 16);
        this.dateOff = parsedDateTimeOff.slice(0, 10);
      }
      if (weather) {
        this.time = parsedDateTime.slice(11, 16);
        this.weather = weather;
        this.crewSize = crewSize;
        this.additionalDetails = true;
      }
    }
  },
  props: {
    reportDetails: Object,
    editMode: { type: Boolean, default: false },
  },
  data() {
    return {
      required: v => !!v || "Required!",
      departmentModel: -1,
      crewModel: -1,
      jobModel: -1,
      mainExpanded: false,
      step: 1,
      department: null,
      crew: {},
      job: {},
      dateOff: "",
      date: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000,
      )
        .toISOString()
        .slice(0, 10),
      time: "",
      timeOff: "",
      weather: "",
      crewsSearch: "",
      jobSearch: "",
      crewSize: 1,
      dateMenu: false,
      timeMenu: false,
      timeOffMenu: false,
      additionalDetails: false,
    };
  },
  watch: {
    additionalDetails(newVal) {
      if (!newVal) this.weather = "";
    },
  },
  computed: {
    ...mapGetters(["departments", "crews", "jobs", "isLoading"]),
    status() {
      return this.reportDetails && this.reportDetails.status;
    },
    timeOffFormatted() {
      if (!this.timeOff) return "";
      const [hour, minute] = this.timeOff.split(":");
      const suffix = hour >= 12 ? "PM" : "AM";
      let finalHour = hour > 12 ? ((hour - 1) % 12) + 1 : hour;
      if (finalHour == 0) {
        finalHour = 12;
      }
      return `${finalHour}:${minute} ${suffix}`;
    },
    timeFormatted() {
      if (!this.time) return "";
      const [hour, minute] = this.time.split(":");
      const suffix = hour >= 12 ? "PM" : "AM";
      let finalHour = hour > 12 ? ((hour - 1) % 12) + 1 : hour;
      if (finalHour == 0) {
        finalHour = 12;
      }
      return `${finalHour}:${minute} ${suffix}`;
    },
    filteredCrews() {
      return this.crews.filter(e =>
        e.code.toLowerCase().includes(this.crewsSearch.toLowerCase()),
      );
    },
    filteredJobs() {
      return this.jobs.filter(e =>
        e.name.toLowerCase().includes(this.jobSearch.toLowerCase()),
      );
    },
    dateFormatted() {
      if (!this.date) return null;
      const [year, month, day] = this.date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
  methods: {
    ...mapActions([
      "fetchDepartmentsForWizard",
      "fetchDepartments",
      "fetchCrewsForWizard",
      "fetchJobsForWizard",
      "createReport",
      "editReport",
    ]),
    ...mapMutations(["setCrewsFilters", "setJobsFilters"]),
    menuInputOff(val) {
      !val && (this.$refs.timepickerOff.selectingHour = true);
    },
    menuInput(val) {
      !val && (this.$refs.timepicker.selectingHour = true);
    },
    async handleEditReport() {
      const {
        date,
        dateOff,
        time,
        timeOff,
        job,
        crew,
        department,
        weather,
        crewSize,
      } = this;

      if (this.additionalDetails && !this.$refs.form.validate()) return;
      let concatDate =
        new Date(date).getTime() + new Date().getTimezoneOffset() * 60000;
      if (time) concatDate = `${date}T${time}`;
      const createDateTime = new Date(concatDate).toISOString();

      let exitDateTime = null;
      if (timeOff) {
        let concatDateOff =
          new Date(dateOff).getTime() + new Date().getTimezoneOffset() * 60000;
        if (timeOff) concatDateOff = `${dateOff}T${timeOff}`;
        exitDateTime = new Date(concatDateOff).toISOString();
      }

      const editedReport = await this.editReport({
        jobId: job.id,
        departmentId: department.id,
        crewId: crew.id,
        weather,
        crewSize: +crewSize,
        id: this.reportDetails.id,
        createDateTime,
        exitDateTime,
      });
      await this.fetchDepartments({ full: true, silent: true });
      if (editedReport) {
        this.$emit("reportEdited", editedReport);
      }
    },
    async handleCreateReport() {
      const { date, time, job, crew, department, weather, crewSize } = this;

      if (this.additionalDetails && !this.$refs.form.validate()) return;
      let concatDate =
        new Date(date).getTime() + new Date().getTimezoneOffset() * 60000;
      if (time) concatDate = `${date}T${time}`;
      const createDateTime = new Date(concatDate).toISOString();

      const payload = {
        jobId: job.id,
        departmentId: department.id,
        crewId: crew.id,
        weather,
        crewSize: +crewSize,
        createDateTime,
      };
      const reportId = await this.createReport(payload);
      if (reportId) {
        this.$router.push({
          name: "ReportDetail",
          params: { id: reportId.toString() },
        });
      }
    },
    async handleDepartmentSelect(department) {
      this.department = department;
      await this.fetchCrewsForWizard(department.id);
      this.step = 2;
      const index = this.departments.findIndex(e => e.code == department.code);
      this.departmentModel = index;
    },
    async handleCrewSelect(crew) {
      this.crew = crew;
      await this.fetchJobsForWizard({
        departmentId: this.department.id,
        crewId: crew.id,
      });
      this.step = 3;
    },
    handleJobSelect(job) {
      this.job = job;
      this.step = 4;
      setTimeout(() => {
        this.mainExpanded = true;
      }, 600);
    },
    handleMinus() {
      if (this.crewSize > 1) {
        this.crewSize -= 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.optional-margin {
  margin-bottom: 150px;
}
.stepper-wrapper {
  width: 540px;
}
.report-stepper {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  .stepper-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #c4c4c4;
    padding-top: 28px;
    padding-left: 40px;
    padding-right: 40px;
    height: 86px;
    .active-header {
      ::v-deep .v-stepper__label {
        color: #7fa231 !important;
      }
    }
    .review-header {
      font-weight: normal;
      font-size: 24px;
    }
  }
  .stepper-subheader {
    height: 72px;
    padding: 24px 40px 24px 40px;
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    align-items: center;
    span {
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: #000000;
        opacity: 0.6;
        text-decoration: underline;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0px;
    }
  }
  .review-main {
    &.expanded {
      height: 392px;
    }
    &.expanded-more {
      height: 693px;
    }
    height: 120px;
    overflow: hidden;
    transition: height 0.4s linear;
    background-color: #ffffff;
    padding: 16px 24px 8px 32px;
    .review-section-wrapper {
      border-bottom: 1px solid #c4c4c4;
      .review-section-heading {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: #000000;
      }
    }
    .review-section-content-wrapper {
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 32px;
    }
    .job-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 16px;
      background-color: #fafafa;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 8px 16px 8px 16px;
      .date-label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000;
      }
      p {
        margin-bottom: 0px;
      }
      .edit-button {
        cursor: pointer;
        color: #ea983f;
      }
    }
  }
  .autocomplete-wrapper {
    background-color: #f4f4f4;
    padding: 14px 40px 14px 28px;
    ::v-deep .v-input__slot {
      border-color: #e5e5e5 !important;
      background-color: #ffffff !important;
    }
  }
  .list-wrapper {
    height: 300px;
    overflow-y: auto;
    padding-left: 28px;
    padding-right: 28px;
  }
  ::v-deep .v-list {
    margin-top: 16px;
    margin-bottom: 16px;
    border: 1px solid #c4c4c4;
    border-radius: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    .v-list-item {
      border-bottom: 1px solid #c4c4c4;
      &.last {
        border-bottom: none;
      }
    }
  }
  .additional-details-text {
    margin-top: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    opacity: 0.6;
  }
  .crew-input {
    display: flex;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 40px;
    align-items: center;
    .counter-button {
      cursor: pointer;
      width: 67px;
      display: grid;
      place-items: center;
      background-color: #f4f4f4;
      height: 100%;
      font-size: 24px;
      line-height: 28px;

      color: #000000;
    }
    .middle {
      flex-grow: 1;
      display: grid;
      place-items: center;
      pointer-events: none;
    }
    .minus {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: 1px solid #c4c4c4;
    }
    .plus {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 1px solid #c4c4c4;
    }
  }
  .stepper-footer {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #f8f8f8;
    border-top: 1px solid #e5e5e5;
    height: 61px;
    display: flex;
    padding-left: 28px;
    padding-right: 28px;
    align-items: center;
    justify-content: space-between;

    .back-button {
      text-decoration: underline !important;
      text-transform: none;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
    }

    .create-report-button {
      text-transform: none !important;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
::v-deep .v-stepper__step__step {
  display: none;
}
::v-deep .v-stepper__content {
  padding: 0px;
}
::v-deep #create-report.v-btn--disabled {
  background-color: #7fa231 !important;
  opacity: 0.3;
  color: #ffffff !important;
}

@media only screen and (max-width: 959px) {
  ::v-deep .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>
