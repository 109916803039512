<template>
  <div>
    <v-dialog
      @click:outside="confirmArchiveDialog = false"
      v-model="confirmArchiveDialog"
      width="450"
    >
      <v-card class="confirm-archive-card">
        <v-overlay :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular
        ></v-overlay>
        <v-card-title class="primary--text">
          Are you done editing?
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <p>
            You have to <strong>archive</strong> this report before sending it.
            You will no longer be able to edit this report.
          </p>
          <div class="d-flex justify-space-between">
            <v-btn
              @click="confirmArchiveDialog = false"
              color="primary"
              outlined
            >
              No, keep editing
            </v-btn>
            <v-btn @click="handleConfirmArchive" class="primary ">
              Archive & Continue
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DeleteConfirmation
      :open.sync="deletePhotoDialog"
      @confirm="handleDeletePhotoConfirm"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete Photo"
      bodyText="This action will delete this image permanently and remove the images from the slides."
    />
    <DeleteConfirmation
      :open.sync="deleteDialog"
      @confirm="handleDeleteReport"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete Report"
      bodyText="This action will delete this report permanently"
    />
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular
    ></v-overlay>
    <v-dialog
      @click:outside="submitDialog = false"
      v-model="submitDialog"
      width="500"
    >
      <div class="submit-report-form">
        <h4>What's the estimated exit time?</h4>
        <p>Enter an estimated completion time for this project.</p>
        <v-menu
          ref="timeMenu"
          transition="scale-transition"
          overflow-y
          nudge-bottom="40"
          allow-overflow
          max-width="290px"
          :close-on-content-click="false"
          @input="menuInput"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="exitTimeFormatted"
              dense
              hide-details
              outlined
              single-line
              label="hh:mm"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            ref="timepicker"
            v-model="exitTime"
            full-width
            no-title
            @click:minute="$refs.timeMenu.save(exitTime)"
          ></v-time-picker>
        </v-menu>
        <div class="button-wrapper">
          <button type="button" @click="handleSubmitReport">SAVE</button>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      @click:outside="sendReportDialog = false"
      v-model="sendReportDialog"
      width="800"
    >
      <v-card>
        <v-card-title class="addModal-header">
          <span class="addModal-header-title text-capitalize">
            Send Report
          </span>
          <span>
            <v-btn
              text
              class="text-body-1 text-capitalize text-decoration-underline"
              @click="sendReportDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="ml-3 text-body-1 text-capitalize"
              @click="handleOpenClient"
            >
              Open Email Client
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text class="addModal-body send-report-modal">
          <div class="d-flex justify-space-between">
            <p>Select Contacts ({{ selectedContacts.length }})</p>
            <span
              @click="selectedContacts = [...contacts.map(e => e.email)]"
              v-if="selectedContacts.length == 0"
              class="select-deselect-buttons"
              >Select All</span
            >
            <span
              @click="selectedContacts = []"
              v-if="selectedContacts.length != 0"
              class="select-deselect-buttons"
              outlined
              >Deselect All</span
            >
          </div>
          <div class="contact-list-wrapper">
            <div
              class="d-flex align-center mb-6"
              v-for="(contact, index) in contacts"
              :key="index"
            >
              <v-checkbox
                :value="contact.email"
                :label="contact.email"
                v-model="selectedContacts"
                hide-details
                class="contact-email"
              >
              </v-checkbox>
              <p class="contact-category">
                {{ contact.category }}
              </p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      @click:outside="stepperDialog = false"
      v-model="stepperDialog"
      content-class="stepper-dialog"
      width="540"
    >
      <ReportStepper
        :reportDetails="report"
        editMode
        @reportEdited="handleReportEdited"
      />
    </v-dialog>
    <v-dialog v-model="photoPreview" width="600">
      <div style="position: relative;">
        <img :src="activePhotoPreview" class="photo-preview" />
      </div>
    </v-dialog>
    <div class="top-navigation">
      <v-btn text class="back-button" @click="handleExitEditor">
        <v-icon left>
          mdi-chevron-left
        </v-icon>
        Exit Editor
      </v-btn>
      <div class="align-center d-flex middle-text">
        <Cloud class="autosave-icon" :class="{ 'd-none': !isAutosaving }" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="!report.isFree"
        >
          <rect width="16" height="16" rx="8" fill="#FFC107" />
          <circle cx="4" cy="8" r="1" fill="white" />
          <circle cx="8" cy="8" r="1" fill="white" />
          <circle cx="12" cy="8" r="1" fill="white" />
        </svg>
        <svg
          style="margin-top: -1px;"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="report.isFree"
        >
          <circle cx="8" cy="8" r="8" :fill="topStatusColor" />
        </svg>
        <span style="color: #D1D1D1;" class="ml-2 d-flex align-center">
          <span v-if="!report.isFree || !hasEditAccess">
            {{ topStatusText }}
          </span>
          <span
            v-if="
              report.status != 'Submitted' &&
                report.status != 'Reviewer1' &&
                report.status != 'Reviewer2' &&
                report.status != 'Completed' &&
                report.isFree &&
                hasEditAccess
            "
          >
            {{ topStatusText }}
          </span>
          <v-menu
            offset-y
            left
            v-if="
              (report.status == 'Submitted' ||
                report.status == 'Reviewer1' ||
                report.status == 'Reviewer2' ||
                report.status == 'Completed') &&
                report.isFree &&
                hasEditAccess
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                {{ topStatusText }}
                <v-btn icon color="white" style="margin-top:-3px;">
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </div>
            </template>
            <v-list class="text-right">
              <v-list-item v-for="(b, i) in reviewerButtons" :key="i">
                <v-list-item-title
                  class="submit-menu-buttons"
                  @click="handleChangeReviewStatus(b.action)"
                >
                  {{ b.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-show="unsaved" style="height: 33px; line-height:33px;">
            (Unsaved)
          </div>
        </span>
      </div>
      <div v-if="!report.isFree && hasEditAccess" style="width: 150px;"></div>
      <div v-if="!hasEditAccess" style="width: 250px;"></div>
      <div v-if="report.isFree && hasEditAccess">
        <v-btn
          id="create-report"
          class="preview-report-button"
          outlined
          @click="previewMode = !previewMode"
          v-if="report.status != 'Archived'"
        >
          {{ previewMode ? "Exit" : "" }} Preview
        </v-btn>
        <v-btn
          class="primary submit-report-button"
          v-if="report.status == 'Archived'"
          @click="handleDownloadPdf"
        >
          <span class="mr-1">Download PDF</span>
        </v-btn>
        <v-btn
          class="primary submit-report-button rounded-r-0"
          @click="submitDialog = true"
          v-if="report.status == 'Created'"
        >
          <span class="mr-1">Submit</span>
        </v-btn>
        <v-btn
          class="primary submit-report-button rounded-r-0"
          @click="handleCompleteReview"
          v-if="
            report.status == 'Submitted' ||
              report.status == 'Reviewer1' ||
              report.status == 'Reviewer2'
          "
        >
          <span class="mr-1">{{ completeReviewText }}</span>
        </v-btn>
        <v-btn
          class="primary submit-report-button"
          :class="{ 'rounded-r-0': report.status != 'Archived' }"
          @click="confirmArchiveDialog = true"
          v-if="report.status == 'Completed'"
        >
          <span class="mr-1">Send Report</span>
        </v-btn>
        <v-menu offset-y v-if="report.status != 'Archived'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="primary text--white elevation-0 rounded-l-0 submit-dropdown-button"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                class="submit-menu-buttons"
                @click="handleSaveAsDraft"
              >
                Save as Draft
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                class="submit-menu-buttons"
                @click="deleteDialog = true"
                >Delete</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="report-info">
      <div class="text">
        <h2 class="job-name">{{ report.jobName }}</h2>
        <p class="report-date" v-if="report.createDate">
          Created on {{ new Date(report.createDate).toLocaleDateString() }} by
          {{ report.userFullName }}
        </p>
      </div>
      <div
        class="edit"
        @click="stepperDialog = true"
        v-if="report.status != 'Archived' && report.isFree && hasEditAccess"
      >
        <p class="edit-details">
          Edit Report Details
        </p>
      </div>
    </div>
    <div
      class="grid-container"
      :class="{ preview: previewMode, archived: report.status === 'Archived' }"
      id="grid-container"
    >
      <div class="slides">
        <div class="slides-top">
          <v-menu v-model="addSlideMenu" transition="scale-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-bind="attrs"
                v-on="on"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z"
                  fill="#EA983F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H13V7Z"
                  fill="#EA983F"
                />
              </svg>
            </template>
            <div class="add-slide-menu">
              <OneOneOrange @click="handleAddSlide('2')" />
              <TwoTwoOrange @click="handleAddSlide('4')" />
              <TwoOneOrange @click="handleAddSlide('3')" />
            </div>
          </v-menu>
        </div>
        <draggable
          v-model="slides"
          group="slide-group"
          @start="drag = true"
          @end="drag = false"
          @change="handleSlidesChange"
          class="slide-list"
          animation="200"
          :move="handleSlideMove"
        >
          <div
            v-for="(slide, i) in slides"
            :key="i"
            class="small-slide"
            :class="{
              active: activeSlide == i,
              flagged: slide.flag,
              blank: checkBlanks(slide),
            }"
          >
            <p>
              {{ i + 1 }}
            </p>
            <span @click="activeSlide = i" style="cursor: pointer;">
              <FirstSlideSvg v-if="slide.type == '1'" />
              <OneOneGray v-if="slide.type == '2'" />
              <TwoOneGray v-if="slide.type == '3'" />
              <TwoTwoGray v-if="slide.type == '4'" />
            </span>
          </div>
        </draggable>
      </div>
      <div class="elements" ref="elements">
        <div class="elements-heading">
          <p>Photos ({{ photos.length }})</p>
          <div class="photo-edit-add">
            <span v-if="!photosEdit" @click="photosEdit = true">Edit</span>
            <span v-if="photosEdit" @click="photosEdit = false">Done</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="$refs.photoInput.click()"
            >
              <path
                d="M21 6H17.83L16 4H10V6H15.12L16.95 8H21V20H5V11H3V20C3 21.1 3.9 22 5 22H21C22.1 22 23 21.1 23 20V8C23 6.9 22.1 6 21 6ZM8 14C8 16.76 10.24 19 13 19C15.76 19 18 16.76 18 14C18 11.24 15.76 9 13 9C10.24 9 8 11.24 8 14ZM13 11C14.65 11 16 12.35 16 14C16 15.65 14.65 17 13 17C11.35 17 10 15.65 10 14C10 12.35 11.35 11 13 11ZM5 6H8V4H5V1H3V4H0V6H3V9H5V6Z"
                fill="#EA983F"
              />
            </svg>
            <input
              type="file"
              accept="image/*"
              multiple
              style="display:none"
              ref="photoInput"
              @change="handleAddPhoto"
            />
          </div>
        </div>
        <div
          class="elements-photos"
          :class="{ edit: photosEdit }"
          v-if="photosEdit"
        >
          <div
            class="photo"
            v-for="(photo, i) in photos"
            :key="i"
            ref="viewer"
            v-viewer="{
              toolbar: {
                download: () => downloadImage(i),
              },
              navbar: false,
              movable: false,
              rotatable: false,
              scalable: false,
            }"
          >
            <span
              class="remove-photo-button"
              @click.stop="handleDeletePhoto(photo.id)"
              v-if="photosEdit"
            >
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.8913 0.53702C13.9694 0.614957 14.0314 0.707544 14.0737 0.809476C14.116 0.911409 14.1378 1.02068 14.1378 1.13104C14.1378 1.2414 14.116 1.35068 14.0737 1.45261C14.0314 1.55454 13.9694 1.64713 13.8913 1.72507L2.14504 13.4713C1.98749 13.6288 1.77382 13.7174 1.55101 13.7174C1.32821 13.7174 1.11454 13.6288 0.95699 13.4713C0.799445 13.3138 0.710938 13.1001 0.710938 12.8773C0.710937 12.6545 0.799445 12.4408 0.95699 12.2833L12.7032 0.53702C12.7812 0.458885 12.8738 0.396894 12.9757 0.354596C13.0776 0.312299 13.1869 0.290527 13.2972 0.290527C13.4076 0.290527 13.5169 0.312299 13.6188 0.354596C13.7207 0.396894 13.8133 0.458885 13.8913 0.53702V0.53702Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.957186 0.53702C0.879051 0.614957 0.81706 0.707544 0.774762 0.809476C0.732465 0.911409 0.710693 1.02068 0.710693 1.13104C0.710693 1.2414 0.732465 1.35068 0.774762 1.45261C0.81706 1.55454 0.879051 1.64713 0.957186 1.72507L12.7034 13.4713C12.861 13.6288 13.0746 13.7174 13.2974 13.7174C13.5202 13.7174 13.7339 13.6288 13.8915 13.4713C14.049 13.3138 14.1375 13.1001 14.1375 12.8773C14.1375 12.6545 14.049 12.4408 13.8915 12.2833L2.14523 0.53702C2.0673 0.458885 1.97471 0.396894 1.87278 0.354596C1.77085 0.312299 1.66157 0.290527 1.55121 0.290527C1.44085 0.290527 1.33157 0.312299 1.22964 0.354596C1.12771 0.396894 1.03512 0.458885 0.957186 0.53702V0.53702Z"
                  fill="white"
                />
              </svg>
            </span>
            <img class="photo-small" :src="photo.imageUrl" alt="Report Photo" />
          </div>
        </div>
        <draggable
          v-if="!photosEdit"
          class="elements-photos"
          :class="{ edit: photosEdit }"
          :group="{ name: 'photoDrag', pull: 'clone', put: false }"
          :sort="false"
        >
          <div
            class="photo"
            v-for="(photo, i) in photos"
            :key="i"
            :style="`background-image: url(${photo.imageUrl});`"
            :data-url="photo.imageUrl"
          ></div>
        </draggable>
        <div class="elements-heading">
          <p>Suggested Phrases</p>
        </div>
        <div class="elements-phrases">
          <div class="buttons">
            <v-btn class="elevation-0" @click="phraseCategory = 2">BW</v-btn>
            <v-btn class="elevation-0" @click="phraseCategory = 1">LR-I</v-btn>
            <v-btn class="elevation-0" @click="phraseCategory = 3">LR-M</v-btn>
            <v-btn class="elevation-0" @click="phraseCategory = 4"
              >Site Visit</v-btn
            >
          </div>
          <draggable
            class="phrases-wrapper"
            :group="{ name: 'textDrag', pull: 'clone', put: false }"
            :sort="false"
          >
            <div
              class="phrase"
              v-for="(phrase, i) in filteredPhrases"
              :key="i"
              :data-text="phrase.description"
            >
              <p>{{ phrase.description }}</p>
              <Plus @click="handleAddPhraseToSlide(phrase.description)" />
            </div>
          </draggable>
        </div>
      </div>
      <div class="editor" :style="editorStatus" id="editor">
        <div
          class="active-slide"
          v-for="(slide, slideIndex) in activeSlides"
          :key="slideIndex"
        >
          <div class="active-slide-options" v-if="!previewMode">
            <div class="layout-options" v-if="activeSlide != 0">
              <div
                class="layout-type-wrapper"
                @click="handleLayoutSelection('2')"
                :class="{ active: slideLayout(slide.type) == 'one-one' }"
              >
                <OneOneOrange />
              </div>
              <div
                class="layout-type-wrapper"
                @click="handleLayoutSelection('4')"
                :class="{ active: slideLayout(slide.type) == 'two-two' }"
              >
                <TwoTwoOrange />
              </div>
              <div
                class="layout-type-wrapper"
                @click="handleLayoutSelection('3')"
                :class="{ active: slideLayout(slide.type) == 'two-one' }"
              >
                <TwoOneOrange />
              </div>
            </div>
            <div class="delete-flag-wrapper" v-if="activeSlide != 0">
              <v-menu
                v-model="deleteSlideMenu"
                transition="scale-transition"
                top
                left
                nudge-top="50"
                origin="bottom right"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="delete-slide-wrapper" v-on="on" v-bind="attrs">
                    <Trashcan />
                  </div>
                </template>
                <div class="delete-slide-menu">
                  <div>
                    <v-icon>mdi-undo</v-icon>
                    <div @click="handleDeleteSlide">
                      <Trashcan />
                    </div>
                  </div>
                </div>
              </v-menu>
              <div
                class="flag-slide-wrapper"
                :class="{ active: slides[activeSlide].flag }"
                @click="slides[activeSlide].flag = !slides[activeSlide].flag"
              >
                <Flag />
              </div>
            </div>
          </div>
          <div
            class="active-slide-content-wrapper"
            :class="{
              'first-slide': slide.type == '1',
              flagged: slide.flag,
            }"
          >
            <input
              type="file"
              accept="image/*"
              style="display:none"
              ref="slidePhotoInput"
              @change="addPhotoToSlide"
            />
            <draggable
              :group="{ name: 'photoDrag' }"
              @add="onPhotoDrop($event, 'imageUrl1')"
              class="first-slide-image"
              v-if="slide.type == '1'"
              :class="{
                empty: !slide.imageUrl1,
                dragEnterClass: draggingImageOne,
              }"
              :style="`background-image: url(${slide.imageUrl1});`"
            >
              <span
                style="position: absolute; height: 40%; top:0; width:100%;"
              ></span>
              <span
                v-if="slide.imageUrl1 && !previewMode"
                class="remove-photo-button"
                @click="slide.imageUrl1 = ''"
              >
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.8913 0.53702C13.9694 0.614957 14.0314 0.707544 14.0737 0.809476C14.116 0.911409 14.1378 1.02068 14.1378 1.13104C14.1378 1.2414 14.116 1.35068 14.0737 1.45261C14.0314 1.55454 13.9694 1.64713 13.8913 1.72507L2.14504 13.4713C1.98749 13.6288 1.77382 13.7174 1.55101 13.7174C1.32821 13.7174 1.11454 13.6288 0.95699 13.4713C0.799445 13.3138 0.710938 13.1001 0.710938 12.8773C0.710937 12.6545 0.799445 12.4408 0.95699 12.2833L12.7032 0.53702C12.7812 0.458885 12.8738 0.396894 12.9757 0.354596C13.0776 0.312299 13.1869 0.290527 13.2972 0.290527C13.4076 0.290527 13.5169 0.312299 13.6188 0.354596C13.7207 0.396894 13.8133 0.458885 13.8913 0.53702V0.53702Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.957186 0.53702C0.879051 0.614957 0.81706 0.707544 0.774762 0.809476C0.732465 0.911409 0.710693 1.02068 0.710693 1.13104C0.710693 1.2414 0.732465 1.35068 0.774762 1.45261C0.81706 1.55454 0.879051 1.64713 0.957186 1.72507L12.7034 13.4713C12.861 13.6288 13.0746 13.7174 13.2974 13.7174C13.5202 13.7174 13.7339 13.6288 13.8915 13.4713C14.049 13.3138 14.1375 13.1001 14.1375 12.8773C14.1375 12.6545 14.049 12.4408 13.8915 12.2833L2.14523 0.53702C2.0673 0.458885 1.97471 0.396894 1.87278 0.354596C1.77085 0.312299 1.66157 0.290527 1.55121 0.290527C1.44085 0.290527 1.33157 0.312299 1.22964 0.354596C1.12771 0.396894 1.03512 0.458885 0.957186 0.53702V0.53702Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div
                class="image-upload-wrapper"
                @click.stop="handleImageUploadToSlide('imageUrl1')"
              >
                <p class="drag-text">Drag photos from<br />right in here</p>
                <button type="button">Take a photo</button>
                <p class="upload-text">or upload from files</p>
              </div>
            </draggable>
            <div class="first-slide-right" v-if="slide.type == '1'">
              <div class="first-slide-text-wrapper">
                <div class="top-part">
                  <p class="job-name">{{ report.jobName }}</p>
                  <p class="job-department">{{ report.departmentName }}</p>
                  <p class="date-text">
                    {{
                      new Date(report.createDate).toLocaleDateString([], {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    }}
                  </p>
                </div>
                <div class="bottom-part">
                  <div v-if="report.weather">
                    <p>Crew Size: {{ report.crewSize }}</p>
                    <p>Weather: {{ report.weather }}</p>
                    <p>
                      Time In:
                      {{
                        new Date(report.createDate).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                      }}
                    </p>
                    <p>Time Off: {{ timeOff }}</p>
                  </div>
                </div>
              </div>
              <div class="furbish-logo-wrapper" v-if="slide.type == '1'">
                <img
                  src="https://onsight.furbishco.com/logo_on_black.png"
                  alt="Furbish Logo"
                />
              </div>
            </div>
            <div
              v-if="slide.type != '1'"
              class="active-slide-content"
              :class="slideLayout(slide.type)"
            >
              <draggable
                :group="{ name: 'photoDrag', pull: false }"
                @add="onPhotoDrop($event, 'imageUrl1')"
                class="photo-1"
                :class="{
                  empty: !slide.imageUrl1,
                  dragEnterClass: draggingImageOne,
                }"
              >
                <span
                  v-if="slide.imageUrl1 && !previewMode"
                  class="remove-photo-button"
                  @click="slide.imageUrl1 = ''"
                >
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.8913 0.53702C13.9694 0.614957 14.0314 0.707544 14.0737 0.809476C14.116 0.911409 14.1378 1.02068 14.1378 1.13104C14.1378 1.2414 14.116 1.35068 14.0737 1.45261C14.0314 1.55454 13.9694 1.64713 13.8913 1.72507L2.14504 13.4713C1.98749 13.6288 1.77382 13.7174 1.55101 13.7174C1.32821 13.7174 1.11454 13.6288 0.95699 13.4713C0.799445 13.3138 0.710938 13.1001 0.710938 12.8773C0.710937 12.6545 0.799445 12.4408 0.95699 12.2833L12.7032 0.53702C12.7812 0.458885 12.8738 0.396894 12.9757 0.354596C13.0776 0.312299 13.1869 0.290527 13.2972 0.290527C13.4076 0.290527 13.5169 0.312299 13.6188 0.354596C13.7207 0.396894 13.8133 0.458885 13.8913 0.53702V0.53702Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.957186 0.53702C0.879051 0.614957 0.81706 0.707544 0.774762 0.809476C0.732465 0.911409 0.710693 1.02068 0.710693 1.13104C0.710693 1.2414 0.732465 1.35068 0.774762 1.45261C0.81706 1.55454 0.879051 1.64713 0.957186 1.72507L12.7034 13.4713C12.861 13.6288 13.0746 13.7174 13.2974 13.7174C13.5202 13.7174 13.7339 13.6288 13.8915 13.4713C14.049 13.3138 14.1375 13.1001 14.1375 12.8773C14.1375 12.6545 14.049 12.4408 13.8915 12.2833L2.14523 0.53702C2.0673 0.458885 1.97471 0.396894 1.87278 0.354596C1.77085 0.312299 1.66157 0.290527 1.55121 0.290527C1.44085 0.290527 1.33157 0.312299 1.22964 0.354596C1.12771 0.396894 1.03512 0.458885 0.957186 0.53702V0.53702Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <div
                  class="image-upload-wrapper"
                  @click.stop="handleImageUploadToSlide('imageUrl1')"
                >
                  <p class="drag-text">Drag photos from<br />right in here</p>
                  <button type="button">Take a photo</button>
                  <p class="upload-text">or upload from files</p>
                </div>
                <div
                  class="slide-image"
                  :style="`background-image: url(${slide.imageUrl1});`"
                ></div>
              </draggable>
              <draggable
                class="photo-2"
                :class="{
                  empty: !slide.imageUrl2,
                  dragEnterClass: draggingImageTwo,
                }"
                :group="{ name: 'photoDrag', pull: false }"
                @add="onPhotoDrop($event, 'imageUrl2')"
              >
                <span
                  v-if="slide.imageUrl2 && !previewMode"
                  class="remove-photo-button"
                  @click="slide.imageUrl2 = ''"
                >
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.8913 0.53702C13.9694 0.614957 14.0314 0.707544 14.0737 0.809476C14.116 0.911409 14.1378 1.02068 14.1378 1.13104C14.1378 1.2414 14.116 1.35068 14.0737 1.45261C14.0314 1.55454 13.9694 1.64713 13.8913 1.72507L2.14504 13.4713C1.98749 13.6288 1.77382 13.7174 1.55101 13.7174C1.32821 13.7174 1.11454 13.6288 0.95699 13.4713C0.799445 13.3138 0.710938 13.1001 0.710938 12.8773C0.710937 12.6545 0.799445 12.4408 0.95699 12.2833L12.7032 0.53702C12.7812 0.458885 12.8738 0.396894 12.9757 0.354596C13.0776 0.312299 13.1869 0.290527 13.2972 0.290527C13.4076 0.290527 13.5169 0.312299 13.6188 0.354596C13.7207 0.396894 13.8133 0.458885 13.8913 0.53702V0.53702Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.957186 0.53702C0.879051 0.614957 0.81706 0.707544 0.774762 0.809476C0.732465 0.911409 0.710693 1.02068 0.710693 1.13104C0.710693 1.2414 0.732465 1.35068 0.774762 1.45261C0.81706 1.55454 0.879051 1.64713 0.957186 1.72507L12.7034 13.4713C12.861 13.6288 13.0746 13.7174 13.2974 13.7174C13.5202 13.7174 13.7339 13.6288 13.8915 13.4713C14.049 13.3138 14.1375 13.1001 14.1375 12.8773C14.1375 12.6545 14.049 12.4408 13.8915 12.2833L2.14523 0.53702C2.0673 0.458885 1.97471 0.396894 1.87278 0.354596C1.77085 0.312299 1.66157 0.290527 1.55121 0.290527C1.44085 0.290527 1.33157 0.312299 1.22964 0.354596C1.12771 0.396894 1.03512 0.458885 0.957186 0.53702V0.53702Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <div
                  class="image-upload-wrapper"
                  @click.stop="handleImageUploadToSlide('imageUrl2')"
                >
                  <p class="drag-text">Drag photos from<br />right in here</p>
                  <button type="button">Take a photo</button>
                  <p class="upload-text">or upload from files</p>
                </div>
                <div
                  class="slide-image"
                  :style="`background-image: url(${slide.imageUrl2});`"
                ></div>
              </draggable>
              <draggable
                :group="{ name: 'textDrag', pull: false }"
                @add="onTextDrop($event, 'subTitle1')"
                class="text-1"
                :class="{ dragEnterClass: draggingTextOne }"
              >
                <p v-if="previewMode">
                  {{ slide.subTitle1 }}
                </p>
                <v-textarea
                  v-if="!previewMode"
                  class="subtitle-textarea"
                  placeholder="Enter or drag a caption in this area"
                  v-model="slide.subTitle1"
                  no-resize
                  hide-details
                  maxlength="360"
                />
              </draggable>
              <draggable
                :group="{ name: 'textDrag', pull: false }"
                @add="onTextDrop($event, 'subTitle2')"
                class="text-2"
                :class="{ dragEnterClass: draggingTextTwo }"
              >
                <p v-if="previewMode">
                  {{ slide.subTitle2 }}
                </p>
                <v-textarea
                  v-if="!previewMode"
                  class="subtitle-textarea"
                  placeholder="Enter or drag a caption in this area"
                  v-model="slide.subTitle2"
                  no-resize
                  hide-details
                  maxlength="360"
                />
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";
import draggable from "vuedraggable";
import { mapGetters, mapActions, mapMutations } from "vuex";
import ReportStepper from "@/components/ReportStepper.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import FirstSlideSvg from "@/assets/svgs/first-slide.svg";
import OneOneOrange from "@/assets/svgs/one-one-orange.svg";
import TwoOneOrange from "@/assets/svgs/two-one-orange.svg";
import TwoTwoOrange from "@/assets/svgs/two-two-orange.svg";
import OneOneGray from "@/assets/svgs/one-one-gray.svg";
import TwoOneGray from "@/assets/svgs/two-one-gray.svg";
import TwoTwoGray from "@/assets/svgs/two-two-gray.svg";
import Trashcan from "@/assets/svgs/trashcan.svg";
import Flag from "@/assets/svgs/flag.svg";
import Plus from "@/assets/svgs/plus.svg";
import Cloud from "@/assets/svgs/cloud-upload.svg";
import html from "@/constants/slideExport.js";

export default {
  name: "ReportDetail",
  beforeMount() {
    window.addEventListener("beforeunload", this.preventUnload);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.lockInterval);
      clearInterval(this.autosave);
      window.removeEventListener("beforeunload", this.preventUnload);
    });
  },
  beforeRouteLeave(_, __, next) {
    const { id, isFree } = this.report;
    if (isFree) {
      this.editReport({ id, isFree: true });
    }
    next();
  },
  async created() {
    this.autosave = setInterval(() => this.handleAutoSave(), 10 * 1000);
    this.fetchDepartments({ full: true, silent: true });
    const report = await this.fetchReport(this.id);
    this.report = { ...report };
    this.photos = await this.fetchPhotos(this.id);
    let slides = await this.fetchSlides(this.id);
    if (!slides.length)
      slides = [
        {
          processType: "add",
          id: -1,
          type: "1",
          imageUrl1: "",
          subTitle1: "",
          imageUrl2: "",
          subTitle2: "",
          flag: false,
        },
      ];
    this.slides = slides;
    this.activeSlide = 0;
    this.fetchSuggestedPhrases();
    if (report.status === "Archived" || !report.isFree || !this.hasEditAccess) {
      this.previewMode = true;
    }
    if (report.isFree && this.hasEditAccess) {
      this.handleLockReport();
      this.lockInterval = setInterval(() => {
        this.handleLockReport();
      }, 120000);
    }
    this.fetchEmailTemplate();
  },
  components: {
    ReportStepper,
    DeleteConfirmation,
    draggable,
    FirstSlideSvg,
    OneOneOrange,
    TwoOneOrange,
    TwoTwoOrange,
    OneOneGray,
    TwoOneGray,
    TwoTwoGray,
    Trashcan,
    Flag,
    Plus,
    Cloud,
  },
  props: {
    id: String,
  },
  data() {
    return {
      autosave: null,
      isAutosaving: false,
      lockInterval: null,
      publicPath: process.env.BASE_URL,
      report: {},
      photos: [],
      previewMode: false,
      sendEmailMode: false,
      deletePhotoDialog: false,
      deleteDialog: false,
      submitDialog: false,
      confirmArchiveDialog: false,
      sendReportDialog: false,
      stepperDialog: false,
      photoPreview: false,
      photosEdit: false,
      addSlideMenu: false,
      deleteSlideMenu: false,
      draggingImageOne: false,
      draggingImageTwo: false,
      draggingTextOne: false,
      draggingTextTwo: false,
      unsaved: false,
      exitTime: "",
      phraseCategory: 2,
      uploadTarget: "",
      activePhotoPreview: "",
      slides: [],
      deletedSlides: [],
      activeSlide: null,
      contacts: [],
      selectedContacts: [],
      photoToDelete: "",
      viewerOptions: {},
    };
  },
  watch: {
    slides: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal.length == 0 || newVal.length == 0) {
          return;
        }
        this.unsaved = true;
      },
    },
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "departments",
      "emailTemplate",
      "suggestedPhrases",
      "phraseCategories",
    ]),
    hasEditAccess() {
      const reviewAccess = this.$store.getters.hasReviewAccess;
      const { status } = this.report;
      if (status === "Created") {
        return true;
      }
      return reviewAccess;
    },
    editorStatus() {
      if (this.previewMode) {
        return "pointer-events:none";
      }
      return "";
    },
    reviewerButtons() {
      const { status } = this.report;
      const reviewButtons = [];
      let reviewIndex;

      if (status === "Completed") {
        reviewIndex = this.reviewerCount;
      } else if (status === "Submitted") {
        reviewIndex = 0;
      } else {
        reviewIndex = status[status.length - 1];
      }

      for (let i = 1; i <= this.reviewerCount; i += 1) {
        if (i > reviewIndex) {
          reviewButtons.push({
            text: `Complete Review ${i}`,
            action: `Reviewer${i}`,
          });
        } else if (i <= reviewIndex) {
          let action = `Reviewer${i - 1}`;

          if (i === 1) {
            action = "Submitted";
          }

          reviewButtons.push({
            text: `Go back to Review ${i}`,
            action,
          });
        }
      }

      if (
        reviewButtons.length > 0 &&
        reviewButtons[reviewButtons.length - 1].text.match(/Complete/)
      ) {
        reviewButtons[reviewButtons.length - 1].text = "Complete Final Review";
      }

      return reviewButtons;
    },
    reviewerCount() {
      const { departmentCode } = this.report;
      if (this.departments) {
        const departmentIndex = this.departments.findIndex(
          e => e.code === departmentCode,
        );
        return this.departments[departmentIndex].reviewersCount;
      }
      return 3;
    },
    timeOff() {
      if (!this.report.exitDateTime) return "____";

      return new Date(this.report.exitDateTime + "Z").toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },
    completeReviewText() {
      const { status } = this.report;
      if (status === "Submitted" && this.reviewerCount != 1)
        return "Complete Review 1";
      if (status === "Reviewer1" && this.reviewerCount != 2)
        return "Complete Review 2";
      return "Complete Final Review";
    },
    topStatusColor() {
      const { status } = this.report;
      if (status === "Submitted") return "#D6C432";
      if (status === "Reviewer1") return "#D6C432";
      if (status === "Reviewer2") return "#D6C432";
      if (status === "Reviewer3") return "#719654";
      if (status === "Completed") return "#719654";
      if (status === "Archived") return "#719654";
      return "#D1D1D1";
    },
    topStatusText() {
      const { status, isFree } = this.report;
      if (!isFree) return "Busy (View Only)";
      if (status === "Submitted") return "Submitted";
      if (status === "Reviewer1") return "Review 1 Completed";
      if (status === "Reviewer2") return "Review 2 Completed";
      if (status === "Reviewer3") return "Final Review Completed";
      if (status === "Completed") return "Final Review Completed";
      if (status === "Archived") return "Archived";
      return "Create New Report";
    },
    exitTimeFormatted() {
      if (!this.exitTime) return "";
      const [hour, minute] = this.exitTime.split(":");
      const suffix = hour >= 12 ? "PM" : "AM";
      let finalHour = hour > 12 ? ((hour - 1) % 12) + 1 : hour;
      if (finalHour == 0) {
        finalHour = 12;
      }
      return `${finalHour}:${minute} ${suffix}`;
    },
    activeSlides() {
      if (this.slides.length == 0) return [];
      if (this.sendEmailMode) return this.slides.filter(e => !e.flag);
      if (this.previewMode) return this.slides;
      return [this.slides[this.activeSlide]];
    },
    filteredPhrases() {
      return this.suggestedPhrases
        .filter(e => e.categoryId == this.phraseCategory)
        .sort((a, b) => (a.row > b.row ? 1 : -1));
    },
  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions([
      "fetchReport",
      "fetchPhotos",
      "fetchSlides",
      "fetchDepartments",
      "fetchSuggestedPhrases",
      "fetchEmailTemplate",
      "fetchContactsByJob",
      "editReport",
      "addPhoto",
      "saveSlides",
      "deletePhoto",
      "deleteReport",
      "lockReport",
    ]),
    downloadImage(index) {
      const a = document.createElement("a");
      a.href = this.$refs.viewer[index].querySelector("img").src;
      a.download = "";
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleLockReport() {
      const { id } = this.report;
      this.lockReport(id);
    },
    async handleConfirmArchive() {
      this.sendEmailMode = true;
      this.previewMode = true;
      const { id, jobId } = this.report;
      await this.handleSaveAsDraft();
      const h = document.getElementById("editor").outerHTML;
      const newHTML = html.replace("EDITORHTML", h);
      const contacts = this.fetchContactsByJob(jobId);
      const editedReport = await this.editReport({
        id,
        status: "Archived",
        htmlTextforPdf: newHTML,
      });
      contacts.then(c => {
        const parsedContacts = [];
        for (let i = 0; i < c.length; i += 1) {
          const e = c[i];
          const category = e.categories.join(", ");
          for (let j = 0; j < e.emailAddresses.length; j += 1) {
            parsedContacts.push({
              email: e.emailAddresses[j].address,
              category,
            });
          }
        }
        this.contacts = parsedContacts;
        this.report.status = editedReport.status;
        this.report.pdfUrl = editedReport.pdfUrl;
        this.sendEmailMode = false;
        this.confirmArchiveDialog = false;
        this.sendReportDialog = true;
      });
    },
    handleDownloadPdf() {
      const { pdfUrl } = this.report;
      const link = document.createElement("a");
      link.href = pdfUrl;
      const fNameSplit = pdfUrl.split("/");
      const fileName = fNameSplit[fNameSplit.length - 1];
      link.download = fileName;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    menuInput(val) {
      !val && (this.$refs.timepicker.selectingHour = true);
    },
    handleOpenClient() {
      /*eslint no-irregular-whitespace: ["error", { "skipTemplates": true }]*/

      const parsedDate = new Date(
        this.report.statusChangeDateTime + "Z",
      ).toLocaleDateString([], {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const { pdfUrl: pdf, departmentName } = this.report;
      const emailFinal = encodeURIComponent(
        this.emailTemplate
          .replace("{UPDATE_TIME}", parsedDate)
          .replace("{DEP_NAME}", departmentName)
          .replace("{REPORT_LINK}", encodeURI(pdf)),
      );
      const subject = encodeURIComponent(
        `Furbish ${this.report.jobName} Report`,
      );
      const toString = this.selectedContacts.join("; ");
      const mLink = `mailto:${toString}?subject=${subject}&body=${emailFinal}`;
      const link = document.createElement("a");
      link.href = mLink;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async handleSendEmail() {
      this.sendEmailMode = true;
      this.previewMode = true;
      const { id } = this.report;
      await this.handleSaveAsDraft();
      const h = document.getElementById("editor").outerHTML;
      const newHTML = html.replace("EDITORHTML", h);
      const editedReport = await this.editReport({
        id,
        status: "Archived",
        htmlTextforPdf: newHTML,
      });
      this.report.status = editedReport.status;
      this.report.pdfUrl = editedReport.pdfUrl;
      this.sendEmailMode = false;
    },
    async handleCompleteReview() {
      this.handleSaveAsDraft();
      const { id, status } = this.report;
      let newStatus = "";
      if (status === "Submitted") newStatus = "Reviewer1";
      if (status === "Reviewer1") newStatus = "Reviewer2";
      if (status === "Reviewer2") newStatus = "Reviewer3";
      const editedReport = await this.editReport({
        id,
        status: newStatus,
      });
      this.report.status = editedReport.status;
    },
    async handleChangeReviewStatus(newStatus) {
      this.handleSaveAsDraft();
      const { id } = this.report;
      const editedReport = await this.editReport({
        id,
        status: newStatus,
      });
      this.report.status = editedReport.status;
    },
    handleExitEditor() {
      if (this.unsaved) {
        if (
          !window.confirm("You have unsaved changes. Leave without saving?")
        ) {
          return;
        }
      }
      this.$router.push("/reports");
    },
    async preventUnload(event) {
      if (!this.unsaved) return;
      event.preventDefault();
      event.returnValue = "";
    },
    checkBlanks(slide) {
      const { type, imageUrl1, imageUrl2, subTitle1, subTitle2 } = slide;
      if (type == "1" && !imageUrl1) {
        return true;
      } else if (type == "2" && (!imageUrl1 || !subTitle1)) {
        return true;
      } else if (type == "3" && (!imageUrl1 || !imageUrl2 || !subTitle1)) {
        return true;
      } else if (
        type == "4" &&
        (!imageUrl1 || !imageUrl2 || !subTitle1 || !subTitle2)
      ) {
        return true;
      }
      return false;
    },
    handleReportEdited(report) {
      this.report = {
        ...report,
        createDate: report.createDate + "Z",
        isFree: true,
      };
      this.stepperDialog = false;
    },
    async handleSaveAsDraft() {
      const parsedSlides = [
        ...this.slides.map((e, i) => ({
          ...e,
          row: i,
        })),
        ...this.deletedSlides,
      ];
      const savedSlides = await this.saveSlides({
        reportId: this.report.id,
        slides: parsedSlides,
      });
      if (savedSlides) {
        this.slides = savedSlides;
        this.deletedSlides = [];
      }
      setTimeout(() => (this.unsaved = false), 300);
    },
    async handleSubmitReport() {
      await this.handleSaveAsDraft();
      const { id, createDate } = this.report;
      const exitTime = this.exitTime;
      let concatDate =
        new Date(createDate.slice(0, 10)).getTime() +
        new Date().getTimezoneOffset() * 60000;
      if (exitTime) concatDate = `${createDate.slice(0, 10)}T${exitTime}`;
      const newExitTime = new Date(concatDate).toISOString();
      const editedReport = await this.editReport({
        id,
        status: "Submitted",
        exitDateTime: newExitTime,
      });
      if (editedReport) {
        this.submitDialog = false;
        this.exitTime = "";
        this.$router.push(`/reports/${id}`);
      }
    },
    slideLayout(type) {
      switch (type) {
        case "1":
          return "first-slide";
        case "2":
          return "one-one";
        case "3":
          return "two-one";
        case "4":
          return "two-two";
      }
      return "first-slide";
    },
    handleAddPhraseToSlide(description) {
      const { slides, activeSlide } = this;
      if (activeSlide == 0) return;

      if (slides[activeSlide]["subTitle1"] == "") {
        this.slides[activeSlide]["subTitle1"] = description;
        return;
      }

      if (
        slides[activeSlide]["subTitle2"] == "" &&
        slides[activeSlide]["type"] == 4
      )
        this.slides[activeSlide]["subTitle2"] = description;
    },
    async handleDeleteReport() {
      const reportDeleted = this.deleteReport(this.report.id);
      if (reportDeleted) {
        this.deleteDialog = false;
        this.$router.push("/reports");
      }
    },
    handleDeletePhoto(id) {
      this.photoToDelete = id;
      this.deletePhotoDialog = true;
    },
    async handleDeletePhotoConfirm() {
      const id = this.photoToDelete;
      const photoDeleted = await this.deletePhoto(id);
      if (photoDeleted) {
        const index = this.photos.findIndex(e => e.id == id);
        this.photos.splice(index, 1);
      }
      this.photoToDelete = "";
      this.deletePhotoDialog = false;
    },
    handlePhotoClicked(url) {
      this.activePhotoPreview = url;
      this.photoPreview = true;
    },
    onTextDrop(event, targetLocation) {
      const el = event.item;
      el.parentNode.removeChild(el);
      if (targetLocation == "subTitle1") this.draggingTextOne = false;
      if (targetLocation == "subTitle2") this.draggingTextTwo = false;
      const text = event.item.dataset.text;
      if (text) this.slides[this.activeSlide][targetLocation] = text;
    },
    onPhotoDrop(event, targetLocation) {
      const el = event.item;
      el.parentNode.removeChild(el);
      if (targetLocation == "imageUrl1") this.draggingImageOne = false;
      if (targetLocation == "imageUrl2") this.draggingImageTwo = false;
      const photoUrl = event.item.dataset.url;
      if (photoUrl) this.slides[this.activeSlide][targetLocation] = photoUrl;
    },
    handleImageUploadToSlide(target) {
      this.uploadTarget = target;
      this.$refs.slidePhotoInput[0].click();
    },
    async addPhotoToSlide(event) {
      const {
        target: { files },
      } = event;

      if (!files || !files.length) {
        this.setLoading(false);
        return;
      }

      const photoRead = async e => {
        const newPhoto = await this.addPhoto({
          url: e.target.result,
          reportId: this.report.id,
        });
        this.photos.push(newPhoto);
        this.slides[this.activeSlide][this.uploadTarget] = newPhoto.imageUrl;
        this.uploadTarget = "";

        this.$refs.slidePhotoInput.value = "";
        this.setLoading(false);
      };
      this.setLoading(true);
      [...files].forEach(file => {
        new Compressor(file, {
          quality: 0.7,
          maxHeight: 1500,
          maxWidth: 2000,
          success(result) {
            const reader = new FileReader();
            reader.onload = photoRead;
            reader.readAsDataURL(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    async handleAddPhoto(e) {
      const {
        target: { files },
      } = e;
      let count = files.length;
      const photoRead = async e => {
        const newPhoto = await this.addPhoto({
          url: e.target.result,
          reportId: this.report.id,
        });
        this.photos.push(newPhoto);
        if (!--count) this.setLoading(false);

        //this.photos.push({ url: e.target.result });
      };
      this.setLoading(true);

      [...files].forEach(file => {
        new Compressor(file, {
          quality: 0.7,
          maxHeight: 1500,
          maxWidth: 2000,
          success(result) {
            const reader = new FileReader();
            reader.onload = photoRead;
            reader.readAsDataURL(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    handleSlidesChange(e) {
      const {
        moved: { newIndex, oldIndex },
      } = e;
      if (this.activeSlide == oldIndex) {
        this.activeSlide = newIndex;
      } else if (this.activeSlide == newIndex) {
        if (this.activeSlide > oldIndex) this.activeSlide -= 1;
        if (this.activeSlide < oldIndex) this.activeSlide += 1;
      }
    },
    handleSlideMove(e) {
      const {
        draggedContext: { index, futureIndex },
      } = e;
      if (index == 0 || futureIndex == 0) return false;
    },
    handleDeleteSlide() {
      const slideToDelete = this.slides.splice(this.activeSlide, 1)[0];
      if (slideToDelete.id != -1) {
        this.deletedSlides.push({
          ...slideToDelete,
          processType: "delete",
          row: -1,
        });
      }
      this.activeSlide -= 1;
    },
    handleAddSlide(type) {
      this.slides.push({
        id: -1,
        processType: "add",
        type,
        imageUrl1: "",
        imageUrl2: "",
        subTitle1: "",
        subTitle2: "",
        flag: false,
      });
      this.activeSlide = this.slides.length - 1;
    },
    handleLayoutSelection(type) {
      this.slides[this.activeSlide].type = type;
    },
    async handleAutoSave() {
      if (this.unsaved) {
        const currentInput = document.activeElement;
        const currentCursor = currentInput.selectionStart;

        this.isAutosaving = true;
        await this.handleSaveAsDraft();
        this.isAutosaving = false;
        currentInput?.setSelectionRange(currentCursor, currentCursor);
      }
    },
  },
};
</script>
<style lang="scss" scoped src="@/styles/ReportDetail.scss"></style>
