<template>
  <div class="justify-center d-flex align-center login">
    <div class="form-left">
      <div class="form-wrapper">
        <h2>Welcome Back</h2>
        <p class="please-sign-text">Please sign in to continue</p>
        <form>
          <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div class="login-input-wrapper">
            <label for="email">Email Address</label>
            <v-text-field
              hide-details
              @keyup.enter="handleLogin"
              placeholder="Type something"
              v-model="email"
              outlined
            ></v-text-field>
          </div>
          <div class="login-input-wrapper">
            <label for="password">Password</label>
            <v-text-field
              hide-details
              @keyup.enter="handleLogin"
              outlined
              v-model="password"
              placeholder="***********"
              type="password"
            ></v-text-field>
          </div>
          <v-btn @click="handleLogin" class="sign-in-button primary"
            >SIGN IN</v-btn
          >
          <div class="form-remember-and-forgot">
            <v-checkbox v-model="remember" dense hide-details class="pt-0 mt-0">
              <template #label>
                <span class="remember-me-label">
                  Remember me
                </span>
              </template>
            </v-checkbox>
          </div>
        </form>
        <div class="form-disclaimer-wrapper">
          <span class="form-disclaimer-text">
            By using this software system, you agree to our company's
            <span>
              Terms and Conditions
            </span>
            and <span>Privacy Policy</span>
          </span>
        </div>
      </div>
      <div class="justify-end powered-by-section d-flex d-md-none align-center">
        <span>Powered by</span>
        <img src="@/assets/logo_small.png" alt="Furbish Logo" />
      </div>
    </div>
    <div class="form-right d-none d-md-block">
      <div class="rounded-r-lg align-end login-hero d-flex">
        <div class="flex-grow-0 hero-text-wrapper">
          <p class="white--text">
            Effortless on-site report generation for living system professionals
          </p>
        </div>
      </div>
      <div class="justify-end mt-3 powered-by-section d-flex align-center">
        <span>Powered by</span>
        <img src="@/assets/logo_small.png" alt="Furbish Logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      remember: false,
    };
  },
  methods: {
    ...mapActions(["login"]),
    ...mapMutations(["setErrorAlert", "setSuccessAlert"]),
    async handleLogin() {
      this.loading = true;
      const { email, password, remember } = this;
      try {
        await this.login({ email, password, remember });
        this.setSuccessAlert("Logged in");
        this.$router.push("/");
      } catch (e) {
        console.log(e);
        this.setErrorAlert("Error while logging in");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background-color: #e8ebe2;
  min-height: 100vh;
}
.form-left {
  display: flex;
  flex-direction: column;
  width: 349px;
  .form-wrapper {
    width: 100%;
    background-color: #fafafa;
    padding-right: 32px;
    padding-left: 32px;
    border-radius: 10px;
    h2 {
      margin-top: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 0px;
    }
    .please-sign-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
    }
    form {
      margin: 32px 0px 32px 0px;
      .login-input-wrapper {
        margin-bottom: 24px;
        label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .sign-in-button {
        width: 100%;
        height: 48px;
      }
      .form-remember-and-forgot {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        .remember-me-label {
          font-size: 12px !important;
        }
        .forgot-password {
          font-size: 12px !important;
          line-height: 14px !important;
          margin-top: 5px;
        }
      }
    }
    .form-disclaimer-wrapper {
      width: 100%;
      margin-bottom: 40px;
      .form-disclaimer-text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        color: rgba(0, 0, 0, 0.4);
        span {
          color: black;
          font-weight: normal;
        }
      }
    }
  }
  .powered-by-section {
    position: relative;
    top: 8px;
    height: 26px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
    img {
      height: 26px;
      margin-left: 5px;
    }
  }
}
@media only screen and (min-width: 600px) {
  .form-left {
    width: 419px;
    .form-wrapper {
      padding-right: 38px;
      padding-left: 38px;
    }
  }
}
@media only screen and (min-width: 960px) {
  .form-wrapper {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
.form-right {
  height: auto;
  height: 544px;
  width: 530px;
  .login-hero {
    background: url("../assets/login_bg.png");
    height: 100%;
    width: 100%;
    background-size: cover;
  }
  .hero-text-wrapper {
    background: rgba(81, 81, 81, 0.6);
    margin-bottom: 44px;
    padding: 32px 19px 32px 24px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 32px;
    }
  }
}
</style>
