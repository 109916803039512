<template>
  <v-dialog
    @click:outside="$emit('update:open', false)"
    v-model="open"
    width="455"
  >
    <v-card class="delete-confirmation-card">
      <v-card-title>{{ questionText }}?</v-card-title>
      <v-card-text class="d-flex flex-column">
        <p>{{ bodyText }}</p>

        <div class="d-flex justify-space-between">
          <v-btn
            @click="$emit('update:open', false)"
            class="elevation-0"
            color="primary"
            outlined
            >{{ cancelText }}</v-btn
          >
          <v-btn
            @click="$emit('confirm')"
            class="remove-button-color white--text elevation-0"
          >
            {{ confirmText }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteConfirmation",
  props: {
    open: Boolean,
    cancelText: String,
    confirmText: String,
    questionText: String,
    bodyText: String,
  },
};
</script>

<style lang="scss" scoped>
.delete-confirmation-card {
  padding: 35px !important;
  p {
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #454545;
    margin-bottom: 28px;
  }
  ::v-deep .v-card__title {
    font-weight: bold !important;
    font-size: 28px !important;
    line-height: 33px !important;
    color: #719654 !important;
  }
  ::v-deep .v-btn {
    height: 49px;
    padding: 0 40px !important;
  }
  ::v-deep .v-btn__content {
    text-transform: none !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
  .remove-button-color {
    background-color: #f39797 !important;
  }
}
</style>
