<template>
  <div>
    <DeleteConfirmation
      :open.sync="deleteModal"
      @confirm="handleConfirmDelete"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete Job"
      bodyText="This action will delete this job permanently"
    />
    <v-dialog
      @click:outside="handleCancel"
      eager
      v-model="addModal"
      width="540"
    >
      <v-card>
        <v-overlay absolute :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular
        ></v-overlay>
        <v-card-title class="addModal-header">
          <span class="addModal-header-title text-capitalize"
            >{{ mode }} Job</span
          >
          <span>
            <v-btn
              v-if="isEdit"
              text
              class="text-body-1 text-capitalize custom-error-color"
              @click="handleDeleteFromEdit"
              >Delete Job</v-btn
            >
            <v-btn
              v-if="!isEdit"
              text
              class="text-body-1 text-capitalize text-decoration-underline"
              @click="handleCancel"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="ml-3 text-body-1 text-capitalize"
              @click="handleSave"
            >
              Save
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text class="addModal-body">
          <v-form class="mx-0 my-0" ref="form">
            <div class="section-heading-wrapper">
              <span class="section-heading">
                Job Photo
              </span>
            </div>
            <div class="form-section">
              <v-sheet
                v-if="!fields.imageUrl"
                width="215"
                height="160"
                ref="fileDrop"
                @drop.prevent="addImage"
                @dragover.prevent
                @click.stop="passClickToInput"
                class="file-upload-wrapper"
              >
                <div class="px-8 text-center d-flex flex-column align-center">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.3096 12.9996V12.6796C30.2414 9.97055 29.1043 7.39852 27.1464 5.52497C25.1886 3.65142 22.569 2.62852 19.8596 2.67956C17.8418 2.66309 15.8622 3.23008 14.159 4.31228C12.4559 5.39449 11.1018 6.94581 10.2596 8.77956C7.88841 9.04407 5.69678 10.1697 4.10049 11.943C2.5042 13.7162 1.6143 16.0137 1.59961 18.3996C1.60223 19.6637 1.85399 20.915 2.34049 22.0818C2.82699 23.2486 3.5387 24.308 4.43491 25.1996C5.33113 26.0912 6.39429 26.7974 7.5636 27.2778C8.73291 27.7583 9.98545 28.0035 11.2496 27.9996H14.9996V25.9996H11.2496C9.31675 25.895 7.4954 25.0615 6.1527 23.6672C4.80999 22.2729 4.04576 20.4215 4.01406 18.486C3.98235 16.5506 4.68552 14.6751 5.98183 13.2376C7.27815 11.8001 9.07121 10.9074 10.9996 10.7396H11.6696L11.8996 10.1096C12.5198 8.49869 13.6196 7.11704 15.0503 6.15131C16.481 5.18559 18.1737 4.68233 19.8996 4.70956C22.0787 4.65836 24.1896 5.47052 25.7724 6.96904C27.3552 8.46757 28.2816 10.531 28.3496 12.7096C28.3634 12.976 28.3634 13.2431 28.3496 13.5096L28.2696 14.2296L28.9196 14.5296C30.1768 15.0959 31.2005 16.078 31.8186 17.3106C32.4366 18.5432 32.6113 19.951 32.3132 21.2973C32.015 22.6435 31.2623 23.846 30.1817 24.7024C29.101 25.5588 27.7584 26.0169 26.3796 25.9996H20.9996V27.9996H26.3796C28.1581 28.0123 29.8901 27.4321 31.302 26.3505C32.7138 25.269 33.725 23.7478 34.1758 22.0273C34.6266 20.3069 34.4912 18.4853 33.791 16.8504C33.0908 15.2155 31.8659 13.8605 30.3096 12.9996Z"
                      fill="#C4C4C4"
                    />
                    <path
                      d="M22.2802 21.8496C22.4797 21.8528 22.6756 21.7963 22.8426 21.6872C23.0097 21.5782 23.1404 21.4217 23.2178 21.2378C23.2952 21.054 23.3159 20.8511 23.2771 20.6554C23.2383 20.4597 23.1419 20.2801 23.0002 20.1396L18.0002 15.1396L13.0002 20.1396C12.8364 20.3309 12.7508 20.577 12.7605 20.8287C12.7702 21.0804 12.8746 21.3191 13.0527 21.4972C13.2308 21.6753 13.4695 21.7796 13.7212 21.7894C13.9728 21.7991 14.2189 21.7135 14.4102 21.5496L17.0002 18.9996V31.2496C17.0002 31.5149 17.1056 31.7692 17.2931 31.9568C17.4807 32.1443 17.735 32.2496 18.0002 32.2496C18.2654 32.2496 18.5198 32.1443 18.7073 31.9568C18.8949 31.7692 19.0002 31.5149 19.0002 31.2496V18.9996L21.5702 21.5696C21.7606 21.7532 22.0158 21.8538 22.2802 21.8496Z"
                      fill="#C4C4C4"
                    />
                  </svg>

                  <p>Drop files to upload or <span>browse</span></p>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style="display:none"
                  ref="fileInput"
                  @change="addImage"
                />
              </v-sheet>
              <div class="image-wrapper" v-if="fields.imageUrl">
                <div class="inside-image-wrapper">
                  <span
                    class="remove-image-button"
                    @click="fields.imageUrl = null"
                  >
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.8913 0.53702C13.9694 0.614957 14.0314 0.707544 14.0737 0.809476C14.116 0.911409 14.1378 1.02068 14.1378 1.13104C14.1378 1.2414 14.116 1.35068 14.0737 1.45261C14.0314 1.55454 13.9694 1.64713 13.8913 1.72507L2.14504 13.4713C1.98749 13.6288 1.77382 13.7174 1.55101 13.7174C1.32821 13.7174 1.11454 13.6288 0.95699 13.4713C0.799445 13.3138 0.710938 13.1001 0.710938 12.8773C0.710937 12.6545 0.799445 12.4408 0.95699 12.2833L12.7032 0.53702C12.7812 0.458885 12.8738 0.396894 12.9757 0.354596C13.0776 0.312299 13.1869 0.290527 13.2972 0.290527C13.4076 0.290527 13.5169 0.312299 13.6188 0.354596C13.7207 0.396894 13.8133 0.458885 13.8913 0.53702V0.53702Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.957186 0.53702C0.879051 0.614957 0.81706 0.707544 0.774762 0.809476C0.732465 0.911409 0.710693 1.02068 0.710693 1.13104C0.710693 1.2414 0.732465 1.35068 0.774762 1.45261C0.81706 1.55454 0.879051 1.64713 0.957186 1.72507L12.7034 13.4713C12.861 13.6288 13.0746 13.7174 13.2974 13.7174C13.5202 13.7174 13.7339 13.6288 13.8915 13.4713C14.049 13.3138 14.1375 13.1001 14.1375 12.8773C14.1375 12.6545 14.049 12.4408 13.8915 12.2833L2.14523 0.53702C2.0673 0.458885 1.97471 0.396894 1.87278 0.354596C1.77085 0.312299 1.66157 0.290527 1.55121 0.290527C1.44085 0.290527 1.33157 0.312299 1.22964 0.354596C1.12771 0.396894 1.03512 0.458885 0.957186 0.53702V0.53702Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <img
                    class="job-image"
                    :src="fields.imageUrl"
                    alt="Job Image"
                  />
                </div>
              </div>
            </div>
            <div class="section-heading-wrapper">
              <span class="section-heading">
                Job Details
              </span>
            </div>
            <div class="form-section">
              <span class="modal-text-field-label">Job Name</span>
              <v-text-field
                dense
                hide-details
                placeholder="e.g. 1050 St NW"
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('name')"
                v-model="fields.name"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('name')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
              <span class="modal-text-field-label">Crews</span>
              <v-autocomplete
                dense
                hide-details
                placeholder="Start typing to find crews"
                outlined
                single-line
                multiple
                :disabled="isDisabled(`crews`)"
                v-model="fields.crews"
                :items="parsedCrews"
                class="mb-3"
                :rules="[arrayRequired]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('crews')"
                >
                  mdi-pencil
                </v-icon>
              </v-autocomplete>
            </div>
            <div class="section-heading-wrapper">
              <span class="section-heading">
                Add Contact Categories
              </span>
            </div>
            <div>
              <p class="mt-1">
                Finalized reports for this job will be distributed to contacts
                who have this category assigned in outlook.
              </p>
            </div>
            <div class="form-section">
              <v-autocomplete
                dense
                hide-details
                placeholder="Start typing to find contacts"
                outlined
                single-line
                multiple
                :items="parsedContactCategories"
                :disabled="isDisabled(`categories`)"
                v-model="fields.categories"
                class="mb-6 mt-2"
                :menu-props="{
                  closeOnClick: false,
                  closeOnContentClick: false,
                  disableKeys: true,
                  openOnClick: false,
                  maxHeight: 304,
                  offsetY: true,
                  offsetOverflow: true,
                  top: true,
                  transition: false,
                }"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('categories')"
                >
                  mdi-pencil
                </v-icon>
              </v-autocomplete>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      left
      absolute
      width="375"
      temporary
      v-model="filterDrawer"
      class="filter-drawer"
    >
      <div @click="filterDrawer = false" class="justify-end d-flex mb-14">
        <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-expansion-panels
        class="filter-expansion-panel"
        v-model="filterPanels"
        flat
        accordion
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Crews {{ filterSelectionCount("crews") }}</span>
              <p
                v-if="filterSelectionElements('crews', 'code')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("crews", "code") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-autocomplete
              placeholder="Search to find title"
              outlined
              dense
              hide-details
              multiple
              :items="parsedCrews"
              v-model="filters.crews"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Department {{ filterSelectionCount("department") }}</span>
              <p
                v-if="filterSelectionElements('department', 'name')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("department", "name") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-checkbox-wrapper">
            <v-checkbox
              v-for="(d, dIndex) in departments"
              :key="dIndex"
              :value="d"
              :label="d.name"
              class="text-body-1 filter-checkbox-reverse"
              :class="{ 'mt-0': dIndex == 0, 'mt-6': dIndex != 0 }"
              v-model="filters.department"
              hide-details
            >
              <template #label>
                <span style="color: black;">{{ d.name }}</span>
              </template>
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
    <TablePageHeader title="Job" @new="handleNew" />
    <Table
      @filter="handleFilterClicked"
      @edit="handleEdit"
      @delete="handleDelete"
      @search="handleSearch"
      @next="handleNext"
      @prev="handlePrev"
      @page-click="handlePageClick"
      @sortDesc="handleSortDesc"
      @sortBy="handleSortBy"
      :items="jobs"
      :headers="headers"
      :filters="filters"
      :totalItemCount="jobsCount"
      :currentPage="jobsCurrentPage"
      searchPlaceholder="Search by Job Name"
      nameWithImage
      imageKey="resizedSmallImageUrl"
    />
  </div>
</template>

<script>
import TablePageHeader from "@/components/TablePageHeader";
import Table from "@/components/Table";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Compressor from "compressorjs";

export default {
  name: "Jobs",
  created() {
    this.fetchJobs();
    this.fetchCrews({ full: true, silent: true });
    this.fetchDepartments({ full: true, silent: true });
    this.fetchContactCategories();
  },
  data() {
    return {
      required: v => !!v || "Required!",
      arrayRequired: v => !!v && v.length != 0,
      addModal: false,
      deleteModal: false,
      filterDrawer: false,
      jobToDelete: {},
      filterPanels: -1,
      filterNames: ["crews", "department"],
      mode: "add",
      filters: {
        crews: [],
        department: [],
      },
      fields: {
        name: "",
        crews: [],
        imageUrl: null,
        categories: [],
      },
      search: "",
      activeEdits: [],
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "JOB NAME", value: "name", sortable: true },
        { text: "CREWS", value: "crewsString", sortable: false },
        { text: "DEPARTMENTS", value: "departmentsString", sortable: false },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      sortColumn: "",
    };
  },
  components: {
    TablePageHeader,
    Table,
    DeleteConfirmation,
  },
  watch: {
    filters: {
      handler(filters) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setJobsFilters({ filters });
          this.fetchJobs();
        }, 600);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "jobs",
      "jobsCount",
      "jobsCurrentPage",
      "crews",
      "departments",
      "isLoading",
      "contactCategories",
    ]),
    parsedCrews() {
      return this.crews.map(e => {
        return {
          text: e.code,
          value: e,
        };
      });
    },
    parsedContactCategories() {
      return this.contactCategories.map(e => {
        return {
          text: e.displayName,
          value: e,
        };
      });
    },
    isEdit() {
      return this.mode === "edit";
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
  methods: {
    ...mapActions([
      "fetchJobs",
      "fetchJob",
      "fetchCrews",
      "fetchDepartments",
      "fetchContactCategories",
      "createJob",
      "editJob",
      "deleteJob",
    ]),
    ...mapMutations(["setJobsFilters", "setJobsPagination"]),
    handleSortBy(e) {
      let column = "";
      if (e == "name") column = "jobNameDesc";
      this.sortColumn = column;
    },
    handleSortDesc(e) {
      const sort = {};
      sort[this.sortColumn] = e;
      this.setJobsFilters({ sort });
      this.fetchJobs();
    },
    handleNext() {
      this.setJobsPagination(this.jobsCurrentPage + 1);
      this.fetchJobs();
    },
    handlePrev() {
      this.setJobsPagination(this.jobsCurrentPage - 1);
      this.fetchJobs();
    },
    handlePageClick(pageNum) {
      if (this.jobsCurrentPage != pageNum) {
        this.setJobsPagination(+pageNum);
        this.fetchJobs();
      }
    },
    filterSelectionCount(filterKey) {
      const filter = this.filters[filterKey];
      const filterCount = filter.length;
      return filterCount > 0 ? `(${filterCount})` : "";
    },
    filterSelectionElements(filterKey, key) {
      const filter = this.filters[filterKey];
      const filterCount = filter.length;
      if (filterCount) {
        if (key) {
          return filter.map(e => e[key]).join(", ");
        }
        return filter.map(e => e.toString()).join(", ");
      }
      return "";
    },
    handleSearch(search) {
      this.setJobsFilters({ search });
      this.fetchJobs();
    },
    passClickToInput() {
      this.$refs.fileInput.click();
    },
    addImage(e) {
      let file = null;
      if (e.dataTransfer) {
        file = e.dataTransfer.files["0"];
      } else {
        file = e.target.files["0"];
      }

      const v = this;
      new Compressor(file, {
        quality: 0.9,
        maxHeight: 800,
        maxWidth: 800,
        success(result) {
          console.log(result);
          const reader = new FileReader();

          reader.onload = e => {
            v.fields.imageUrl = e.target.result;
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
    handleTextFieldClicked(field) {
      if (this.isDisabled(field)) this.activeEdits.push(field);
    },
    isDisabled(field) {
      return this.mode === "edit" && !this.activeEdits.includes(field);
    },
    handleCancel() {
      this.addModal = false;
      this.resetForms();
    },
    async handleSave() {
      if (!this.$refs.form.validate()) return;

      const { mode, fields } = this;
      const { name, crews, imageUrl, id, categories } = fields;
      const job = { name, crews, imageUrl, categories };
      if (mode === "add") {
        const jobCreated = await this.createJob(job);
        if (jobCreated) {
          this.addModal = false;
          this.resetForms();
        }
      } else if (mode === "edit") {
        const jobEdited = await this.editJob({ job, id });
        if (jobEdited) {
          this.addModal = false;
          this.resetForms();
        }
      }
    },
    handleNew() {
      this.addModal = true;
      this.resetForms();
    },
    async handleEdit(job) {
      const { name, crews, imageUrl, categories, id } = await this.fetchJob(
        job.id,
      );
      console.log(crews);
      const newCrews = [];
      for (let i = 0; i < crews.length; i++) {
        const e = crews[i];
        const index = this.crews.findIndex(d => d.id == e.crewId);
        console.log(index);
        if (index != -1) newCrews.push(this.crews[index]);
      }
      console.log(newCrews);
      const newCategories = [];
      for (let i = 0; i < categories.length; i++) {
        const e = categories[i];
        const index = this.contactCategories.findIndex(
          d => d.id == e.categoryId,
        );
        newCategories.push(this.contactCategories[index]);
      }
      this.addModal = true;
      this.mode = "edit";
      this.fields = {
        name,
        crews: newCrews,
        imageUrl,
        categories: newCategories,
        id,
      };
    },
    resetForms() {
      this.$refs.form.resetValidation();
      this.mode = "add";
      this.fields = {
        name: "",
        crews: [],
        imageUrl: null,
        categories: [],
        id: "",
      };
    },
    handleDeleteFromEdit() {
      this.addModal = false;
      this.handleDelete(this.fields);
    },
    handleDelete(job) {
      this.deleteModal = true;
      this.jobToDelete = job;
    },
    handleConfirmDelete() {
      const jobDeleted = this.deleteJob(this.jobToDelete.id);
      if (jobDeleted) {
        this.jobToDelete = {};
        this.deleteModal = false;
      }
    },
    handleFilterClicked(filter) {
      switch (filter) {
        case "crews":
          this.filterPanels = 0;
          break;
        case "department":
          this.filterPanels = 1;
          break;
      }
      this.filterDrawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload-wrapper {
  border: 1px dashed #c4c4c4 !important;
  background-color: #fbfbfb !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: grid;
  place-items: center;
  p {
    margin-top: 8px;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    span {
      color: #7fa231;
      text-decoration: underline;
    }
  }
}
.image-wrapper {
  display: grid;
  place-items: center;
  .inside-image-wrapper {
    position: relative;
    .job-image {
      width: auto;
      max-width: 420px;
      height: 150px;
      object-fit: cover;
      margin-left: auto;
      margin-right: auto;
    }
    .remove-image-button {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 28px;
      width: 28px;
      background: rgba(234, 152, 63, 0.7);
      border-radius: 5px;
      display: grid;
      place-items: center;
    }
  }
}
</style>
