<template>
  <div>
    <DeleteConfirmation
      :open.sync="deleteModal"
      @confirm="handleConfirmDelete"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete Crew"
      bodyText="This action will delete this crew permanently"
    />
    <v-dialog @click:outside="handleCancel" v-model="addModal" width="540">
      <v-card>
        <v-overlay absolute :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular
        ></v-overlay>
        <v-card-title class="addModal-header">
          <span class="addModal-header-title text-capitalize"
            >{{ mode }} Crew</span
          >
          <span>
            <v-btn
              v-if="isEdit"
              text
              class="text-body-1 text-capitalize custom-error-color"
              @click="handleDeleteFromEdit"
              >Delete Crew</v-btn
            >
            <v-btn
              v-if="!isEdit"
              text
              class="text-body-1 text-capitalize text-decoration-underline"
              @click="handleCancel"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="ml-3 text-body-1 text-capitalize"
              @click="handleSave"
            >
              Save
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text class="addModal-body">
          <v-form class="mx-0 my-0" ref="form">
            <div class="section-heading-wrapper">
              <span class="section-heading">
                Crew Details
              </span>
            </div>
            <div class="form-section">
              <span class="modal-text-field-label">Crew Code</span>
              <v-text-field
                dense
                hide-details
                placeholder="e.g. Steve A"
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('code')"
                v-model="fields.code"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('code')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
              <span class="modal-text-field-label">Crew Description</span>
              <v-textarea
                dense
                hide-details
                placeholder="e.g. Steve A’s crew does installations and maintenance for living roofs."
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('description')"
                v-model="fields.description"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('description')"
                >
                  mdi-pencil
                </v-icon>
              </v-textarea>
              <span class="modal-text-field-label">Departments</span>
              <v-autocomplete
                dense
                hide-details
                placeholder="Start typing to find departments"
                outlined
                single-line
                multiple
                :disabled="isDisabled(`departments`)"
                v-model="fields.departments"
                :items="parsedDepartments"
                class="mb-3"
                :rules="[arrayRequired]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('departments')"
                >
                  mdi-pencil
                </v-icon>
              </v-autocomplete>
              <span class="modal-text-field-label"
                >Crew Members (Optional)</span
              >
              <v-autocomplete
                dense
                hide-details
                placeholder="Start typing to find users"
                outlined
                single-line
                multiple
                :disabled="isDisabled(`crewMembers`)"
                v-model="fields.crewMembers"
                :items="parsedUsers"
                class="mb-3"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('crewMembers')"
                >
                  mdi-pencil
                </v-icon>
              </v-autocomplete>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      left
      absolute
      width="375"
      temporary
      v-model="filterDrawer"
      class="filter-drawer"
    >
      <div @click="filterDrawer = false" class="justify-end d-flex mb-14">
        <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-expansion-panels
        class="filter-expansion-panel"
        v-model="filterPanels"
        flat
        accordion
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Department {{ filterSelectionCount("department") }}</span>
              <p
                v-if="filterSelectionElements('department', 'name')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("department", "name") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-checkbox-wrapper">
            <v-checkbox
              v-for="(d, dIndex) in departments"
              :key="dIndex"
              :value="d"
              :label="d.name"
              class="text-body-1 filter-checkbox-reverse"
              :class="{ 'mt-0': dIndex == 0, 'mt-6': dIndex != 0 }"
              v-model="filters.department"
              hide-details
            >
              <template #label>
                <span style="color: black;">{{ d.name }}</span>
              </template>
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
    <TablePageHeader title="Crew" @new="handleNew" />
    <Table
      @filter="handleFilterClicked"
      @edit="handleEdit"
      @delete="handleDelete"
      @search="handleSearch"
      @next="handleNext"
      @prev="handlePrev"
      @page-click="handlePageClick"
      @sortDesc="handleSortDesc"
      @sortBy="handleSortBy"
      :items="crews"
      :headers="headers"
      :filters="filters"
      :totalItemCount="crewsCount"
      :currentPage="crewsCurrentPage"
      searchPlaceholder="Search by Crew Code"
    />
  </div>
</template>

<script>
import TablePageHeader from "@/components/TablePageHeader";
import Table from "@/components/Table";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Crews",
  created() {
    this.fetchCrews();
    this.fetchDepartments({ full: true, silent: true });
    this.fetchUsers({ full: true, silent: true });
  },
  data() {
    return {
      required: v => !!v || "Required!",
      arrayRequired: v => !!v && v.length != 0,
      addModal: false,
      deleteModal: false,
      filterDrawer: false,
      filterPanels: -1,
      filterNames: ["department"],
      mode: "add",
      fields: {
        code: "",
        description: "",
        departments: [],
        crewMembers: [],
        id: "",
      },
      filters: {
        department: [],
      },
      activeEdits: [],
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "CREW CODE NAME", value: "code", sortable: true },
        { text: "DEPARTMENTS", value: "departmentsString", sortable: false },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      sortColumn: "",
    };
  },
  components: {
    TablePageHeader,
    Table,
    DeleteConfirmation,
  },
  watch: {
    filters: {
      handler(filters) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setCrewsFilters({ filters });
          this.fetchCrews();
        }, 600);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "crews",
      "crewsCurrentPage",
      "crewsCount",
      "departments",
      "users",
      "isLoading",
    ]),
    parsedUsers() {
      return this.users.map(e => {
        return {
          text: e.fullName,
          value: e,
        };
      });
    },
    parsedDepartments() {
      return this.departments.map(e => {
        return {
          text: e.name,
          value: e,
        };
      });
    },
    isEdit() {
      return this.mode === "edit";
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
  methods: {
    ...mapActions([
      "fetchCrews",
      "fetchCrew",
      "fetchDepartments",
      "fetchUsers",
      "createCrew",
      "editCrew",
      "deleteCrew",
    ]),
    ...mapMutations(["setCrewsFilters", "setCrewsPagination"]),
    handleSortBy(e) {
      let column = "";
      if (e == "code") column = "crewCodeDesc";
      this.sortColumn = column;
    },
    handleSortDesc(e) {
      const sort = {};
      sort[this.sortColumn] = e;
      this.setCrewsFilters({ sort });
      this.fetchCrews();
    },
    handleNext() {
      this.setCrewsPagination(this.crewsCurrentPage + 1);
      this.fetchCrews();
    },
    handlePrev() {
      this.setCrewsPagination(this.crewsCurrentPage - 1);
      this.fetchCrews();
    },
    handlePageClick(pageNum) {
      if (this.crewsCurrentPage != pageNum) {
        this.setCrewsPagination(+pageNum);
        this.fetchCrews();
      }
    },
    filterSelectionCount(filterKey) {
      const filter = this.filters[filterKey];
      const filterCount = filter.length;
      return filterCount > 0 ? `(${filterCount})` : "";
    },
    filterSelectionElements(filterKey, key) {
      const filter = this.filters[filterKey];
      const filterCount = filter.length;
      if (filterCount) {
        if (key) {
          return filter.map(e => e[key]).join(", ");
        }
        return filter.map(e => e.toString()).join(", ");
      }
      return "";
    },
    handleSearch(search) {
      this.setCrewsFilters({ search });
      this.fetchCrews();
    },
    handleTextFieldClicked(field) {
      if (this.isDisabled(field)) this.activeEdits.push(field);
    },
    isDisabled(field) {
      return this.mode === "edit" && !this.activeEdits.includes(field);
    },
    async handleSave() {
      if (!this.$refs.form.validate()) return;

      const { mode, fields } = this;
      const { code, departments, crewMembers, description, id } = fields;
      const crew = { code, departments, description, crewMembers };
      if (mode === "add") {
        const crewCreated = await this.createCrew(crew);
        if (crewCreated) {
          this.addModal = false;
          this.resetForms();
        }
      } else if (mode === "edit") {
        const crewEdited = await this.editCrew({ crew, id });
        if (crewEdited) {
          this.addModal = false;
          this.resetForms();
        }
      }
    },
    handleCancel() {
      this.addModal = false;
      this.resetForms();
    },
    handleNew() {
      this.addModal = true;
      this.resetForms();
    },
    resetForms() {
      this.mode = "add";
      this.$refs.form.resetValidation();

      this.fields = {
        code: "",
        departments: [],
        description: "",
        id: "",
        crewMembers: [],
      };
    },
    async handleEdit(crew) {
      const {
        code,
        departments,
        description,
        users,
        id,
      } = await this.fetchCrew(crew.id);
      this.addModal = true;
      this.mode = "edit";
      const newDepartments = [];
      for (let i = 0; i < departments.length; i++) {
        const e = departments[i];
        const index = this.departments.findIndex(d => d.id == e.departmentId);
        newDepartments.push(this.departments[index]);
      }
      const newUsers = [];
      for (let i = 0; i < users.length; i++) {
        const e = users[i];
        const index = this.users.findIndex(d => d.id == e.userId);
        newUsers.push(this.users[index]);
      }
      this.fields = {
        code,
        departments: newDepartments,
        crewMembers: newUsers,
        description,
        id,
      };
    },
    handleDeleteFromEdit() {
      this.addModal = false;
      this.handleDelete(this.fields);
    },
    handleDelete(crew) {
      this.deleteModal = true;
      this.crewToDelete = crew;
    },
    handleConfirmDelete() {
      const crewDeleted = this.deleteCrew(this.crewToDelete.id);
      if (crewDeleted) {
        this.crewToDelete = {};
        this.deleteModal = false;
      }
    },
    handleFilterClicked(filter) {
      switch (filter) {
        case "department":
          this.filterPanels = 0;
          break;
      }
      this.filterDrawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
