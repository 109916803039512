import axios from "axios";
import axiosRetry from "axios-retry";

const devUrls = [
  "furbish.azurewebsites.net",
  "onsighttest.furbishco.com",
  "localhost:8080",
];
const isDevEnv = devUrls.includes(window.location.host);
const baseURL = isDevEnv
  ? `https://onsighttest.furbishco.com/api`
  : `https://onsight.furbishco.com/api`;

const axiosInstance = axios.create({
  baseURL,
});
axiosRetry(axiosInstance, { retries: 3 });

export default axiosInstance;
