<template>
  <v-container fluid class="table-page-title-wrapper">
    <v-container
      :fluid="isIpad"
      class="d-flex align-center table-page-title-inside-wrapper"
    >
      <h1 class="table-page-title">{{ title }}s</h1>
      <v-btn
        v-if="buttons"
        @click="$emit('new')"
        class="ml-6 table-page-button"
      >
        New {{ title }}
      </v-btn>
      <slot name="extras"></slot>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "TablePageHeader",
  props: {
    title: String,
    buttons: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-page-title-wrapper {
  .table-page-title-inside-wrapper {
    padding-left: 65px;
  }
  background-color: #454545;
  padding-top: 30px;
  padding-bottom: 8px;
  .table-page-title {
    color: white;
    font-weight: normal;
    font-size: 42px;
  }

  .table-page-button {
    background-color: #7fa231 !important;
    font-size: 16px !important;
    font-weight: normal !important;
    color: #ffffff !important;
    padding: 10px 22px 10px 22px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .table-page-title-inside-wrapper {
    padding-left: 6px !important;
  }
}
</style>
