<template>
  <div>
    <DeleteConfirmation
      :open.sync="deleteModal"
      @confirm="handleConfirmDeleteUser"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete User"
      bodyText="This action will delete this user permanently"
    />
    <v-dialog @click:outside="handleCancel" v-model="addModal" width="540">
      <v-card>
        <v-overlay absolute :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular
        ></v-overlay>
        <v-card-title class="addModal-header">
          <span class="addModal-header-title text-capitalize"
            >{{ mode }} User</span
          >
          <span>
            <v-btn
              v-if="isEdit"
              text
              class="text-body-1 text-capitalize custom-error-color"
              @click="handleDeleteUserFromEdit"
              >Delete User</v-btn
            >
            <v-btn
              v-if="!isEdit"
              text
              class="text-body-1 text-capitalize text-decoration-underline"
              @click="handleCancel"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="ml-3 text-body-1 text-capitalize"
              @click="handleSave"
            >
              Save
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text class="addModal-body">
          <v-form class="mx-0 my-0" ref="form">
            <div class="section-heading-wrapper">
              <span class="section-heading">
                User Details
              </span>
            </div>
            <div class="form-section">
              <span class="modal-text-field-label">Full Name</span>
              <v-text-field
                dense
                hide-details
                placeholder="e.g. John Doe"
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('name')"
                v-model="fields.fullName"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('name')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
              <span class="modal-text-field-label">Title</span>
              <v-text-field
                dense
                hide-details
                placeholder="e.g. Project Manager"
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('title')"
                v-model="fields.title"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('title')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
              <span class="modal-text-field-label">Email Address</span>
              <v-text-field
                dense
                hide-details
                placeholder="e.g. jdoe@furbishco.com"
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('email')"
                v-model="fields.emailAddress"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('email')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
              <span class="modal-text-field-label">Password</span>
              <v-text-field
                dense
                hide-details
                type="password"
                placeholder="********"
                outlined
                single-line
                class="mb-8"
                :disabled="isDisabled('password')"
                v-model="fields.password"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('password')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
            </div>
            <div class="section-heading-wrapper">
              <span class="section-heading">
                User Roles
              </span>
            </div>
            <div class="form-section">
              <span
                class="modal-text-field-label d-flex justify-space-between align-center"
              >
                Select a User Role
                <v-btn
                  v-if="isEdit"
                  icon
                  small
                  @click="handleTextFieldClicked('role')"
                  class="mr-3"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </span>
              <v-radio-group
                :disabled="isDisabled('role')"
                hide-details
                class="mt-2 text-body-1"
                v-model="fields.roleId"
                :rules="[roleRequired]"
              >
                <v-radio
                  class="user-role-radio"
                  v-for="(role, i) in userRoles"
                  :key="i"
                  :value="role.id"
                >
                  <span slot="label" class="black--text">{{ role.name }}</span>
                </v-radio>
              </v-radio-group>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      left
      absolute
      width="375"
      temporary
      v-model="filterDrawer"
      class="filter-drawer"
    >
      <div @click="filterDrawer = false" class="justify-end d-flex mb-14">
        <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-expansion-panels
        class="filter-expansion-panel"
        v-model="filterPanels"
        flat
        accordion
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Role {{ filterSelectionCount("role") }}</span>
              <p
                v-if="filterSelectionElements('role', 'name')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("role", "name") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-checkbox-wrapper">
            <v-checkbox
              v-for="(d, dIndex) in userRoles"
              :key="dIndex"
              :value="d"
              :label="d.name"
              class="text-body-1 filter-checkbox-reverse"
              :class="{ 'mt-0': dIndex == 0, 'mt-6': dIndex != 0 }"
              v-model="filters.role"
              hide-details
            >
              <template #label>
                <span style="color: black;">{{ d.name }}</span>
              </template>
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Department {{ filterSelectionCount("department") }}</span>
              <p
                v-if="filterSelectionElements('department', 'name')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("department", "name") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-checkbox-wrapper">
            <v-checkbox
              v-for="(d, dIndex) in departments"
              :key="dIndex"
              :value="d"
              :label="d.name"
              class="text-body-1 filter-checkbox-reverse"
              :class="{ 'mt-0': dIndex == 0, 'mt-6': dIndex != 0 }"
              v-model="filters.department"
              hide-details
            >
              <template #label>
                <span style="color: black;">{{ d.name }}</span>
              </template>
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
    <TablePageHeader title="User" @new="handleNewUser" />
    <Table
      @filter="handleFilterClicked"
      @edit="handleEditUser"
      @delete="handleDeleteUser"
      @search="handleSearch"
      @next="handleNext"
      @prev="handlePrev"
      @page-click="handlePageClick"
      @sortDesc="handleSortDesc"
      @sortBy="handleSortBy"
      :items="users"
      :headers="headers"
      :filters="filters"
      :totalItemCount="usersCount"
      :currentPage="usersCurrentPage"
      searchPlaceholder="Search by Name, or Title"
    />
  </div>
</template>

<script>
import TablePageHeader from "@/components/TablePageHeader";
import Table from "@/components/Table";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Users",
  async created() {
    await this.fetchUsers();
    this.fetchDepartments({ full: true });
  },
  data() {
    return {
      required: v => !!v || "Required!",
      roleRequired: v => v >= 0 || "Required!",
      addModal: false,
      deleteModal: false,
      filterDrawer: false,
      filterPanels: -1,
      filterNames: ["role", "department"],
      filters: {
        role: [],
        department: [],
      },
      mode: "add",
      fields: {
        fullName: "",
        title: "",
        emailAddress: "",
        password: "",
        roleId: -1,
      },
      userToDelete: {},
      activeEdits: [],
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "NAME", value: "fullName", sortable: true },
        { text: "TITLE", value: "title", sortable: false },
        { text: "ROLE", value: "role", sortable: false },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      sortColumn: "",
    };
  },
  components: {
    TablePageHeader,
    Table,
    DeleteConfirmation,
  },
  watch: {
    filters: {
      handler(filters) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setUsersFilters({ filters });
          this.fetchUsers();
        }, 600);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "users",
      "userRoles",
      "usersCount",
      "usersCurrentPage",
      "departments",
      "isLoading",
    ]),
    isEdit() {
      return this.mode === "edit";
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "fetchDepartments",
      "createUser",
      "editUser",
      "deleteUser",
    ]),
    ...mapMutations(["setUsersFilters", "setUsersPagination"]),
    handleSortBy(e) {
      let column = "";
      if (e == "fullName") column = "nameDesc";
      this.sortColumn = column;
    },
    handleSortDesc(e) {
      const sort = {};
      sort[this.sortColumn] = e;
      this.setUsersFilters({ sort });
      this.fetchUsers();
    },
    handleNext() {
      this.setUsersPagination(this.usersCurrentPage + 1);
      this.fetchUsers();
    },
    handlePrev() {
      this.setUsersPagination(this.usersCurrentPage - 1);
      this.fetchUsers();
    },
    handlePageClick(pageNum) {
      if (this.usersCurrentPage != pageNum) {
        this.setUsersPagination(+pageNum);
        this.fetchUsers();
      }
    },
    filterSelectionCount(filterKey) {
      const filter = this.filters[filterKey];
      const filterCount = filter.length;
      return filterCount > 0 ? `(${filterCount})` : "";
    },
    filterSelectionElements(filterKey, key) {
      const filter = this.filters[filterKey];
      const filterCount = filter.length;
      if (filterCount) {
        if (key) {
          return filter.map(e => e[key]).join(", ");
        }
        return filter.map(e => e.toString()).join(", ");
      }
      return "";
    },
    handleSearch(search) {
      this.setUsersFilters({ search });
      this.fetchUsers();
    },
    handleTextFieldClicked(field) {
      if (this.isDisabled(field)) this.activeEdits.push(field);
    },
    isDisabled(field) {
      return this.mode === "edit" && !this.activeEdits.includes(field);
    },
    resetForms() {
      this.$refs.form.resetValidation();
      this.mode = "add";
      this.fields = {
        fullName: "",
        title: "",
        emailAddress: "",
        password: "",
        roleId: "",
        id: "",
      };
      this.activeEdits = [];
    },
    async handleSave() {
      const { mode, fields } = this;
      const { emailAddress, password, fullName, title, roleId, id } = fields;
      const user = { emailAddress, password, fullName, title, roleId: +roleId };
      if (mode === "add") {
        if (!this.$refs.form.validate()) return;
        const userCreated = await this.createUser(user);
        if (userCreated) {
          this.addModal = false;
          this.resetForms();
        }
      } else if (mode === "edit") {
        const userEdited = await this.editUser({ user, id });
        if (userEdited) {
          this.addModal = false;
          this.resetForms();
        }
      }
    },
    handleNewUser() {
      this.addModal = true;
      this.resetForms();
    },
    handleEditUser(user) {
      const { emailAddress, title, password, fullName, roleId, id } = user;
      this.addModal = true;
      this.mode = "edit";
      this.fields = {
        emailAddress,
        title,
        password,
        fullName,
        roleId: roleId,
        id,
      };
    },
    handleDeleteUserFromEdit() {
      this.addModal = false;
      this.handleDeleteUser(this.fields);
    },
    handleDeleteUser(user) {
      this.deleteModal = true;
      this.userToDelete = user;
    },
    handleCancel() {
      this.addModal = false;
      this.resetForms();
    },
    handleConfirmDeleteUser() {
      const userDeleted = this.deleteUser(this.userToDelete.id);
      if (userDeleted) {
        this.userToDelete = {};
        this.deleteModal = false;
      }
    },
    handleFilterClicked(filter) {
      switch (filter) {
        case "role":
          this.filterPanels = 0;
          break;
        case "department":
          this.filterPanels = 1;
          break;
      }
      this.filterDrawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
