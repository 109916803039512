<template>
  <div>
    <DeleteConfirmation
      :open.sync="deleteModal"
      @confirm="handleConfirmDelete"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete Department"
      bodyText="This action will delete this department permanently"
    />
    <v-dialog @click:outside="handleCancel" v-model="addModal" width="540">
      <v-card>
        <v-overlay absolute :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular
        ></v-overlay>
        <v-card-title class="addModal-header">
          <span class="addModal-header-title text-capitalize"
            >{{ mode }} Department</span
          >
          <span>
            <v-btn
              v-if="isEdit"
              text
              class="text-body-1 text-capitalize custom-error-color"
              @click="handleDeleteDepartmentFromEdit"
              >Delete Department</v-btn
            >
            <v-btn
              v-if="!isEdit"
              text
              class="text-body-1 text-capitalize text-decoration-underline"
              @click="handleCancel"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="ml-3 text-body-1 text-capitalize"
              @click="handleSave"
            >
              Save
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text class="addModal-body">
          <v-form class="mx-0 my-0" ref="form">
            <div class="section-heading-wrapper">
              <span class="section-heading">
                Department Details
              </span>
            </div>
            <div class="form-section">
              <span class="modal-text-field-label">Department Name</span>
              <v-text-field
                dense
                hide-details
                placeholder="e.g. Living Roof Installation"
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('name')"
                v-model="fields.name"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('name')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
              <span class="modal-text-field-label">Department Code</span>
              <v-text-field
                dense
                hide-details
                placeholder="e.g. LR - I"
                outlined
                single-line
                class="mb-3"
                :disabled="isDisabled('code')"
                v-model="fields.code"
                :rules="[required]"
              >
                <v-icon
                  v-if="isEdit"
                  class="edit-field-button"
                  slot="append"
                  @click="handleTextFieldClicked('code')"
                >
                  mdi-pencil
                </v-icon>
              </v-text-field>
            </div>
            <div class="section-heading-wrapper">
              <span class="section-heading">
                Review Process
              </span>
            </div>
            <div>
              <p class="mt-1">
                The more reviewers you add, the more approvals reports will be
                needed before distribution (e.g. 3 Reviewers = 3 Approvals).
              </p>
            </div>
            <div class="form-section">
              <span v-for="(reviewer, i) in fields.reviewers" :key="i">
                <span class="modal-text-field-label">Reviewer {{ i + 1 }}</span>
                <div
                  v-if="!isDisabled(`reviewer${i}`)"
                  class="d-flex justify-space-between"
                >
                  <v-autocomplete
                    v-if="!isDisabled(`reviewer${i}`)"
                    dense
                    hide-details
                    placeholder="Start typing to find users"
                    outlined
                    single-line
                    :disabled="isDisabled(`reviewer${i}`)"
                    v-model="fields.reviewers[i]"
                    :items="parsedReviewers"
                    class="mb-3 reviewer-autocomplete"
                    :rules="[autoCompleteRequired]"
                  >
                  </v-autocomplete>
                  <span
                    v-if="isEdit"
                    class="custom-error-color delete-review-button"
                    @click="handleDeleteReview(i)"
                  >
                    Delete Review
                  </span>
                </div>
                <div
                  v-if="isDisabled(`reviewer${i}`)"
                  class="d-flex justify-space-between"
                >
                  <v-text-field
                    v-if="isDisabled(`reviewer${i}`)"
                    dense
                    hide-details
                    outlined
                    single-line
                    class="mb-3 reviewer-autocomplete"
                    disabled
                    :value="fields.reviewers[i].name"
                  >
                    <v-icon
                      v-if="isEdit"
                      class="edit-field-button"
                      slot="append"
                      @click="handleTextFieldClicked(`reviewer${i}`)"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-text-field>
                  <span
                    v-if="isEdit"
                    class="custom-error-color delete-review-button"
                    @click="handleDeleteReview(i)"
                  >
                    Delete Review
                  </span>
                </div>
              </span>
              <span
                v-if="fields.reviewers.length < 3"
                class="primary--text add-reviewer-button"
                @click="handleAddReviewer"
              >
                + Add Reviewer
              </span>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TablePageHeader title="Department" @new="handleNewDepartment" />
    <Table
      @edit="handleEditDepartment"
      @delete="handleDeleteDepartment"
      @search="handleSearch"
      @next="handleNext"
      @prev="handlePrev"
      @page-click="handlePageClick"
      :items="departments"
      :headers="headers"
      :totalItemCount="departmentsCount"
      :currentPage="departmentsCurrentPage"
      searchPlaceholder="Search by Department Name, or Name of Reviewers"
    />
  </div>
</template>

<script>
import TablePageHeader from "@/components/TablePageHeader";
import Table from "@/components/Table";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Departments",
  created() {
    this.fetchDepartments();
    this.fetchReviewers({ silent: true });
  },
  data() {
    return {
      required: v => !!v || "Required!",
      autoCompleteRequired: v => !!v && !!v.id,
      addModal: false,
      deleteModal: false,
      filterDrawer: false,
      departmentToDelete: {},
      filterPanels: -1,
      filterNames: ["# of reviewers"],
      mode: "add",
      fields: {
        title: "",
        code: "",
        reviewers: [{}],
        id: "",
      },
      activeEdits: [],
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "DEPARTMENT NAME", value: "name", sortable: false },
        { text: "# OF REVIEWERS", value: "reviewersCount", sortable: false },
        { text: "REVIEWERS", value: "reviewersString", sortable: false },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  components: {
    TablePageHeader,
    Table,
    DeleteConfirmation,
  },
  computed: {
    ...mapGetters([
      "departments",
      "departmentsCount",
      "departmentsCurrentPage",
      "users",
      "reviewers",
      "isLoading",
    ]),
    parsedReviewers() {
      return this.reviewers.map(e => {
        return {
          text: e.fullName,
          value: e,
        };
      });
    },
    isEdit() {
      return this.mode === "edit";
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
  methods: {
    ...mapActions([
      "fetchDepartments",
      "fetchReviewers",
      "createDepartment",
      "editDepartment",
      "deleteDepartment",
    ]),
    ...mapMutations(["setDepartmentsFilters", "setDepartmentsPagination"]),
    handleNext() {
      this.setDepartmentsPagination(this.departmentsCurrentPage + 1);
      this.fetchDepartments();
    },
    handlePrev() {
      this.setDepartmentsPagination(this.departmentsCurrentPage - 1);
      this.fetchDepartments();
    },
    handlePageClick(pageNum) {
      if (this.departmentsCurrentPage != pageNum) {
        this.setDepartmentsPagination(+pageNum);
        this.fetchDepartments();
      }
    },
    handleAddReviewer() {
      this.fields.reviewers.push({});
      this.activeEdits.push(`reviewer${this.fields.reviewers.length - 1}`);
    },
    handleDeleteReview(index) {
      this.fields.reviewers.splice(index, 1);
    },
    handleSearch(search) {
      this.setDepartmentsFilters({ search });
      this.fetchDepartments();
    },
    handleTextFieldClicked(field) {
      if (this.isDisabled(field)) this.activeEdits.push(field);
    },
    isDisabled(field) {
      return this.mode === "edit" && !this.activeEdits.includes(field);
    },
    handleCancel() {
      this.addModal = false;
      this.resetForms();
    },
    async handleSave() {
      if (!this.$refs.form.validate()) return;

      const { mode, fields } = this;
      const { name, code, reviewers, id } = fields;
      const department = { name, code, reviewers };
      if (mode === "add") {
        const departmentCreated = await this.createDepartment(department);
        if (departmentCreated) {
          this.addModal = false;
          this.resetForms();
        }
      } else if (mode === "edit") {
        const departmentEdited = await this.editDepartment({ department, id });
        if (departmentEdited) {
          this.addModal = false;
          this.resetForms();
        }
      }
    },
    handleNewDepartment() {
      this.addModal = true;
      this.resetForms();
    },
    resetForms() {
      this.$refs.form.resetValidation();
      this.mode = "add";
      this.fields = {
        name: "",
        code: "",
        reviewers: [{}],
        id: "",
      };
      this.activeEdits = [];
    },
    handleEditDepartment(department) {
      const { name, code, reviewers, id } = department;
      this.addModal = true;
      this.mode = "edit";
      this.fields = {
        name,
        code,
        reviewers,
        id,
      };
    },
    handleDeleteDepartmentFromEdit() {
      this.addModal = false;
      this.handleDeleteDepartment(this.fields);
    },
    handleDeleteDepartment(department) {
      this.deleteModal = true;
      this.departmentToDelete = department;
    },
    handleConfirmDelete() {
      const departmentDeleted = this.deleteDepartment(
        this.departmentToDelete.id,
      );
      if (departmentDeleted) {
        this.departmentToDelete = {};
        this.deleteModal = false;
      }
    },
    handleFilterClicked(filter) {
      switch (filter) {
        case "# of reviewers":
          this.filterPanels = 0;
          break;
        case "department":
          this.filterPanels = 1;
          break;
      }
      this.filterDrawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-reviewer-button {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding-top: 8px;
  padding-bottom: 9px;
}
.reviewer-autocomplete {
  width: 300px;
}
.delete-review-button {
  height: 40px;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 40px !important;
  margin-left: 24px;
  margin-right: -12px;
}
</style>
