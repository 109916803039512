import Vue from "vue";
import Vuex from "vuex";
import axios from "../axiosApi";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    user: {},
    reviewers: [],
    users: [],
    usersCount: 0,
    usersFilters: {},
    usersCurrentPage: 1,
    departments: [],
    departmentsCount: 0,
    departmentsFilters: {},
    departmentsCurrentPage: 1,
    crews: [],
    crewsCount: 0,
    crewsFilters: {},
    crewsCurrentPage: 1,
    jobs: [],
    jobsCount: 0,
    jobsFilters: {},
    jobsCurrentPage: 1,
    reports: [],
    reportsCount: 0,
    reportsFilters: {},
    reportsCurrentPage: 1,
    loading: false,
    userRoles: [],
    pendingReview: [],
    recentReports: [],
    contactCategories: [],
    phraseCategories: [
      {
        id: 1,
        name: "LR-I",
      },
      {
        id: 2,
        name: "BW",
      },
      {
        id: 3,
        name: "LR-M",
      },
      {
        id: 4,
        name: "Site Visit",
      },
    ],
    suggestedPhrases: [],
    emailTemplate: "",
    alert: {
      message: "",
      type: "success",
      visible: false,
    },
    serverInfo: {},
    activityLog: [],
  },
  getters: {
    alert: state => state.alert,
    isAuthenticated: state => !!state.token,
    isLoading: state => state.loading,
    user: state => state.user,
    userRoles: state => state.userRoles,
    reviewers: state => state.reviewers,
    users: state => state.users,
    usersCount: state => state.usersCount,
    usersCurrentPage: state => state.usersCurrentPage,
    departments: state => state.departments,
    departmentsCount: state => state.departmentsCount,
    departmentsCurrentPage: state => state.departmentsCurrentPage,
    crews: state => state.crews,
    crewsCount: state => state.crewsCount,
    crewsCurrentPage: state => state.crewsCurrentPage,
    jobs: state => state.jobs,
    jobsCount: state => state.jobsCount,
    jobsCurrentPage: state => state.jobsCurrentPage,
    reports: state => state.reports,
    reportsCount: state => state.reportsCount,
    reportsCurrentPage: state => state.reportsCurrentPage,
    hasReviewAccess: state => state.user && state.user.roleId >= 2,
    pendingReview: state => state.pendingReview,
    recentReports: state => state.recentReports,
    contactCategories: state => state.contactCategories,
    phraseCategories: state => state.phraseCategories,
    suggestedPhrases: state => state.suggestedPhrases,
    emailTemplate: state => state.emailTemplate,
    roleId: state => state.user.roleId,
    serverInfo: state => state.serverInfo,
    activityLog: state => state.activityLog,
  },
  mutations: {
    resetFilters(state) {
      state.usersFilters = {
        sort: {
          nameDesc: false,
        },
      };
      state.departmentsFilters = {};
      state.crewsFilters = {
        sort: {
          crewCodeDesc: false,
        },
      };
      state.jobsFilters = {
        sort: {
          jobNameDesc: false,
        },
      };
      state.reportsFilters = {
        sort: {
          dateDesc: true,
        },
      };
    },
    setAlert(state, alert) {
      state.alert = { ...state.alert, ...alert, visible: true };
    },
    setErrorAlert(state, message) {
      state.alert = { message, type: "error", visible: true };
    },
    setSuccessAlert(state, message) {
      state.alert = { message, type: "grey darken-4", visible: true };
    },
    setWarningAlert(state, message) {
      state.alert = { message, type: "warning", visible: true };
    },
    setUser(state, user) {
      state.user = { ...user };
    },
    setReviewers(state, { users }) {
      state.reviewers = users;
    },
    setUsers(state, { users, count }) {
      state.users = users;
      state.usersCount = count;
    },
    setUsersFilters(state, filters) {
      state.usersFilters = { ...state.usersFilters, ...filters };
      state.usersCurrentPage = 1;
    },
    setUsersPagination(state, pageNum) {
      state.usersCurrentPage = pageNum;
    },
    setDepartments(state, { departments, count }) {
      state.departments = departments;
      state.departmentsCount = count;
    },
    setDepartmentsFilters(state, filters) {
      state.departmentsFilters = { ...state.departmentsFilters, ...filters };
      state.departmentsCount = 1;
    },
    setDepartmentsPagination(state, pageNum) {
      state.departmentsCurrentPage = pageNum;
    },
    setCrews(state, { crews, count }) {
      state.crews = crews;
      state.crewsCount = count;
    },
    setCrewsFilters(state, filters) {
      state.crewsFilters = { ...state.crewsFilters, ...filters };
      state.crewsCurrentPage = 1;
    },
    setCrewsPagination(state, pageNum) {
      state.crewsCurrentPage = pageNum;
    },
    setJobs(state, { jobs, count }) {
      state.jobs = jobs;
      state.jobsCount = count;
    },
    setJobsFilters(state, filters) {
      state.jobsFilters = { ...state.jobsFilters, ...filters };
      state.jobsCurrentPage = 1;
    },
    setJobsPagination(state, pageNum) {
      state.jobsCurrentPage = pageNum;
    },
    setReports(state, { reports, count }) {
      state.reports = reports;
      state.reportsCount = count;
    },
    setReportsFilters(state, filters) {
      state.reportsFilters = { ...state.reportsFilters, ...filters };
      state.reportsCurrentPage = 1;
    },
    setReportsPagination(state, pageNum) {
      state.reportsCurrentPage = pageNum;
    },
    setContactCategories(state, contactCategories) {
      state.contactCategories = contactCategories;
    },
    setSuggestedPhrases(state, suggestedPhrases) {
      state.suggestedPhrases = suggestedPhrases;
    },
    setEmailTemplate(state, emailTemplate) {
      state.emailTemplate = emailTemplate;
    },
    updateUser(state, user) {
      const index = state.users.findIndex(e => e.id === user.id);
      state.users.splice(index, 1, { ...state.users[index], ...user });
    },
    removeUser(state, id) {
      const index = state.users.findIndex(e => e.id === id);
      state.users.splice(index, 1);
    },
    updateDepartment(state, department) {
      const index = state.departments.findIndex(e => e.id === department.id);
      state.departments.splice(index, 1, {
        ...state.departments[index],
        ...department,
      });
    },
    removeDepartment(state, id) {
      const index = state.departments.findIndex(e => e.id === id);
      state.departments.splice(index, 1);
    },
    removeCrew(state, id) {
      const index = state.crews.findIndex(e => e.id === id);
      state.crews.splice(index, 1);
    },
    removeJob(state, id) {
      const index = state.jobs.findIndex(e => e.id === id);
      state.jobs.splice(index, 1);
    },
    setUserRoles(state, roles) {
      state.userRoles = roles;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setToken(state, token) {
      state.token = token;
      Vue.prototype.$http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    },
    setPendingReview(state, pendingReview) {
      state.pendingReview = pendingReview;
    },
    setRecentReports(state, recentReports) {
      state.recentReports = recentReports;
    },
    setServerInfo(state, serverInfo) {
      state.serverInfo = serverInfo;
    },
    setActivityLog(state, activityLog) {
      state.activityLog = activityLog;
    },
  },
  actions: {
    async login({ commit }, payload) {
      const { email, password, remember } = payload;
      try {
        const response = await axios.post("/login", {
          username: email,
          password: password,
        });
        const {
          data: { expiration, token },
        } = response;

        if (remember) {
          localStorage.setItem("token", token);
          localStorage.setItem("expiration", expiration);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("expiration");
        } else {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("expiration", expiration);
          localStorage.removeItem("token");
          localStorage.removeItem("expiration");
        }
        commit("setToken", token);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async fetchReviewers({ commit }, payload) {
      try {
        const silent = payload && payload.silent;
        if (!silent) {
          commit("setLoading", true);
        }
        const params = {
          top: 10000,
          NameDesc: false,
        };
        let roleFilterString = "RoleIds=2&RoleIds=3&RoleIds=4&";
        const {
          data: { lists: users },
        } = await axios.get(`/Users/GetList?${roleFilterString}`, { params });
        if (users) {
          commit("setReviewers", { users });
        } else {
          commit("setReviewers", { users: [] });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchUsers({ state, commit }, payload) {
      try {
        const silent = payload && payload.silent;
        if (!silent) {
          commit("setLoading", true);
        }
        const { filters, search, sort } = state.usersFilters;
        let userRoles = state.userRoles;
        if (userRoles.length == 0) {
          const {
            data: { roles },
          } = await axios.get("/userRoles");
          userRoles = roles;
        }
        let departmentFilters = [];
        let roleFilters = [];
        if (filters) {
          const { role, department } = filters;
          if (role) {
            roleFilters = role.map(e => e.id);
          }
          if (department) {
            departmentFilters = department.map(e => e.id);
          }
        }
        const sortObject = {
          NameDesc: null,
        };
        if (sort) {
          const { nameDesc } = sort;
          if (nameDesc) {
            sortObject["NameDesc"] = true;
          } else if (nameDesc == false) {
            sortObject["NameDesc"] = false;
          }
        }
        let top = 10;
        if (payload) {
          const { full } = payload;
          if (full) {
            top = 999999;
          }
        }
        const params = {
          top,
          skip: (state.usersCurrentPage - 1) * 10,
          search: search || "",
          ...sort,
        };
        let departmentFilterString = "";
        for (let i = 0; i < departmentFilters.length; i++) {
          departmentFilterString += `DepartmentIds=${departmentFilters[i]}&`;
        }
        let roleFilterString = "";
        for (let i = 0; i < roleFilters.length; i++) {
          roleFilterString += `RoleIds=${roleFilters[i]}&`;
        }
        const {
          data: { lists: users, count },
        } = await axios.get(
          `/Users/GetList?${departmentFilterString}${roleFilterString}`,
          { params },
        );
        if (users) {
          const usersWithRoles = users.map(e => {
            const role = userRoles.find(element => element.id === e.roleId);
            return {
              ...e,
              role: role.name,
            };
          });
          commit("setUsers", { users: usersWithRoles, count });
          commit("setUserRoles", userRoles);
        } else {
          commit("setUsers", { users: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchDepartments({ state, commit }, payload) {
      try {
        const silent = payload && payload.silent;
        if (!silent) {
          commit("setLoading", true);
        }
        let top = 10;
        if (payload) {
          const { full } = payload;
          if (full) {
            top = 999999;
          }
        }
        const params = {
          top,
          skip: (state.departmentsCurrentPage - 1) * 10,
          search: state.departmentsFilters.search || "",
        };
        const {
          data: { lists: departments, count },
        } = await axios.get("/Departments/GetList", {
          params,
        });
        if (departments) {
          commit("setDepartments", {
            departments: departments.map(e => {
              return {
                ...e,
                reviewers: e.reviewers.sort((a, b) => a.row - b.row),
                reviewersString: [
                  ...new Set(e.reviewers.map(r => r.name)),
                ].join(", "),
              };
            }),
            count,
          });
        } else {
          commit("setDepartments", { departments: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchDepartmentsForWizard({ commit }) {
      try {
        commit("setLoading", true);
        const {
          data: { lists: departments, count },
        } = await axios.get("/Departments/ForWizard");
        if (departments) {
          commit("setDepartments", {
            departments,
            count,
          });
        } else {
          commit("setDepartments", { departments: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchCrews({ commit, state }, payload) {
      try {
        const silent = payload && payload.silent;
        if (!silent) {
          commit("setLoading", true);
        }
        const { filters, search, sort } = state.crewsFilters;
        let departmentFilters = [];
        if (filters) {
          const { department } = filters;
          if (department) {
            departmentFilters = department.map(e => e.id);
          }
        }
        let top = 10;
        if (payload) {
          const { full } = payload;
          if (full) {
            top = 999999;
          }
        }
        const sortObject = {
          CrewCodeDesc: null,
        };
        if (sort) {
          const { crewCodeDesc } = sort;
          if (crewCodeDesc) {
            sortObject["CrewCodeDesc"] = true;
          } else if (crewCodeDesc == false) {
            sortObject["CrewCodeDesc"] = false;
          }
        }
        const params = {
          top,
          skip: (state.crewsCurrentPage - 1) * 10,
          search: search || "",
          ...sort,
        };
        let departmentFilterString = "";
        for (let i = 0; i < departmentFilters.length; i++) {
          departmentFilterString += `DepartmentIds=${departmentFilters[i]}&`;
        }
        const {
          data: { lists: crews, count },
        } = await axios.get(`/Crews/GetList?${departmentFilterString}`, {
          params,
        });
        if (crews) {
          commit("setCrews", {
            crews: crews.map(e => {
              return {
                ...e,
                departmentsString: [
                  ...new Set(e.departments.map(r => r.departmentCode)),
                ].join(", "),
              };
            }),
            count,
          });
        } else {
          commit("setCrews", { crews: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchCrewsForWizard({ commit }, departmentId) {
      try {
        commit("setLoading", true);
        const params = {
          departmentId: departmentId,
        };
        const {
          data: { lists: crews, count },
        } = await axios.get(`/Crews/ForWizard`, {
          params,
        });
        if (crews) {
          commit("setCrews", {
            crews,
            count,
          });
        } else {
          commit("setCrews", { crews: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchCrew({ commit }, id) {
      try {
        commit("setLoading", true);
        const { data, status } = await axios.get(`/Crews/GetById/${id}`);
        console.log(data);
        if (status == 200) {
          return data;
        }
        return false;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchJobs({ state, commit }, payload) {
      try {
        const silent = payload && payload.silent;
        if (!silent) {
          commit("setLoading", true);
        }
        const { filters, search, sort } = state.jobsFilters;
        let departmentFilters = [];
        let crewFilters = [];
        if (filters) {
          const { crews, department } = filters;
          if (crews) {
            crewFilters = crews.map(e => e.id);
          }
          if (department) {
            departmentFilters = department.map(e => e.id);
          }
        }
        let top = 10;
        if (payload) {
          const { full } = payload;
          if (full) {
            top = 999999;
          }
        }

        const sortObject = {
          JobNameDesc: null,
        };
        if (sort) {
          const { jobNameDesc } = sort;
          if (jobNameDesc) {
            sortObject["JobNameDesc"] = true;
          } else if (jobNameDesc == false) {
            sortObject["JobNameDesc"] = false;
          }
        }
        const params = {
          top,
          skip: (state.jobsCurrentPage - 1) * 10,
          search: search || "",
          ...sort,
        };
        let departmentFilterString = "";
        for (let i = 0; i < departmentFilters.length; i++) {
          departmentFilterString += `DepartmentIds=${departmentFilters[i]}&`;
        }
        let crewsFilterString = "";
        for (let i = 0; i < crewFilters.length; i++) {
          crewsFilterString += `CrewsIds=${crewFilters[i]}&`;
        }
        const {
          data: { lists: jobs, count },
        } = await axios.get(
          `/Jobs/GetList?${departmentFilterString}${crewsFilterString}`,
          { params },
        );
        if (jobs) {
          commit("setJobs", {
            jobs: jobs.map(e => {
              return {
                ...e,
                departmentsString: [
                  ...new Set(e.departments.map(r => r.departmentCode)),
                ].join(", "),
                crewsString: [...new Set(e.crews.map(r => r.crewCode))].join(
                  ", ",
                ),
              };
            }),
            count,
          });
        } else {
          commit("setJobs", { jobs: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchJobsForWizard({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { departmentId, crewId } = payload;
        const params = {
          departmentId,
          crewId,
        };
        const {
          data: { lists: jobs, count },
        } = await axios.get(`/Jobs/ForWizard`, { params });
        if (jobs) {
          commit("setJobs", {
            jobs,
            count,
          });
        } else {
          commit("setJobs", { jobs: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchJob({ commit }, id) {
      try {
        commit("setLoading", true);
        const { data, status } = await axios.get(`/Jobs/GetById/${id}`);
        console.log(data);
        if (status == 200) {
          return data;
        }
        return false;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchReports({ state, commit }) {
      try {
        commit("setLoading", true);
        const { filters, search, sort } = state.reportsFilters;

        let departmentFilters = [];
        let statusFilters = [];
        let crewFilters = [];
        let startDate = "";
        let endDate = "";
        if (filters) {
          const { department, status, crews, date } = filters;
          if (department) {
            departmentFilters = department.map(e => e.id);
          }
          if (status) {
            statusFilters = status.map(e => e.value);
          }
          if (crews) {
            crewFilters = crews.map(e => e.id);
          }
          if (date.length) {
            [startDate, endDate] = date;
            if (new Date(startDate) > new Date(endDate)) {
              [startDate, endDate] = [endDate, startDate];
            }
            startDate =
              new Date(startDate).getTime() +
              new Date().getTimezoneOffset() * 60000;
            startDate = new Date(startDate).toISOString();
            endDate =
              new Date(endDate).getTime() +
              new Date().getTimezoneOffset() * 60000 +
              1439 * 60000;
            endDate = new Date(endDate).toISOString();
          }
        }
        const sortObject = {
          JobNameDesc: null,
          DateDesc: null,
        };
        if (sort) {
          const { jobNameDesc, dateDesc } = sort;
          if (jobNameDesc) {
            sortObject["JobNameDesc"] = true;
          } else if (jobNameDesc == false) {
            sortObject["JobNameDesc"] = false;
          }
          if (dateDesc) {
            sortObject["DateDesc"] = true;
          } else if (dateDesc == false) {
            sortObject["DateDesc"] = false;
          }
        }
        let departmentFilterString = "";
        for (let i = 0; i < departmentFilters.length; i++) {
          departmentFilterString += `DepartmentIds=${departmentFilters[i]}&`;
        }

        let statusFilterString = "";
        for (let i = 0; i < statusFilters.length; i++) {
          statusFilterString += `status=${statusFilters[i]}&`;
        }

        let crewsFilterString = "";
        for (let i = 0; i < crewFilters.length; i++) {
          crewsFilterString += `CrewsIds=${crewFilters[i]}&`;
        }

        let dateFilterString = `StartDateTime=${startDate}&EndDateTime=${endDate}`;

        const params = {
          top: 10,
          skip: (state.reportsCurrentPage - 1) * 10,
          search: search || "",
          ...sortObject,
        };
        const { data } = await axios.get(
          `/Reports/GetList?${departmentFilterString}${statusFilterString}${crewsFilterString}${dateFilterString}`,
          {
            params,
          },
        );
        const parseStatus = status => {
          if (status === "Created") return "Created";
          if (status === "Submitted") return "Submitted";
          if (status === "Reviewer1") return "Review 1 Completed";
          if (status === "Reviewer2") return "Review 2 Completed";
          if (status === "Reviewer3") return "Final Review Completed";
          if (status === "Completed") return "Final Review Completed";
          if (status === "Archived") return "Archived";
        };
        const { lists: reports, count } = data;
        if (reports) {
          commit("setReports", {
            reports: reports.map(e => ({
              ...e,
              createDate: `${e.createDate}Z`,
              createDateString: new Date(
                `${e.createDate}Z`,
              ).toLocaleDateString(),
              parsedStatus: parseStatus(e.status),
            })),
            count,
          });
        } else {
          commit("setReports", { reports: [], count: 0 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchReport({ commit }, id) {
      try {
        commit("setLoading", true);
        const { data, status } = await axios.get(`/Reports/GetById/${id}`);
        if (status) {
          return { ...data, createDate: `${data.createDate}Z` };
        }
      } catch (e) {
        console.log(e);
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchPhotos({ commit }, id) {
      try {
        commit("setLoading", true);
        const { data, status } = await axios.get(
          `/Reports/Photos?reportId=${id}`,
        );
        if (status) {
          const { lists } = data;
          return lists;
        }
      } catch (e) {
        console.log(e);
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchSlides({ commit }, id) {
      try {
        commit("setLoading", true);
        const { data, status } = await axios.get(
          `/Reports/Pages?reportId=${id}`,
        );
        if (status) {
          const { lists } = data;
          return lists.map(e => ({ ...e, processType: "update" }));
        }
      } catch (e) {
        console.log(e);
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async createReport({ commit }, payload) {
      try {
        commit("setLoading", true);
        const response = await axios.post("/Reports/Add", payload);
        console.log(response);
        const {
          status,
          data: {
            data: { id },
          },
        } = response;
        commit("setSuccessAlert", "Report created!");
        if (status === 200) {
          return id;
        }
      } catch (e) {
        console.log(e);
        const {
          response: { status },
        } = { ...e };
        if (status && status == 403) {
          commit("setErrorAlert", "Report already exists!");
        } else {
          commit("setErrorAlert", "Report creation failed!");
        }
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async addPhoto({ commit }, payload) {
      try {
        console.log("Uploading image");
        const { reportId, url } = payload;
        let imageCleaned = url;
        if (imageCleaned.substring(0, 4) === "data") {
          imageCleaned = imageCleaned.split(",")[1];
        }
        const response = await axios.post("/Reports/AddReportPhoto", {
          reportId,
          image: imageCleaned,
        });
        const {
          status,
          data: {
            data: { id, imageUrl },
          },
        } = response;
        if (status === 200) {
          console.log("Image uploaded");
          return { id, imageUrl };
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Photo upload failed!");
        return false;
      }
    },
    async saveSlides({ commit }, { reportId, slides }) {
      try {
        commit("setLoading", true);
        console.log(slides);
        const { data, status } = await axios.post("/Reports/PagesUpdate", {
          reportId,
          pages: slides,
        });
        console.log(data);
        console.log(status);
        if (status === 200) {
          return data.data
            .filter(e => e.processType != "delete")
            .map(e => ({ ...e, processType: "update" }));
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", `Report save failed! ${status}`);
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async deletePhoto({ commit }, id) {
      try {
        commit("setLoading", true);
        const response = await axios.delete(`/Reports/DeleteReportPhoto/${id}`);
        const { status } = response;
        console.log(response);
        if (status === 200) {
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Photo deletion failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async editReport({ commit }, { id, ...rest }) {
      try {
        commit("setLoading", true);
        console.log(rest);
        const response = await axios.put(`/Reports/Update/${id}`, {
          ...rest,
        });
        console.log(response);
        const { status, data } = response;
        if (rest.status && rest.status === "Archived") {
          commit("setSuccessAlert", "PDF was generated!");
        }
        if (Object.keys(rest).length > 1 || rest.isFree == undefined) {
          commit("setSuccessAlert", "Report edited!");
        }
        if (status === 200) {
          return data.data;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", `Report edit failed! ${status}`);
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async deleteReport({ commit, dispatch }, id) {
      try {
        commit("setLoading", true);
        const response = await axios.delete(`/Reports/Delete/${id}`);
        const { status } = response;
        commit("setSuccessAlert", "Report deleted!");
        if (status === 200) {
          dispatch("fetchReports");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Report deletion failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchPendingReview({ getters, commit }) {
      if (!getters.hasReviewAccess) {
        return;
      }
      try {
        commit("setLoading", true);
        const parseStatus = status => {
          if (status === "Created") return "Created";
          if (status === "Submitted") return "Submitted";
          if (status === "Reviewer1") return "Review 1";
          if (status === "Reviewer2") return "Review 2";
          if (status === "Reviewer3") return "Completed";
          if (status === "Completed") return "Completed";
          if (status === "Archived") return "Archived";
        };
        const { data } = await axios.get("/reportsPendingYourReview?top=999");
        if (data && data.reports) {
          commit(
            "setPendingReview",
            data.reports.map(e => ({
              ...e,
              parsedStatus: parseStatus(e.status),
            })),
          );
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchRecentReports({ commit }) {
      try {
        commit("setLoading", true);
        const parseStatus = status => {
          if (status === "Created") return "Created";
          if (status === "Submitted") return "Submitted";
          if (status === "Reviewer1") return "Review 1";
          if (status === "Reviewer2") return "Review 2";
          if (status === "Reviewer3") return "Completed";
          if (status === "Completed") return "Completed";
          if (status === "Archived") return "Archived";
        };
        const { data } = await axios.get("/reportsRecent");
        if (data && data.reports) {
          commit(
            "setRecentReports",

            data.reports.map(e => ({
              ...e,
              parsedStatus: parseStatus(e.status),
            })),
          );
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchSuggestedPhrases({ commit }, payload = {}) {
      const { silent } = payload;
      try {
        if (!silent) {
          commit("setLoading", true);
        }
        const {
          data: { lists },
        } = await axios.get("/Settings/Suggesteds");
        if (lists) {
          commit("setSuggestedPhrases", lists);
        }
      } catch (e) {
        console.log(e);
      } finally {
        if (!silent) {
          commit("setLoading", false);
        }
      }
    },
    async fetchSyncServerInfo({ commit }) {
      try {
        const { data } = await axios.get("/Settings/ServerInfo");
        commit("setServerInfo", data);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchActivityLog({ commit }) {
      try {
        const {
          data: {
            data: { lists },
          },
        } = await axios.get("/Settings/ActivityLog");
        if (lists) {
          commit("setActivityLog", lists);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async createPhrase({ commit, dispatch }, { categoryId, description }) {
      try {
        commit("setLoading", true);
        const response = await axios.post("/Settings/AddSuggested", {
          categoryId: +categoryId,
          description,
        });
        console.log(response);
        const { status } = response;
        commit("setSuccessAlert", "Phrase created!");
        if (status === 200) {
          await dispatch("fetchSuggestedPhrases");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Phrase creation failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async editPhrase({ commit, dispatch }, { phrase, id, silent, noFetch }) {
      try {
        if (!silent) {
          commit("setLoading", true);
        }
        const response = await axios.put(
          `/Settings/UpdateSuggested/${id}`,
          phrase,
        );
        const { status } = response;
        commit("setSuccessAlert", "Phrase edited!");
        if (status === 200 && !noFetch) {
          dispatch("fetchSuggestedPhrases", { silent });
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Phrase edit failed!");
        return false;
      } finally {
        if (!silent) {
          commit("setLoading", false);
        }
      }
    },
    async deletePhrase({ commit, dispatch }, { id, orderedPhrases }) {
      try {
        commit("setLoading", true);
        const response = await axios.delete(`/Settings/DeleteSuggested/${id}`);
        const { status } = response;

        commit("setSuccessAlert", "Phrase deleted!");
        if (status === 200) {
          const allEdits = orderedPhrases
            .filter(e => e.id !== id)
            .map((e, index) => {
              return dispatch("editPhrase", {
                phrase: { row: index + 1 },
                id: e.id,
                silent: true,
              });
            });

          await Promise.all(allEdits);
          dispatch("fetchSuggestedPhrases");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Phrase deletion failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchEmailTemplate({ commit }) {
      try {
        commit("setLoading", true);
        const {
          data: {
            data: { value },
          },
        } = await axios.get("/Settings/GetTemplate/1");
        if (value) {
          commit("setEmailTemplate", value);
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async editEmailTemplate({ commit }, template) {
      try {
        commit("setLoading", true);
        const response = await axios.put(`/Settings/UpdateTemplate/`, {
          id: 1,
          value: template,
        });
        const { status } = response;
        commit("setSuccessAlert", "Email template edited!");
        if (status === 200) {
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Email template edit failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchUserInfo({ commit }) {
      try {
        const { data: user } = await axios.get("/me");
        commit("setUser", user);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchContactCategories({ commit }) {
      try {
        commit("setLoading", true);
        const { data } = await axios.get(
          "/Jobs/GetOutlookCategories/GetOutlookCategories",
        );
        commit("setContactCategories", data);
      } catch (e) {
        console.log(e);
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchContactsByJob(_, jobId) {
      try {
        const {
          data: { lists },
        } = await axios.get(
          `/Jobs/GetOutlookContactsByJobId/GetOutlookContactsByJobId?jobId=${jobId}`,
        );
        console.log(jobId);
        if (lists) {
          return lists;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async createUser(
      { commit, dispatch },
      { emailAddress, password, fullName, title, roleId },
    ) {
      try {
        commit("setLoading", true);
        const response = await axios.post("/Users/Add", {
          emailAddress,
          password,
          fullName,
          title,
          roleId,
        });
        const { status } = response;
        commit("setSuccessAlert", "User created!");
        if (status === 200) {
          await dispatch("fetchUsers");
          return true;
        }
      } catch (e) {
        console.log(e);
        const {
          response: { status },
        } = { ...e };
        if (status && status == 403) {
          commit("setErrorAlert", "User already exists!");
        } else {
          commit("setErrorAlert", "User creation failed!");
        }
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async editUser({ commit, dispatch }, { user, id }) {
      try {
        commit("setLoading", true);
        const response = await axios.put(`/Users/Update/${id}`, user);
        console.log(response);
        const { status } = response;
        commit("setSuccessAlert", "User edited!");
        if (status === 200) {
          dispatch("fetchUsers");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "User edit failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async deleteUser({ commit, dispatch }, id) {
      try {
        commit("setLoading", true);
        const response = await axios.delete(`/Users/Delete/${id}`);
        const { status } = response;
        commit("setSuccessAlert", "User deleted!");
        if (status === 200) {
          dispatch("fetchUsers");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "User deletion failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async createDepartment({ commit, dispatch }, { name, code, reviewers }) {
      try {
        commit("setLoading", true);
        const reviewersParsed = reviewers.map((e, i) => ({
          reviewerId: e.id,
          row: i,
        }));
        const newDepartment = {
          name,
          code,
          reviewers: reviewersParsed,
        };
        console.log(newDepartment);
        const response = await axios.post("/Departments/Add", newDepartment);
        console.log(response);
        const { status } = response;
        commit("setSuccessAlert", "Department created!");
        if (status === 200) {
          await dispatch("fetchDepartments");
          return true;
        }
      } catch (e) {
        console.log(e);
        const {
          response: { status },
        } = { ...e };
        if (status && status == 403) {
          commit("setErrorAlert", "Department already exists!");
        } else {
          commit("setErrorAlert", "Department creation failed!");
        }
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async editDepartment({ commit, dispatch }, { department, id }) {
      try {
        commit("setLoading", true);
        const newDepartment = {
          ...department,
          reviewers: department.reviewers.map((e, i) => ({
            reviewerId: e.reviewerId || e.id,
            row: i,
          })),
        };
        console.log(newDepartment);
        const response = await axios.put(
          `/Departments/Update/${id}`,
          newDepartment,
        );
        console.log(response);
        const { status } = response;
        commit("setSuccessAlert", "Department edited!");
        if (status === 200) {
          dispatch("fetchDepartments");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Department edit failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async deleteDepartment({ commit, dispatch }, id) {
      try {
        commit("setLoading", true);
        const response = await axios.delete(`/Departments/Delete/${id}`);
        const { status } = response;
        commit("setSuccessAlert", "Department deleted!");
        if (status === 200) {
          dispatch("fetchDepartments");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Department deletion failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async createCrew(
      { commit, dispatch },
      { code, description, departments, crewMembers },
    ) {
      try {
        console.log(crewMembers);
        commit("setLoading", true);
        const newCrew = {
          code,
          description,
          users: crewMembers.map(e => {
            return {
              userId: e.id,
              fullName: e.fullName,
            };
          }),
          departments: departments.map(e => {
            return {
              departmentId: e.id,
              departmentName: e.name,
            };
          }),
        };
        const response = await axios.post("/Crews/Add", newCrew);
        const { status } = response;
        commit("setSuccessAlert", "Crew created!");
        if (status === 200) {
          await dispatch("fetchCrews");
          return true;
        }
      } catch (e) {
        console.log(e);
        const {
          response: { status },
        } = { ...e };
        if (status && status == 403) {
          commit("setErrorAlert", "Crew already exists!");
        } else {
          commit("setErrorAlert", "Crew creation failed!");
        }
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async editCrew(
      { commit, dispatch },
      { crew: { code, description, departments, crewMembers }, id },
    ) {
      try {
        commit("setLoading", true);
        console.log("Edit Request:");
        console.log(
          JSON.stringify({
            code,
            description,
            users: crewMembers.map(e => {
              return {
                userId: e.id,
                fullName: e.fullName,
              };
            }),
            departments: departments.map(e => {
              return {
                departmentId: e.id,
                departmentName: e.name,
              };
            }),
          }),
        );
        const newCrew = {
          code,
          description,
          users: crewMembers.map(e => {
            return {
              userId: e.id,
              fullName: e.fullName,
            };
          }),
          departments: departments.map(e => {
            return {
              departmentId: e.id,
              departmentName: e.name,
            };
          }),
        };
        const response = await axios.put(`/Crews/Update/${id}`, newCrew);
        console.log(response);
        const { status } = response;
        commit("setSuccessAlert", "Crew edited!");
        if (status === 200) {
          dispatch("fetchCrews");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Crew edit failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async deleteCrew({ commit, dispatch }, id) {
      try {
        commit("setLoading", true);
        const response = await axios.delete(`/Crews/Delete/${id}`);
        const { status } = response;
        commit("setSuccessAlert", "Crew deleted!");
        if (status === 200) {
          dispatch("fetchCrews");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Crew deletion failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async createJob(
      { commit, dispatch },
      { name, imageUrl, crews, categories },
    ) {
      try {
        commit("setLoading", true);
        let imageCleaned = imageUrl;
        if (imageUrl.substring(0, 4) === "data") {
          imageCleaned = imageUrl.split(",")[1];
        }
        const newJob = {
          name,
          image: imageCleaned,
          crewIds: crews.map(e => e.id),
          categories: categories.map(e => ({
            categoryColor: e.color,
            categoryId: e.id,
            categoryName: e.displayName,
            jobDistributionId: 0,
          })),
        };
        const response = await axios.post("/Jobs/Add", newJob);
        const { status } = response;
        commit("setSuccessAlert", "Job created!");
        if (status === 200) {
          await dispatch("fetchJobs");
          return true;
        }
      } catch (e) {
        console.log(e);
        const {
          response: { status },
        } = { ...e };
        if (status && status == 403) {
          commit("setErrorAlert", "Job already exists!");
        } else {
          commit("setErrorAlert", "Job creation failed!");
        }
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async editJob(
      { commit, dispatch },
      { job: { name, imageUrl, crews, categories }, id },
    ) {
      try {
        commit("setLoading", true);
        let imageCleaned = null;

        if (imageUrl && imageUrl.substring(0, 4) === "data") {
          imageCleaned = imageUrl.split(",")[1];
        }

        if (!imageUrl) {
          imageCleaned = "-";
        }

        const newJob = {
          name,
          image: imageCleaned,
          crewIds: crews.map(e => e.id),
          categories: categories
            .filter(e => !!e)
            .map(e => ({
              categoryId: e.id,
              categoryName: e.displayName,
              categoryColor: e.color,
            })),
        };
        const response = await axios.put(`/Jobs/Update/${id}`, newJob);
        const { status } = response;
        commit("setSuccessAlert", "Job edited!");
        if (status === 200) {
          dispatch("fetchJobs");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Job edit failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async deleteJob({ commit, dispatch }, id) {
      try {
        commit("setLoading", true);
        const response = await axios.delete(`/Jobs/Delete/${id}`);
        const { status } = response;
        commit("setSuccessAlert", "Job deleted!");
        if (status === 200) {
          dispatch("fetchJobs");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Job deletion failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async changePassword({ commit }, { oldPassword, newPassword }) {
      try {
        commit("setLoading", true);
        const response = await axios.put(`/changePassword`, {
          oldPassword,
          newPassword,
        });
        const { status } = response;
        if (status === 200) {
          commit("setSuccessAlert", "Password Changed");
          return true;
        }
      } catch (e) {
        console.log(e);
        commit("setErrorAlert", "Password change failed!");
        return false;
      } finally {
        commit("setLoading", false);
      }
    },
    async lockReport(_, id) {
      axios.get(`/Reports/ReportLastChangeDateUpdate/${id}`);
    },
    async manualSynchronization({ commit, dispatch }) {
      commit("setLoading", true);
      await axios.post(`/Settings/ManuelSynchronization`);
      commit("setLoading", false);
      setTimeout(() => {
        dispatch("fetchSyncServerInfo");
      }, 3 * 1000);
      setTimeout(() => {
        dispatch("fetchActivityLog");
      }, 3 * 1000);
      setTimeout(() => {
        dispatch("fetchSyncServerInfo");
      }, 6 * 1000);
      setTimeout(() => {
        dispatch("fetchActivityLog");
      }, 6 * 1000);
    },
  },
  modules: {},
});
