<template>
  <v-container :fluid="isIpad" class="wrapper-container">
    <div class="">
      <h2 class="dashboard-welcome">Hello {{ user.fullName }},</h2>
      <p class="dashboard-welcome-2">Here is what's been happening</p>
    </div>
    <div
      v-if="hasReviewAccess"
      class="justify-space-between d-flex align-center"
    >
      <div class="horizontal-line left-line"></div>
      <h3 class="recent-reports-heading d-flex align-center">
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2"
        >
          <g opacity="0.6">
            <path
              d="M5.625 11.75C6.31536 11.75 6.875 11.1904 6.875 10.5C6.875 9.80964 6.31536 9.25 5.625 9.25C4.93464 9.25 4.375 9.80964 4.375 10.5C4.375 11.1904 4.93464 11.75 5.625 11.75Z"
              fill="black"
            />
            <path
              d="M14.375 11.75C15.0654 11.75 15.625 11.1904 15.625 10.5C15.625 9.80964 15.0654 9.25 14.375 9.25C13.6846 9.25 13.125 9.80964 13.125 10.5C13.125 11.1904 13.6846 11.75 14.375 11.75Z"
              fill="black"
            />
            <path
              d="M10 11.75C10.6904 11.75 11.25 11.1904 11.25 10.5C11.25 9.80964 10.6904 9.25 10 9.25C9.30964 9.25 8.75 9.80964 8.75 10.5C8.75 11.1904 9.30964 11.75 10 11.75Z"
              fill="black"
            />
            <path
              d="M10 19.25C8.26942 19.25 6.57769 18.7368 5.13876 17.7754C3.69983 16.8139 2.57832 15.4473 1.91606 13.8485C1.25379 12.2496 1.08051 10.4903 1.41813 8.79296C1.75575 7.09563 2.58911 5.53653 3.81282 4.31282C5.03653 3.08911 6.59563 2.25575 8.29296 1.91813C9.9903 1.58051 11.7496 1.75379 13.3485 2.41606C14.9473 3.07832 16.3139 4.19983 17.2754 5.63876C18.2368 7.07769 18.75 8.76942 18.75 10.5C18.7474 12.8198 17.8246 15.0439 16.1843 16.6843C14.5439 18.3246 12.3198 19.2474 10 19.25ZM10 3C8.51664 3 7.0666 3.43987 5.83323 4.26398C4.59986 5.08809 3.63856 6.25943 3.07091 7.62988C2.50325 9.00032 2.35473 10.5083 2.64411 11.9632C2.9335 13.418 3.64781 14.7544 4.6967 15.8033C5.7456 16.8522 7.08197 17.5665 8.53683 17.8559C9.99168 18.1453 11.4997 17.9968 12.8701 17.4291C14.2406 16.8614 15.4119 15.9001 16.236 14.6668C17.0601 13.4334 17.5 11.9834 17.5 10.5C17.4977 8.51159 16.7068 6.60528 15.3007 5.19926C13.8947 3.79324 11.9884 3.00232 10 3Z"
              fill="black"
            />
          </g>
        </svg>
        Pending Your Review ({{ pendingReview.length }})
      </h3>
      <div class="horizontal-line right-line"></div>
      <v-btn to="/reports" text class="primary--text">
        View All
      </v-btn>
    </div>
    <v-row v-if="user.roleId >= 2">
      <v-col v-if="pendingReview.length == 0 && !isLoading">
        <p class="pending-placeholder">
          You don’t have any pending items. You’re all caught up!
        </p>
      </v-col>
      <v-col
        class="d-flex flex-column"
        v-for="(pr, i) in pendingReview.slice(0, 4)"
        :key="i"
        cols="4"
        md="3"
        xl="2"
      >
        <div class="report-card" @click="handleReportClicked(pr.id)">
          <img
            :src="pr.resizedSmallImageUrl"
            class="report-image"
            alt="Report Image"
          />
          <div class="report-header">
            <h4>{{ pr.jobName }}</h4>
            <p>
              {{ pr.parsedStatus }} |
              <span class="time-passed"
                >{{ parseTimeDifference(pr.statusChangeDateTime) }} ago
                <span class="text-decoration-underline">{{
                  pr.userFullName
                }}</span></span
              >
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="justify-space-between d-flex align-center">
      <div class="horizontal-line left-line"></div>
      <h3 class="recent-reports-heading">Recent Reports</h3>
      <div class="horizontal-line right-line"></div>
      <v-btn to="/reports" text class="primary--text">
        View All
      </v-btn>
    </div>
    <v-row style="margin-bottom: 90px;">
      <v-col v-if="recentReports.length == 0 && !isLoading">
        <p class="pending-placeholder">
          You don’t have any recent reports. Click “New Report” to start
          creating your first report.
        </p>
      </v-col>
      <v-col
        class="d-flex flex-column"
        v-for="(report, i) in recentReports"
        :key="i"
        cols="4"
        md="3"
        xl="2"
      >
        <div class="report-card" @click="handleReportClicked(report.id)">
          <img
            :src="report.resizedSmallImageUrl"
            class="report-image"
            alt="Report Image"
          />
          <div class="report-header">
            <h4>{{ report.jobName }}</h4>
            <p>
              {{ report.parsedStatus }} |
              <span class="time-passed"
                >{{ parseTimeDifference(report.statusChangeDateTime) }} ago
                <span class="text-decoration-underline">{{
                  report.userFullName
                }}</span></span
              >
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Dashboard",
  mounted() {
    this.fetchPendingReview();
    this.fetchRecentReports();
  },
  computed: {
    ...mapGetters([
      "user",
      "hasReviewAccess",
      "pendingReview",
      "recentReports",
      "isLoading",
    ]),
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
  methods: {
    ...mapActions(["fetchPendingReview", "fetchRecentReports"]),
    handleReportClicked(id) {
      this.$router.push({
        name: "ReportDetail",
        params: { id: id.toString() },
      });
    },
    parseTimeDifference(timestamp) {
      const n = new Date().getTime();
      const submitted = new Date(timestamp + "Z").getTime();
      const diff = submitted - n;
      let msec = diff;
      let hh = Math.ceil(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm = Math.ceil(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      const dd = Math.ceil(hh / 24);
      if (dd < 0) {
        return `${dd * -1} days`;
      }
      if (hh < 0) {
        return `${hh * -1} hours`;
      }
      if (mm < 0) {
        return `${mm * -1} minutes`;
      }
      return `${mm} minutes`;
    },
  },
};
</script>
<style scoped lang="scss">
.report-card {
  border: 1px solid #e5e5e5;
  cursor: pointer;
}
.horizontal-line {
  background-color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  height: 1px;
}

.left-line {
  margin-right: 24px;
}

.right-line {
  margin-left: 24px;
  margin-right: 6px;
}

.dashboard-welcome {
  font-weight: normal;
  font-size: 42px;
  line-height: 49px;
  color: rgba(0, 0, 0, 0.6);
}

.dashboard-welcome-2 {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.recent-reports-heading {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 1000px) {
  .report-image {
    height: 120px !important;
  }
}

.report-image {
  height: 160px;
  width: 100%;
}

.report-header {
  height: 60px;
  color: #000;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  h4 {
    font-weight: normal;
    font-size: 16px;
  }
  p {
    font-weight: normal;
    font-size: 12px;
  }
}
.pending-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  opacity: 0.6;
}
.time-passed {
  color: #b2b2b2;
}
</style>
