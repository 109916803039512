<template>
  <div>
    <DeleteConfirmation
      :open.sync="deleteModal"
      @confirm="handleConfirmDelete"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete Phrase"
      bodyText="This action will delete this phrase permanently"
    />
    <v-dialog @click:outside="handleCancel" v-model="addModal" width="540">
      <v-card>
        <v-overlay absolute :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular
        ></v-overlay>
        <v-card-title class="addModal-header">
          <span class="addModal-header-title text-capitalize"
            >{{ mode }} Phrase</span
          >
          <span>
            <v-btn
              text
              class="text-body-1 text-capitalize text-decoration-underline"
              @click="handleCancel"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="ml-3 text-body-1 text-capitalize"
              @click="handleSave"
            >
              Save
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text class="addModal-body">
          <v-form class="mx-0 my-0" ref="form">
            <div class="section-heading-wrapper">
              <span class="section-heading">
                Phrase Details
              </span>
            </div>
            <div class="form-section">
              <span class="modal-text-field-label">Category</span>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  dense
                  hide-details
                  placeholder="Start typing to find categories"
                  outlined
                  single-line
                  v-model="fields.category"
                  :items="categoriesParsed"
                  class="mb-3 reviewer-autocomplete"
                  :rules="[required]"
                >
                </v-autocomplete>
              </div>
              <span class="modal-text-field-label">Phrase</span>
              <v-textarea
                dense
                hide-details
                outlined
                single-line
                class="mb-3"
                v-model="fields.description"
                :rules="[required]"
              >
              </v-textarea>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TablePageHeader title="Setting" :buttons="false" />
    <v-container :fluid="isIpad" class="wrapper-container">
      <v-row>
        <v-col cols="3">
          <v-list class="settings-menu">
            <v-list-item
              :class="activeMenu == 0 ? 'settings-menu-active' : ''"
              class="px-6"
              @click="activeMenu = 0"
            >
              <v-list-item-content class="py-4">
                <v-list-item-title>Account Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :class="activeMenu == 1 ? 'settings-menu-active' : ''"
              class="px-6"
              @click="activeMenu = 1"
            >
              <v-list-item-content class="py-4">
                <v-list-item-title>Suggested Phrases</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :class="activeMenu == 2 ? 'settings-menu-active' : ''"
              class="px-6"
              @click="activeMenu = 2"
              v-if="user.roleId >= 2"
            >
              <v-list-item-content class="py-4">
                <v-list-item-title>Email Template</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :class="activeMenu == 3 ? 'settings-menu-active' : ''"
              class="px-6"
              @click="activeMenu = 3"
              v-if="user.roleId >= 4"
            >
              <v-list-item-content class="py-4">
                <v-list-item-title>Synchronization Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="9">
          <div v-show="activeMenu == 0" class="settings-right-wrapper">
            <div
              class="d-flex justify-space-between settings-menu-header-wrapper"
            >
              <h2>Account Settings</h2>
              <v-btn
                elevation="0"
                class="primary white--text text-body-1"
                style="text-transform: none;"
                @click="handleChangePassword"
                >Change Password</v-btn
              >
            </div>
            <div class="settings-menu-middle-text">
              <p>
                Edit your account settings such as your profile photo, email,
                and password.
              </p>
            </div>
            <div class="settings-menu-content">
              <span class="user-info">
                <span class="user-info-label">Name</span>
                <span>{{ user.fullName }}</span>
              </span>
              <span class="user-info">
                <span class="user-info-label">Role</span>
                <span>{{ user.roleName }}</span>
              </span>
              <span class="user-info">
                <span class="user-info-label">Title</span>
                <span>{{ user.title }}</span>
              </span>
              <span class="user-info">
                <span class="user-info-label">Email</span>
                <span>{{ user.emailAddress }}</span>
              </span>
              <v-form ref="pwForm">
                <span class="modal-text-field-label">Old Password</span>
                <v-text-field
                  dense
                  hide-details
                  placeholder="************"
                  outlined
                  single-line
                  class="mt-1 mb-3"
                  v-model="oldPassword"
                  type="password"
                  style="width: 300px;"
                  :rules="[required]"
                >
                </v-text-field>
                <span class="modal-text-field-label">New Password</span>
                <v-text-field
                  dense
                  hide-details
                  placeholder="************"
                  outlined
                  single-line
                  class="mt-1 mb-3"
                  v-model="newPassword"
                  type="password"
                  style="width: 300px;"
                  :rules="[required]"
                >
                </v-text-field>
              </v-form>
            </div>
          </div>
          <div v-show="activeMenu == 1" class="settings-right-wrapper">
            <div
              class="d-flex justify-space-between settings-menu-header-wrapper"
            >
              <h2>Suggested Phrases</h2>
              <div>
                <v-btn
                  elevation="0"
                  class="primary--text text-body-1 mr-2"
                  style="text-transform: none;"
                  @click="handleFixOrderNumbers"
                  v-if="user.roleId >= 2"
                >
                  Fix Order Numbers
                </v-btn>
                <v-btn
                  elevation="0"
                  class="primary white--text text-body-1"
                  style="text-transform: none;"
                  @click="handleNewPhrase"
                  v-if="user.roleId >= 2"
                >
                  Add Phrase
                </v-btn>
              </div>
            </div>
            <div class="settings-menu-middle-text">
              <p>
                Suggested phrases help your teammates on the field complete
                reports faster and with more accuracy. Here you can add, edit
                and remove these phrases.
              </p>
            </div>
            <div class="settings-menu-content">
              <v-tabs v-model="selectedSuggestedTab" class="mb-2">
                <v-tab v-for="tab in suggestedsTabOrder" :key="tab">
                  {{ tab }}
                </v-tab>
              </v-tabs>
              <transition-group name="phrase-list">
                <div
                  v-for="suggested in orderedSuggestedPharases"
                  :key="suggested.id"
                  class="suggested-phrases-item-wrapper"
                >
                  <div class="suggested-phrases-item">
                    <div class="position-actions">
                      <v-btn
                        elevation="0"
                        class="up-down-button"
                        @click="handleMoveSuggested(suggested, 'increase')"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                      <input
                        :ref="'suggested-row-input-' + suggested.row"
                        class="position-display"
                        :value="suggested.row"
                        @change="handleMoveSuggested(suggested, $event)"
                      />
                      <v-btn
                        elevation="0"
                        class="up-down-button"
                        @click="handleMoveSuggested(suggested, 'decrease')"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>
                    </div>
                    <p>{{ suggested.description }}</p>
                    <div v-if="user.roleId >= 2" style="min-width: 100px;">
                      <v-icon
                        class="mr-6"
                        @click="handleEdit(suggested)"
                        color="black"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon @click="handleDelete(suggested)" color="black">
                        mdi-delete
                      </v-icon>
                    </div>
                  </div>
                </div>
              </transition-group>
            </div>
          </div>
          <div
            v-show="activeMenu == 2"
            class="settings-right-wrapper"
            v-if="user.roleId >= 2"
          >
            <div
              class="d-flex justify-space-between settings-menu-header-wrapper"
            >
              <h2>Email Template</h2>
              <div v-if="user.roleId >= 4">
                <v-btn
                  elevation="0"
                  class="cancel-button text-body-1"
                  style="text-transform: none;"
                  outlined
                  @click="handleEmailTemplateCancel"
                  >Cancel</v-btn
                >
                <v-btn
                  elevation="0"
                  class="primary white--text text-body-1"
                  style="text-transform: none;"
                  @click="handleEmailTemplateSave"
                  >Save Settings</v-btn
                >
              </div>
            </div>
            <div class="settings-menu-middle-text">
              <p>
                This is the standard email template that’s used when you
                distribute reports. You can make changes to the template below
                that will be reflected for future email distribution.
              </p>
            </div>
            <div class="settings-menu-content">
              <span class="modal-text-field-label">Email template</span>
              <v-textarea
                dense
                hide-details
                outlined
                no-resize
                class="mt-1 mb-3"
                v-model="emailTemplate"
              >
              </v-textarea>
            </div>
          </div>
          <div
            v-show="activeMenu == 3"
            class="settings-right-wrapper"
            v-if="user.roleId >= 4"
          >
            <div
              class="d-flex justify-space-between settings-menu-header-wrapper"
            >
              <h2>Synchronization Settings</h2>
              <div>
                <v-btn elevation="0" class="mr-2" @click="refreshSyncStatus">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-btn
                  elevation="0"
                  class="primary white--text text-body-1"
                  style="text-transform: none;"
                  @click="manualSynchronization"
                >
                  Manual Synchronization
                </v-btn>
              </div>
            </div>
            <div class="settings-menu-middle-text">
              <p>
                Synchronzation details are listed below. To make any
                modification please contact your administrator.
              </p>
            </div>
            <div class="settings-menu-content">
              <div class="sync-menu-server-container">
                <h3 class="sync-menu-header">Server Info</h3>
                <div>
                  <div class="server-info-line-wrapper">
                    <p class="left-text">Sync Status</p>
                    <span>{{ serverInfo.syncStatus }}</span>
                    <button type="button" class="force-stop-button">
                      Force Stop
                    </button>
                  </div>
                  <div class="server-info-line-wrapper">
                    <p class="left-text">IP / URL</p>
                    <span>{{ serverInfo.ip }}</span>
                  </div>
                  <div class="server-info-line-wrapper">
                    <p class="left-text">Directory</p>
                    <span>{{ serverInfo.directory }}</span>
                  </div>
                  <div class="server-info-line-wrapper">
                    <p class="left-text">Scheduled</p>
                    <span>{{ serverInfo.scheduledTime }}</span>
                  </div>
                </div>
              </div>
              <div class="sync-menu-log-container">
                <h3 class="sync-menu-header">Activity Log</h3>
                <div class="activity-log">
                  <div
                    class="log-row-container"
                    v-for="(log, index) in activityLog"
                    :key="index"
                  >
                    <div class="log-info-line">
                      <p class="log-state">{{ log.state }}</p>
                      <p class="log-method">{{ log.synchronizationMethod }}</p>
                      <p>
                        Synchronization ({{ log.succesedSynchronizationNumber }}
                        /
                        {{
                          log.succesedSynchronizationNumber +
                            log.failedSynchronizationNumber
                        }})
                      </p>
                    </div>
                    <p>{{ formatActivityLogDatetime(log.datetime) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DeleteConfirmation from "@/components/DeleteConfirmation";
import TablePageHeader from "@/components/TablePageHeader";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Settings",
  async created() {
    this.fetchSuggestedPhrases();
    this.fetchSyncServerInfo();
    this.fetchActivityLog();
    await this.fetchEmailTemplate();
    this.emailTemplate = this.$store.getters.emailTemplate;
  },
  components: {
    TablePageHeader,
    DeleteConfirmation,
  },
  data() {
    return {
      required: v => !!v || "Required!",
      addModal: false,
      deleteModal: false,
      mode: "add",
      fields: {
        category: "",
        description: "",
        id: "",
      },
      categories: {
        1: "LR-I",
        2: "BW",
        3: "LR-M",
        4: "Site Visit",
      },
      oldPassword: "",
      newPassword: "",
      emailTemplate: "",
      activeMenu: 0,
      phraseToDelete: {},
      selectedSuggestedTab: 0,
      suggestedsTabOrder: ["BW", "LR-I", "LR-M", "Site Visit"],
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "isLoading",
      "suggestedPhrases",
      "serverInfo",
      "activityLog",
    ]),
    categoriesParsed() {
      return Object.entries(this.categories).map(c => ({
        text: c[1],
        value: c[0],
      }));
    },
    isEdit() {
      return this.mode === "edit";
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
    suggestedPhrasesWithCategories() {
      const suggestedPhrasesWithCategories = {};
      this.suggestedPhrases.forEach(e => {
        const categoryName = this.categories[e.categoryId];
        if (suggestedPhrasesWithCategories[categoryName]) {
          suggestedPhrasesWithCategories[categoryName].push(e);
        } else {
          suggestedPhrasesWithCategories[categoryName] = [e];
        }
      });
      return suggestedPhrasesWithCategories;
    },
    selectedSuggestedCategory() {
      return this.suggestedsTabOrder[this.selectedSuggestedTab];
    },
    orderedSuggestedPharases() {
      const {
        selectedSuggestedCategory,
        suggestedPhrasesWithCategories,
      } = this;
      let ordered = [];

      if (
        selectedSuggestedCategory &&
        suggestedPhrasesWithCategories &&
        Object.keys(suggestedPhrasesWithCategories).length &&
        suggestedPhrasesWithCategories[selectedSuggestedCategory]
      ) {
        ordered = [
          ...suggestedPhrasesWithCategories[selectedSuggestedCategory],
        ];
        ordered.sort((a, b) => (a.row > b.row ? 1 : -1));
      } else {
        return [];
      }

      return ordered;
    },
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "fetchSuggestedPhrases",
      "fetchEmailTemplate",
      "fetchSyncServerInfo",
      "fetchActivityLog",
      "createPhrase",
      "editPhrase",
      "editEmailTemplate",
      "deletePhrase",
      "changePassword",
      "manualSynchronization",
    ]),
    ...mapMutations(["setLoading"]),
    handleEmailTemplateCancel() {
      this.emailTemplate = this.$store.getters.emailTemplate;
    },
    async handleEmailTemplateSave() {
      const { emailTemplate } = this;
      this.editEmailTemplate(emailTemplate);
    },
    handleChangePassword() {
      if (!this.$refs.pwForm.validate()) return;
      const { oldPassword, newPassword } = this;
      this.changePassword({
        oldPassword,
        newPassword,
      });
    },
    handleEdit(phrase) {
      const { categoryId, description, id } = phrase;
      this.addModal = true;
      this.mode = "edit";
      this.fields = {
        category: categoryId.toString(),
        description,
        id,
      };
    },
    handleDelete(phrase) {
      this.deleteModal = true;
      this.phraseToDelete = phrase;
    },
    async handleConfirmDelete() {
      const phraseDeleted = this.deletePhrase({
        id: this.phraseToDelete.id,
        orderedPhrases: this.orderedSuggestedPharases,
      });
      if (phraseDeleted) {
        this.phraseToDelete = {};
        this.deleteModal = false;
      }
    },
    async handleSave() {
      if (!this.$refs.form.validate()) return;
      const { mode, fields } = this;
      const { category, description, id } = fields;
      if (mode === "add") {
        const phraseCreated = await this.createPhrase({
          categoryId: category,
          description,
        });
        if (phraseCreated) {
          this.addModal = false;
          this.resetForms();
        }
      } else if (mode === "edit") {
        const phrase = { categoryId: +category, description };
        const phraseEdited = await this.editPhrase({ phrase, id });
        if (phraseEdited) {
          this.addModal = false;
          this.resetForms();
        }
      }
    },
    resetForms() {
      this.$refs.form.resetValidation();
      this.mode = "add";
      this.fields = {
        category: "",
        description: "",
        id: "",
      };
    },
    handleNewPhrase() {
      this.addModal = true;
      this.resetForms();
    },
    handleCancel() {
      this.addModal = false;
      this.resetForms();
    },
    formatActivityLogDatetime(datetime) {
      try {
        return new Date(datetime + "Z").toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      } catch (e) {
        //pass
      }
      return "";
    },
    async handleMoveSuggested(suggested, action) {
      const isSingleMove = action.constructor === String;
      const { row } = suggested;
      const newSuggested = { ...suggested };
      let nextPosition;

      if (isSingleMove) {
        nextPosition = action === "increase" ? row + 1 : row - 1;
      } else {
        nextPosition = +action.target.value;
      }

      if (
        nextPosition <= 0 ||
        nextPosition > this.orderedSuggestedPharases.length
      ) {
        this.$refs[`suggested-row-input-${row}`][0].value = row;
        return;
      }

      if (isSingleMove) {
        const currentPosition = row;
        const swapSuggestion = this.orderedSuggestedPharases[nextPosition - 1];
        this.editPhrase({
          phrase: { row: nextPosition },
          id: newSuggested.id,
          silent: true,
        });
        this.editPhrase({
          phrase: { row: currentPosition },
          id: swapSuggestion.id,
          silent: true,
        });
      } else {
        const firstIndex = row - 1;
        const secondIndex = nextPosition - 1;
        const smallIndex = Math.min(firstIndex, secondIndex);
        const bigIndex = Math.max(firstIndex, secondIndex);
        const upwardsMove = firstIndex < secondIndex;
        const promises = [];
        let changeArray;

        this.setLoading(true);

        if (upwardsMove) {
          changeArray = this.orderedSuggestedPharases.slice(
            smallIndex + 1,
            bigIndex + 1,
          );
          changeArray.forEach(phrase => {
            promises.push(
              this.editPhrase({
                phrase: { row: phrase.row - 1 },
                id: phrase.id,
                silent: true,
                noFetch: true,
              }),
            );
          });
        } else {
          changeArray = this.orderedSuggestedPharases.slice(
            smallIndex,
            bigIndex,
          );
          changeArray.forEach(phrase => {
            promises.push(
              this.editPhrase({
                phrase: { row: phrase.row + 1 },
                id: phrase.id,
                silent: true,
                noFetch: true,
              }),
            );
          });
        }

        promises.push(
          this.editPhrase({
            phrase: { row: nextPosition },
            id: newSuggested.id,
            silent: true,
          }),
        );

        await Promise.all(promises);
        this.setLoading(false);
      }
    },
    refreshSyncStatus() {
      this.fetchSyncServerInfo();
      this.fetchActivityLog();
    },
    async handleFixOrderNumbers() {
      this.setLoading(true);

      const allEdits = this.orderedSuggestedPharases.map((e, index) => {
        return this.editPhrase({
          phrase: { row: index + 1 },
          id: e.id,
          silent: true,
        });
      });

      await Promise.all(allEdits);

      this.setLoading(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-menu {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  ::v-deep .v-list-item {
    border-radius: 5px;
  }
  ::v-deep .v-list-item:not(:last-child) {
    border-bottom: 1px solid #c4c4c4;
  }
}
.settings-menu-active {
  background-color: #f4f4f4;
}
.settings-right-wrapper {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  .settings-menu-header-wrapper {
    background-color: #f4f4f4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 20px 32px;
    border-bottom: 1px solid #c4c4c4;
    h2 {
      font-weight: normal;
      font-size: 28px;
      line-height: 33px;
    }
    .cancel-button {
      background-color: transparent;
      border: 1px solid #7fa231;
      color: #7fa231;
      margin-right: 8px;
    }
  }
  .settings-menu-middle-text {
    padding: 16px 32px;
    border-bottom: 1px solid #c4c4c4;
    p {
      margin-bottom: 0;
    }
  }
  .settings-menu-content {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    .user-info {
      display: flex;
      margin-bottom: 14px;
      .user-info-label {
        display: block;
        width: 45px;
        color: rgba(0, 0, 0, 0.6);
        margin-right: 20px;
      }
    }

    .sync-menu-header {
      font-weight: 500;
      line-height: 24px;
      font-size: 16px;
      width: 150px;
    }

    .sync-menu-server-container {
      margin-bottom: 20px;
      display: flex;

      .left-text {
        width: 110px;
      }

      .server-info-line-wrapper {
        display: flex;
      }

      .force-stop-button {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #7fa231;
        height: 20px;
        margin-top: 2px;
        margin-left: 9px;
      }
    }

    .sync-menu-log-container {
      display: flex;
      .activity-log {
        width: 585px;
        height: 300px;
        background: #f4f4f4;
        border: 1px solid #c4c4c4;
        overflow-y: scroll;
        padding: 16px 24px;

        .log-row-container {
          margin-bottom: 24px;

          p {
            margin-bottom: 0px;
          }

          .log-info-line {
            display: flex;
            margin-bottom: 8px;

            .log-state {
              font-weight: 700;
              margin-right: 4px;
            }

            .log-method {
              text-decoration: underline;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}
.settings-menu-content {
  .suggested-phrases-item-wrapper {
    padding: 12px 0px;
    border-bottom: #dcdcdc 1px solid;
    .suggested-phrases-item {
      display: flex;
      align-items: center;
      p {
        margin: 0 32px;
        width: 100%;
      }
      .position-actions {
        border-radius: 2px;
        border: #c4c4c4 1px solid;
        display: flex;
        padding: 0;
        border-sizing: border-box;
        height: 37px;
        .up-down-button {
          height: 35px !important;
          width: 40px;
          min-width: 40px;
        }
        .position-display {
          height: 35px;
          width: 55px;
          text-align: center;
          font-size: 16px;
          margin-right: 2px;
          margin-left: 2px;
          outline-color: #7fa231;
        }
      }
    }
  }
}
.phrase-list-move {
  transition: transform 300ms;
  transition-timing-function: ease-out;
}
</style>
