<template>
  <v-container :fluid="isIpad" class="wrapper-container">
    <div class="mb-5 d-flex">
      <span
        v-for="(filter, i) in Object.keys(filters)"
        :key="i"
        @click="$emit('filter', filter)"
        class="filter-chip"
        :class="filters[filter].length > 0 ? 'filter-chip-active' : ''"
      >
        <span class="filter-chip-text">
          {{ filter }}
          {{ filters[filter].length > 0 ? `(${filters[filter].length})` : "" }}
        </span>
      </span>
      <v-text-field
        :label="searchPlaceholder"
        outlined
        hide-details
        dense
        single-line
        background-color="white"
        color="grey lighten-1"
        prepend-inner-icon="mdi-magnify"
        class="ml-1 search-input"
        v-model="debouncedSearch"
      ></v-text-field>
    </div>
    <v-data-table
      must-sort
      :headers="headers"
      :items="items"
      hide-default-footer
      class="data-table-customization"
      :server-items-length="items.length"
      :options.sync="options"
      @update:sort-desc="handleSortDescChange"
      @update:sort-by="handleSortByChange"
      :show-select="select"
      v-model="selected"
    >
      <template v-if="nameWithImage" v-slot:item.jobName="{ item }">
        <div class="align-center d-flex">
          <img class="small-job-image" :src="item[imageKey]" alt="Job Image" />
          <span>
            {{ item.jobName }}
          </span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!item.isFree"
            class="ml-1"
          >
            <rect width="16" height="16" rx="8" fill="#FFC107" />
            <circle cx="4" cy="8" r="1" fill="white" />
            <circle cx="8" cy="8" r="1" fill="white" />
            <circle cx="12" cy="8" r="1" fill="white" />
          </svg>
        </div>
      </template>
      <template v-if="nameWithImage" v-slot:item.name="{ item }">
        <div class="align-center d-flex">
          <img class="small-job-image" :src="item[imageKey]" alt="Job Image" />
          <span>
            {{ item.name }}
          </span>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="d-flex">
          <v-icon
            class="mr-4"
            @click="$emit('edit', item)"
            v-show="hasViewPermission(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            @click="$emit('delete', item)"
            v-show="hasDeletePermission(item)"
          >
            mdi-delete
          </v-icon>
        </span>
      </template>
    </v-data-table>
    <div class="justify-center d-flex align-center pagination-buttons">
      <span
        @click="$emit('prev')"
        class="pagination-button"
        :class="currentPage != 1 ? 'active' : ''"
      >
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.93741 11.29C7.03012 11.1975 7.10367 11.0876 7.15385 10.9666C7.20403 10.8457 7.22986 10.716 7.22986 10.585C7.22986 10.454 7.20403 10.3244 7.15385 10.2034C7.10367 10.0824 7.03012 9.97252 6.93741 9.88001L3.05742 6.00001L6.93741 2.12001C7.12439 1.93303 7.22943 1.67944 7.22943 1.41501C7.22943 1.15058 7.12439 0.896985 6.93741 0.710008C6.75044 0.52303 6.49684 0.417989 6.23241 0.417989C5.96799 0.417989 5.71439 0.52303 5.52741 0.710008L0.937414 5.30001C0.844711 5.39252 0.771163 5.50241 0.720982 5.62338C0.6708 5.74436 0.644971 5.87404 0.644971 6.00501C0.644971 6.13598 0.6708 6.26566 0.720982 6.38663C0.771163 6.50761 0.844711 6.6175 0.937414 6.71001L5.52741 11.3C5.90741 11.68 6.54741 11.68 6.93741 11.29Z"
            fill="black"
          />
        </svg>
        <span class="pg-text">Prev</span>
      </span>
      <span
        v-for="page in currentPages"
        :key="page"
        class="table-page-num"
        :class="page == currentPage ? 'table-page-num-active' : ''"
        @click="$emit('page-click', page)"
      >
        {{ page }}
      </span>
      <span
        @click="$emit('next')"
        class="pagination-button"
        :class="currentPage != totalPageCount ? 'active' : ''"
      >
        <span class="pg-text">Next</span>
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.557459 0.709992C0.464755 0.802505 0.391208 0.912394 0.341026 1.03337C0.290845 1.15434 0.265015 1.28402 0.265015 1.41499C0.265015 1.54596 0.290845 1.67564 0.341026 1.79662C0.391208 1.91759 0.464755 2.02748 0.557459 2.11999L4.43746 5.99999L0.557459 9.87999C0.370481 10.067 0.265438 10.3206 0.265438 10.585C0.265438 10.8494 0.370481 11.103 0.557459 11.29C0.744437 11.477 0.998033 11.582 1.26246 11.582C1.52689 11.582 1.78048 11.477 1.96746 11.29L6.55746 6.69999C6.65016 6.60748 6.72371 6.49759 6.77389 6.37662C6.82407 6.25564 6.8499 6.12596 6.8499 5.99499C6.8499 5.86402 6.82407 5.73434 6.77389 5.61337C6.72371 5.49239 6.65016 5.3825 6.55746 5.28999L1.96746 0.699991C1.58746 0.319991 0.947459 0.319992 0.557459 0.709992Z"
            fill="black"
          />
        </svg>
      </span>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Table",
  data() {
    return {
      selected: [],
      options: {},
      timeout: null,
      search: "",
    };
  },
  props: {
    selection: String,
    headers: Array,
    items: Array,
    filters: {
      type: Object,
      default: function() {
        return {};
      },
    },
    searchPlaceholder: String,
    imageKey: String,
    nameWithImage: {
      type: Boolean,
      default: false,
    },
    totalItemCount: Number,
    currentPage: Number,
    limitedActions: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selected(newSelected) {
      this.$emit("selectionChanged", newSelected);
    },
    items: {
      immediate: true,
      handler() {
        if (this.selection) {
          const index = this.items.findIndex(e => e.id == +this.selection);
          console.log(index);
          this.selected.push(this.items[index]);
        }
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
    totalPageCount() {
      return Math.ceil(this.totalItemCount / 10);
    },
    currentPages() {
      const { totalPageCount, currentPage } = this;
      const pages = [];
      for (let i = 1; i <= totalPageCount; i += 1) {
        pages.push(i.toString());
      }
      if (pages.length > 5) {
        if (currentPage > 3) {
          if (totalPageCount - currentPage > 2) {
            return pages.slice(currentPage - 3, currentPage + 2);
          } else {
            return pages.slice(
              currentPage - (5 - (totalPageCount - currentPage)),
              totalPageCount + 1,
            );
          }
        } else {
          return pages.slice(0, 5);
        }
      }
      return pages;
    },
    debouncedSearch: {
      get() {
        return this.search;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.search = val;
          this.$emit("search", val);
        }, 400);
      },
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
  methods: {
    hasViewPermission(report) {
      if (!this.limitedActions) return true;
      const { creatorUserId } = report;
      const { roleId, id } = this.user;
      if (roleId >= 2) return true;
      if (id == creatorUserId) return true;
      return false;
    },
    hasDeletePermission(report) {
      if (!this.limitedActions) return true;
      const { creatorUserId, status } = report;
      const { roleId, id } = this.user;
      if (status === "Archived") return false;
      if (roleId >= 2) return true;
      if (id == creatorUserId && status === "Created") return true;
      return false;
    },
    handleSortByChange(e) {
      this.$emit("sortBy", e[0]);
    },
    handleSortDescChange(e) {
      this.$emit("sortDesc", e[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-page-num {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  opacity: 0.6;
  pointer-events: auto;
  cursor: pointer;
}

.table-page-num-active {
  font-weight: bold !important;
  opacity: 1 !important;
  pointer-events: auto;
}
.pagination-buttons {
  margin: 20px auto 40px auto;
  font-weight: normal;
  font-size: 16px;
  pointer-events: none;
  span {
    margin: auto 10px auto 10px !important;
  }
  .pagination-button {
    opacity: 0.3;
    span {
      margin: auto 10px auto 10px !important;
    }
    &.active {
      opacity: 1 !important;
      pointer-events: auto;
      cursor: pointer;
    }
  }
}

.filter-chip-text {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: normal !important;
  color: white !important;
}
.filter-chip {
  height: 40px;
  background: #a3a3a3;
  border-radius: 2px;
  padding: 10px 16px;
  place-items: center;
  margin-right: 4px;
}
.filter-chip-active {
  background: #454545 !important;
}
.search-input {
  border-radius: 2px;
}
.small-job-image {
  width: 40px;
  margin-right: 12px;
}
</style>
