<template>
  <v-app style="background-color: #F4F4F4;">
    <v-main>
      <v-snackbar bottom left :color="alert.type" v-model="alert.visible">
        {{ alert.message }}
      </v-snackbar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  beforeCreate() {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token") || "";
    this.$store.commit("setToken", token);
  },
  computed: {
    ...mapGetters(["alert"]),
  },
};
</script>

<style lang="scss" src="@/styles/main.scss"></style>
