<template>
  <div>
    <v-navigation-drawer absolute temporary v-model="drawer" color="#353535">
      <v-divider class="top-divider"></v-divider>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item link dark to="/">
          <v-list-item-content>
            <v-list-item-title>
              DASHBOARD
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link dark to="/reports">
          <v-list-item-content>
            <v-list-item-title>
              REPORTS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item link dark to="/jobs" v-if="userRole >= 2">
          <v-list-item-content>
            <v-list-item-title>
              JOBS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link dark to="/crews" v-if="userRole >= 2">
          <v-list-item-content>
            <v-list-item-title>
              CREWS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link dark to="/departments" v-if="userRole >= 2">
          <v-list-item-content>
            <v-list-item-title>
              DEPARTMENTS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link dark to="/users" v-if="userRole >= 4">
          <v-list-item-content>
            <v-list-item-title>
              USERS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list nav class="nav-drawer-bottom">
        <v-list-item link dark to="/settings">
          <v-list-item-icon>
            <v-icon class="nav-drawer-icon">mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              SETTINGS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link dark @click="handleLogout">
          <v-list-item-icon>
            <v-icon class="nav-drawer-icon">mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              LOG OUT
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container fluid class="px-0 py-0 navigation-main">
      <v-container
        :fluid="isIpad"
        class="py-0 navbar d-flex justify-space-between align-center navigation-wrapper"
      >
        <div class="d-flex hamburger-activator">
          <span @click="drawer = true">
            <svg
              width="33"
              height="18"
              viewBox="0 0 33 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M1.35141 3.05371H30.9629C31.6272 3.05371 32.1651 2.50586 32.1651 1.80859C32.1651 1.11133 31.6272 0.546875 30.9629 0.546875H1.35141C0.687051 0.546875 0.165054 1.11133 0.165054 1.80859C0.165054 2.50586 0.687051 3.05371 1.35141 3.05371ZM1.35141 10.292H30.9629C31.6272 10.292 32.1651 9.74414 32.1651 9.04688C32.1651 8.33301 31.6272 7.76855 30.9629 7.76855H1.35141C0.687051 7.76855 0.165054 8.33301 0.165054 9.04688C0.165054 9.74414 0.687051 10.292 1.35141 10.292ZM1.35141 17.5469H30.9629C31.6272 17.5469 32.1651 16.9824 32.1651 16.2686C32.1651 15.5713 31.6272 15.0234 30.9629 15.0234H1.35141C0.687051 15.0234 0.165054 15.5713 0.165054 16.2686C0.165054 16.9824 0.687051 17.5469 1.35141 17.5469Z"
                  fill="white"
                  fill-opacity="0.8"
                />
              </g>
            </svg>
          </span>
        </div>
        <div class="d-none align-center navbar-left">
          <v-btn
            elevation="0"
            to="/"
            exact-active-class="router-link-exact-active"
            class="ml-0 router-link text-body-1"
          >
            DASHBOARD
          </v-btn>
          <v-btn
            elevation="0"
            exact-active-class="router-link-exact-active"
            to="/reports"
            class="router-link text-body-1"
          >
            REPORTS
          </v-btn>
          <div class="navbar-divider" v-if="userRole >= 2"></div>
          <v-btn
            v-if="userRole >= 4"
            elevation="0"
            exact-active-class="router-link-exact-active"
            to="/users"
            class="router-link text-body-1"
          >
            USERS
          </v-btn>
          <v-btn
            v-if="userRole >= 2"
            elevation="0"
            exact-active-class="router-link-exact-active"
            to="/departments"
            class="router-link text-body-1"
          >
            DEPARTMENTS
          </v-btn>
          <v-btn
            v-if="userRole >= 2"
            elevation="0"
            exact-active-class="router-link-exact-active"
            to="/crews"
            class="router-link text-body-1"
          >
            CREWS
          </v-btn>
          <v-btn
            v-if="userRole >= 2"
            elevation="0"
            exact-active-class="router-link-exact-active"
            to="/jobs"
            class="router-link text-body-1"
          >
            JOBS
          </v-btn>
        </div>
        <div class="d-flex align-center">
          <v-btn
            class="px-5 mr-3 d-none d-sm-block primary new-report-button white--text"
            @click="$router.push('/create-report')"
          >
            NEW REPORT
          </v-btn>
          <v-btn
            to="/settings"
            small
            icon
            class="mt-1 ml-3 mr-3 d-none d-sm-block"
          >
            <v-icon class="nav-drawer-icon">mdi-cog-outline</v-icon>
          </v-btn>
          <v-menu offset-y left nudge-bottom="10">
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-3 profile-circle" v-on="on" v-bind="attrs">
                {{ initials }}
              </div>
            </template>
            <v-list dense>
              <v-list-item class="logout-button" @click="handleLogout">
                <v-list-item-title>
                  Logout
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.user.roleId;
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
    initials() {
      if (!this.$store.getters.user || !this.$store.getters.user.fullName)
        return "";
      const name = this.$store.getters.user.fullName;
      const nameSplit = name.split(" ");
      const firstName = nameSplit[0];
      const lastName = nameSplit[nameSplit.length - 1];
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    },
  },
  methods: {
    handleLogout() {
      localStorage.removeItem("token");
      localStorage.removeItem("expiration");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("expiration");
      this.$store.commit("setUser", {});
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.logout-button {
  cursor: pointer;
}
::v-deep .v-divider {
  background-color: #5f5f5f !important;
}
//::v-deep .v-list {
//padding-left: 41px;
//padding-top: 12px;
//}

::v-deep .v-list-item__icon {
  margin-right: 8px !important;
}

.nav-drawer-icon {
  color: rgba(255, 255, 255, 0.8) !important;
}

.nav-drawer-bottom {
  position: absolute;
  bottom: 40px;
  width: 100%;
}
::v-deep .v-list-item--active {
  color: rgba(255, 255, 255, 0.8) !important;
}
.router-link {
  border-radius: 0px !important;
}
.router-link {
  color: rgba(255, 255, 255, 0.8) !important;
  height: 100% !important;
  text-decoration: none;
  display: flex;
  place-items: center;
  margin-right: 8px;
  margin-left: 8px;
  background-color: #353535 !important;
  margin-bottom: 2px !important;
  &:hover {
    border-bottom: 2px solid rgba(255, 255, 255, 0.8) !important;
    margin-bottom: 0px !important;
  }
}
.top-divider {
  margin-top: 96px;
}
.navigation-wrapper {
  padding-left: 65px !important;
  padding-right: 65px !important;
}
.navbar {
  height: 72px;
}
@media only screen and (max-width: 1024px) {
  .navigation-wrapper {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .navbar {
    height: 96px;
  }
}

@media only screen and (min-width: 1024px) {
  ::v-deep .v-btn:before {
    margin: 9px 4px !important;
    border-radius: 2px;
  }
}
::v-deep .router-link-exact-active.v-btn:before {
  background-color: #353535 !important;
}

.navigation-main {
  background-color: #353535;
  .router-link-exact-active {
    border-radius: 0px !important;
    border-bottom: 2px solid #cdff5e !important;
    padding-top: 2px;
    margin-bottom: 2px !important;
    color: #cdff5e !important;
    background-color: #353535 !important;
  }
}

.navbar-divider {
  display: inline-block;
  height: 24px;
  width: 1px;
  background-color: #dddddd;
  align-self: center;
}

.navbar-left {
  color: rgba(0, 0, 0, 0.6);
  align-self: stretch;
}

.profile-circle {
  border-radius: 32px;
  background-color: #ffffff;
  width: 32px;
  font-size: 12px;
  height: 32px;
  color: #353535;
  line-height: 32px;
  text-align: center;
}

.new-report-button {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
}

@media only screen and (min-width: 1100px) {
  .navbar-left {
    display: flex !important;
  }
  .hamburger-activator {
    display: none !important;
  }
}
</style>
