<template>
  <div>
    <DeleteConfirmation
      :open.sync="deleteModal"
      @confirm="handleConfirmDelete"
      cancelText="No, keep"
      confirmText="Yes, delete"
      questionText="Delete Report"
      bodyText="This action will delete this report permanently"
    />
    <v-navigation-drawer
      left
      absolute
      width="375"
      temporary
      v-model="filterDrawer"
      class="filter-drawer"
    >
      <div @click="filterDrawer = false" class="justify-end d-flex mb-14">
        <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-expansion-panels
        class="filter-expansion-panel"
        v-model="filterPanels"
        flat
        accordion
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Department {{ filterSelectionCount("department") }}</span>
              <p
                v-if="filterSelectionElements('department', 'name')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("department", "name") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-checkbox-wrapper">
            <v-checkbox
              v-for="(d, dIndex) in departments"
              :key="dIndex"
              :value="d"
              :label="d.name"
              class="text-body-1 filter-checkbox-reverse"
              :class="{ 'mt-0': dIndex == 0, 'mt-6': dIndex != 0 }"
              v-model="filters.department"
              hide-details
            >
              <template #label>
                <span style="color: black;">{{ d.name }}</span>
              </template>
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Status {{ filterSelectionCount("status") }}</span>
              <p
                v-if="filterSelectionElements('status', 'text')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("status", "text") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-checkbox-wrapper">
            <v-checkbox
              v-for="(d, dIndex) in statuses"
              :key="dIndex"
              :value="d"
              :label="d.text"
              class="text-body-1 filter-checkbox-reverse"
              :class="{ 'mt-0': dIndex == 0, 'mt-6': dIndex != 0 }"
              v-model="filters.status"
              hide-details
            >
              <template #label>
                <span style="color: black;">{{ d.text }}</span>
              </template>
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Crews {{ filterSelectionCount("crews") }}</span>
              <p
                v-if="filterSelectionElements('crews', 'code')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("crews", "code") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-autocomplete
              placeholder="Search to find title"
              outlined
              dense
              hide-details
              multiple
              :items="parsedCrews"
              v-model="filters.crews"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex flex-column">
              <span>Date {{ filterSelectionCount("date") }}</span>
              <p
                v-if="filterSelectionElements('date', '')"
                class="filter-selection-elements"
              >
                {{ filterSelectionElements("date", "") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex justify-space-between">
              <v-menu
                v-model="dateMenu"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    dense
                    hide-details
                    outlined
                    single-line
                    label=""
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker range v-model="filters.date" no-title scrollable>
                </v-date-picker>
              </v-menu>
              <v-btn style="height: 40px;" text @click="filters.date = []"
                >Reset</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
    <TablePageHeader title="Report" @new="$router.push('/create-report')">
      <template #extras>
        <div>
          <v-btn text class="white--text extra-button" @click="handleSend">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M21.5999 2.39999H4.7999C3.4763 2.39999 2.3999 3.47639 2.3999 4.79999V10.8H4.7999V7.19999L12.4799 12.96C12.6877 13.1156 12.9403 13.1997 13.1999 13.1997C13.4595 13.1997 13.7121 13.1156 13.9199 12.96L21.5999 7.19999V18H11.9999V20.4H21.5999C22.9235 20.4 23.9999 19.3236 23.9999 18V4.79999C23.9999 3.47639 22.9235 2.39999 21.5999 2.39999ZM13.1999 10.5L5.5991 4.79999H20.8007L13.1999 10.5Z"
                  fill="white"
                />
                <path
                  d="M0 12H8.4V14.4H0V12ZM2.4 15.6H9.6V18H2.4V15.6ZM6 19.2H10.8V21.6H6V19.2Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Send
          </v-btn>
          <v-btn text class="white--text extra-button" @click="handlePDF">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.9375 14.4375H17.9919L16.2181 16.2181L17.1875 17.1875L20.625 13.75L17.1875 10.3125L16.2181 11.2819L17.9919 13.0625H8.9375V14.4375Z"
                fill="white"
              />
              <path
                d="M15.125 9.62501V6.87501C15.1255 6.78453 15.1082 6.69484 15.074 6.61108C15.0398 6.52731 14.9893 6.45113 14.9256 6.38689L10.1131 1.57439C10.0489 1.51067 9.9727 1.46026 9.88894 1.42604C9.80517 1.39183 9.71548 1.37449 9.625 1.37501H2.75C2.38533 1.37501 2.03559 1.51988 1.77773 1.77774C1.51987 2.0356 1.375 2.38534 1.375 2.75001V19.25C1.375 19.6147 1.51987 19.9644 1.77773 20.2223C2.03559 20.4801 2.38533 20.625 2.75 20.625H13.75C14.1147 20.625 14.4644 20.4801 14.7223 20.2223C14.9801 19.9644 15.125 19.6147 15.125 19.25V17.875H13.75V19.25H2.75V2.75001H8.25V6.87501C8.25 7.23969 8.39487 7.58942 8.65273 7.84728C8.91059 8.10515 9.26033 8.25001 9.625 8.25001H13.75V9.62501H15.125ZM9.625 6.87501V3.03189L13.4681 6.87501H9.625Z"
                fill="white"
              />
            </svg>

            PDF
          </v-btn>
        </div>
      </template>
    </TablePageHeader>
    <Table
      @filter="handleFilterClicked"
      @edit="handleEdit"
      @delete="handleDelete"
      @search="handleSearch"
      @next="handleNext"
      @prev="handlePrev"
      @page-click="handlePageClick"
      @sortDesc="handleSortDesc"
      @sortBy="handleSortBy"
      @selectionChanged="handleSelection"
      :items="reports"
      :headers="headers"
      :filters="filters"
      :totalItemCount="reportsCount"
      :currentPage="reportsCurrentPage"
      searchPlaceholder="Search by Job, Crew or Department"
      nameWithImage
      imageKey="resizedSmallImageUrl"
      limitedActions
      select
      :selection="id"
    />
  </div>
</template>

<script>
import TablePageHeader from "@/components/TablePageHeader";
import Table from "@/components/Table";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Reports",
  async created() {
    const storageFilters = JSON.parse(sessionStorage.getItem("reportsFilters"));

    if (storageFilters) {
      this.filters = storageFilters;
      this.setReportsFilters({ storageFilters });
    }

    await this.fetchReports();
    this.fetchDepartments({ full: true, silent: true });
    this.fetchCrews({ full: true, silent: true });
    this.fetchEmailTemplate();
  },
  data() {
    return {
      filterDrawer: false,
      dateMenu: false,
      deleteModal: false,
      filterPanels: -1,
      filters: {
        department: [],
        status: [],
        crews: [],
        date: [],
      },
      fields: {},
      search: "",
      activeEdits: [],
      statuses: [
        { text: "Created", value: "Created" },
        { text: "Submitted", value: "Submitted" },
        { text: "Review 1 Completed", value: "Reviewer1" },
        { text: "Review 2 Completed", value: "Reviewer2" },
        { text: "Final Review Completed", value: "Completed" },
        { text: "Archived", value: "Archived" },
      ],
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "JOB NAME", value: "jobName", sortable: true },
        { text: "DATE", value: "createDateString", sortable: true },
        { text: "CREW", value: "crewCode", sortable: false },
        { text: "DEPARTMENT", value: "departmentCode", sortable: false },
        { text: "STATUS", value: "parsedStatus", sortable: false },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
      sortColumn: "",
      selected: [],
      reportToDelete: {},
    };
  },
  props: {
    id: String,
  },
  components: {
    TablePageHeader,
    Table,
    DeleteConfirmation,
  },
  watch: {
    filters: {
      handler(filters) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.filters.date.length == 1) return;
          this.setReportsFilters({ filters });
          this.fetchReports();
          sessionStorage.setItem("reportsFilters", JSON.stringify(filters));
        }, 600);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "departments",
      "crews",
      "reports",
      "reportsCount",
      "reportsCurrentPage",
      "isLoading",
      "emailTemplate",
    ]),
    dateFormatted() {
      const dates = this.filters.date;
      if (dates.length < 2) return null;
      const [year1, month1, day1] = dates[0].split("-");
      const [year2, month2, day2] = dates[1].split("-");
      return `${month1}/${day1}/${year1}  -  ${month2}/${day2}/${year2}`;
    },
    parsedCrews() {
      return this.crews.map(e => {
        return {
          text: e.code,
          value: e,
        };
      });
    },
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
  methods: {
    ...mapActions([
      "fetchReports",
      "fetchDepartments",
      "fetchCrews",
      "fetchEmailTemplate",
      "deleteReport",
    ]),
    ...mapMutations(["setReportsPagination", "setReportsFilters"]),
    handleConfirmDelete() {
      const reportDeleted = this.deleteReport(this.reportToDelete.id);
      if (reportDeleted) {
        this.reportToDelete = {};
        this.deleteModal = false;
      }
    },
    handleDelete(report) {
      this.deleteModal = true;
      this.reportToDelete = report;
    },
    handleSelection(newSelected) {
      this.selected = newSelected;
    },
    handlePDF() {
      this.selected
        .filter(e => e.status === "Archived")
        .map(e => e.pdfUrl)
        .forEach(e => {
          const link = document.createElement("a");
          link.href = e;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    handleSend() {
      const pdfs = this.selected
        .filter(e => e.status === "Archived")
        .map(e => encodeURI(e.pdfUrl))
        .join("\n");
      const parsedDate = new Date().toLocaleDateString([], {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const depCode = this.selected?.[0].departmentCode;

      const departmentName = this.departments.find(e => e.code === depCode)
        ?.name;

      const emailFinal = encodeURIComponent(
        this.emailTemplate
          .replace("{UPDATE_TIME}", parsedDate)
          .replace("{DEP_NAME}", departmentName)
          .replace("{REPORT_LINK}", pdfs),
      );
      const mLink = `mailto:?body=${emailFinal}`;
      const link = document.createElement("a");
      link.href = mLink;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleSortBy(e) {
      let column = "";
      if (e == "jobName") column = "jobNameDesc";
      if (e == "createDateString") column = "dateDesc";
      this.sortColumn = column;
    },
    handleSortDesc(e) {
      const sort = {};
      sort[this.sortColumn] = e;
      this.setReportsFilters({ sort });
      this.fetchReports();
    },
    handleNext() {
      this.setReportsPagination(this.reportsCurrentPage + 1);
      this.fetchReports();
    },
    handlePrev() {
      this.setReportsPagination(this.reportsCurrentPage - 1);
      this.fetchReports();
    },
    handlePageClick(pageNum) {
      if (this.reportsCurrentPage != pageNum) {
        this.setReportsPagination(+pageNum);
        this.fetchReports();
      }
    },
    filterSelectionCount(filterKey) {
      const filter = this.filters[filterKey];
      const filterCount = filter.length;
      return filterCount > 0 ? `(${filterCount})` : "";
    },
    filterSelectionElements(filterKey, key) {
      const filter = this.filters[filterKey];
      if (filterKey == "date") return this.dateFormatted;
      const filterCount = filter.length;
      if (filterCount) {
        if (key) {
          return filter.map(e => e[key]).join(", ");
        }
        return filter.map(e => e.toString()).join(", ");
      }
      return "";
    },
    handleSearch(search) {
      this.setReportsFilters({ search });
      this.fetchReports();
    },
    handleEdit(report) {
      this.$router.push({
        name: "ReportDetail",
        params: { id: report.id.toString() },
      });
    },
    handleFilterClicked(filter) {
      switch (filter) {
        case "department":
          this.filterPanels = 0;
          break;
        case "status":
          this.filterPanels = 1;
          break;
        case "crews":
          this.filterPanels = 2;
          break;
        case "date":
          this.filterPanels = 3;
          break;
      }
      this.filterDrawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-button {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: none !important;
  svg {
    margin-right: 8px;
  }
}
</style>
