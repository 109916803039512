import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Layout from "../views/Layout.vue";
import Users from "../views/Users.vue";
import Departments from "../views/Departments.vue";
import Crews from "../views/Crews.vue";
import Jobs from "../views/Jobs.vue";
import Reports from "../views/Reports.vue";
import Settings from "../views/Settings.vue";
import CreateReport from "../views/CreateReport.vue";
import ReportDetail from "../views/ReportDetail.vue";
import Login from "../views/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/create-report",
    name: "CreateReport",
    component: CreateReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-detail/:id",
    name: "ReportDetail",
    component: ReportDetail,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
          requiresAuth: true,
          requiredLevel: 4,
        },
      },
      {
        path: "/departments",
        name: "Departments",
        component: Departments,
        meta: {
          requiresAuth: true,
          requiredLevel: 2,
        },
      },
      {
        path: "/crews",
        name: "Crews",
        component: Crews,
        meta: {
          requiresAuth: true,
          requiredLevel: 2,
        },
      },
      {
        path: "/jobs",
        name: "Jobs",
        component: Jobs,
        meta: {
          requiresAuth: true,
          requiredLevel: 2,
        },
      },
      {
        path: "/reports/:id?",
        name: "Reports",
        component: Reports,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  store.commit("resetFilters");
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let passing = false;
    if (store.getters.isAuthenticated) {
      passing = true;
    } else {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token") || "";
      const expiration =
        localStorage.getItem("expiration") ||
        sessionStorage.getItem("expiration") ||
        "";
      if (token && expiration) {
        const expCheck = new Date(expiration);
        if (new Date() < expCheck) {
          passing = true;
        }
      }
    }
    if (!passing) {
      next({ path: "/login" });
    }
    if (!store.getters.user.roleId) {
      await store.dispatch("fetchUserInfo");
    }
    const level = to.matched[to.matched.length - 1].meta.requiredLevel;
    if (level && level > store.getters.user.roleId) {
      next({ path: "/" });
    } else {
      next();
      return;
    }
  }
  next();
});

export default router;
