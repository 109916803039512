<template>
  <div>
    <Navbar />
    <v-container fluid class="py-0" style="padding-right:0px;padding-left:0px;">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular
      ></v-overlay>
      <router-view />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/Navbar";
export default {
  name: "Layout",
  components: { Navbar },
  //created() {
  //try {
  //this.$store.dispatch("fetchUserInfo");
  //} catch (e) {
  //console.log(e);
  //}
  //},
  computed: {
    ...mapGetters(["isLoading"]),
    isIpad() {
      return window.innerWidth < 1201;
    },
  },
};
</script>
