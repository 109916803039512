export default `
<!DOCTYPE html>
<html>
<head>
<title>Report PDF</title>
	<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet">
  <link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet">
<style type="text/css">
body 
	{
	font-family: 'Roboto', sans-serif;
	}
.active-slide {
  height: 1062px;
  width: 100%;
}
.active-slide-options: {
  display: none;
}
.active-slide-content {
  height: 100%;
  width: 100%;
  position: relative;
}
.active-slide-content-wrapper {
  height: 100%;
  width: 100%;
}
.image-upload-wrapper {
  display: none;
}
.two-two .photo-1, .two-two .photo-2 {
	 position: absolute;
	 width: 519.48px;
	 height: 389.03px;
}
 .two-two .photo-1 > img, .two-two .photo-2 > img {
	 width: auto;
	 height: auto;
	 max-height: 389.03px;
	 margin-left: auto;
	 margin-right: auto;
}
 .two-two .photo-1 {
	 left: 145.14px;
	 top: 169.03px;
}
 .two-two .photo-2 {
	 left: 700.67px;
	 top: 169.03px;
}
 .two-two .slide-image {
	 width: 519.48px;
	 height: 389.03px;
	 background-repeat: no-repeat;
	 background-position: center;
	 background-size: contain;
}
 .two-two .text-1, .two-two .text-2 {
	 position: absolute;
	 width: 520.43px;
	 height: 254.58px;
	 font-family: Roboto;
	 font-style: normal;
	 font-weight: normal;
	 font-size: 24px;
	 line-height: 28px;
	 color: #fff;
}
 .two-two .text-1 {
	 left: 144.19px;
	 top: 583.52px;
}
 .two-two .text-2 {
	 left: 700.67px;
	 top: 583.52px;
}
.one-one .photo-2, .one-one .text-2{
  display:none;
}

.one-one .photo-1{
  position: absolute;
  width: 1038.31px;
	height: 777.58px;
	left: 163.16px;
	top: 72.15px;
}
 .one-one .slide-image {
	 width: 1038.31px;
height: 777.58px;
	 background-repeat: no-repeat;
	 background-position: center;
	 background-size: contain;
}
 .one-one .text-1 {
	 position: absolute;
	 width: 1043.88px;
height: 116.81px;
left: 157.6px;
top: 867.73px;
	 font-family: Roboto;
	 font-style: normal;
	 font-weight: normal;
	 font-size: 24px;
	 line-height: 28px;
	 color: #fff;
}





.two-one .photo-1, .two-one .photo-2 {
	 position: absolute;
	 width: 517.09px;
height: 387.24px;

}
 .two-one .photo-1 {
left: 144.47px;
top: 168.25px;
}
 .two-one .photo-2 {
	 left: 697.44px;
top: 168.25px;
}
 .two-one .slide-image {
	 width: 517.09px;
height: 387.24px;
	 background-repeat: no-repeat;
	 background-position: center;
	 background-size: contain;
}
 .two-one .text-1 {
	 position: absolute;
	 width: 1071.95px;
height: 125.58px;
left: 143.53px;
top: 580.83px;
	 font-family: Roboto;
	 font-style: normal;
	 font-weight: normal;
	 font-size: 24px;
	 line-height: 28px;
	 color: #fff;
}
 .two-one .text-2 {
	 display: none;
}




.first-slide {
	 border: 0px solid #000;
	 padding: 0px;
	 display: block;
	 position: relative;
}
 .first-slide .first-slide-image {
	 display: inline-block;
	 height: 100%;
	 width: 796px;
	 background-size: contain;
	 background-position: left center;
}
 .first-slide .first-slide-right {
	 display: inline-block;
	 width: 571px;
	 height: 100%;
	 padding: 40px 45px;
	 position: absolute;
	 top: 0px;
}
 .first-slide .first-slide-right .first-slide-text-wrapper {
	 width: 100%;
	 height: 90%;
	 background-color: rgba(0, 0, 0, 0.8);
	 color: #fff;
	 padding: 16px 0px;
	 position: relative;
}
 .first-slide .first-slide-right .first-slide-text-wrapper .top-part .job-name {
	 font-weight: normal;
	 font-size: 48px;
	 line-height: 56px;
	 margin-bottom: 33px;
}
 .first-slide .first-slide-right .first-slide-text-wrapper .top-part .job-department {
	 font-weight: normal;
	 font-size: 32px;
   line-height: 37px;
	 margin-bottom: 8px;
}
 .first-slide .first-slide-right .first-slide-text-wrapper .top-part .date-text {
	 font-weight: normal;
	 font-size: 32px;
   line-height: 37px;
}
 .first-slide .first-slide-right .first-slide-text-wrapper .bottom-part {
	 position: absolute;
	 top: 720px;
}
 .first-slide .first-slide-right .first-slide-text-wrapper .bottom-part div {
	 display: block;
}
 .first-slide .first-slide-right .first-slide-text-wrapper .bottom-part div p {
	 font-size: 24px;
	 line-height: 28px;
	 margin-bottom: 4px;
}
 .first-slide .first-slide-right .furbish-logo-wrapper {
	 height: 42px;
	 top: 920px;
}
 .first-slide .first-slide-right .furbish-logo-wrapper img {
	 height: 42px;
}
.text-1, .text-2 {
	text-align: center;
  white-space: pre-line;
}

</style>
</head>
	<body style="background-color: black; width: 1375px; margin: 0px;">
		EDITORHTML
</body>
</html>
`;
