<template>
  <div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular
    ></v-overlay>
    <div class="top-navigation">
      <v-btn text class="back-button" to="/">
        <v-icon left>
          mdi-chevron-left
        </v-icon>
        Back
      </v-btn>
      <div class="align-center d-flex">
        <svg
          style="margin-top: -1px;"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="#D1D1D1" />
        </svg>
        <span style="color: #D1D1D1;" class="ml-2">
          Create New Report
        </span>
      </div>
      <div></div>
    </div>
    <div class="justify-center d-flex pt-9">
      <ReportStepper />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReportStepper from "@/components/ReportStepper.vue";

export default {
  name: "CreateReport",
  components: { ReportStepper },
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>
<style lang="scss" scoped>
.top-navigation {
  display: flex;
  height: 56px;
  background-color: #353535;
  padding-left: 42px;
  padding-right: 22px;
  justify-content: space-between;
  align-items: center;
  .back-button {
    color: #ffffff;
    opacity: 0.6;
    text-transform: none !important;
    font-weight: normal;
    font-size: 16px;
  }
}
</style>
